import React from 'react';
// Import components
import FilePreview from './FilePreview/FilePreview';
// Import icon components
import AddIcon from '../../../assets/images/svg/AddIcon';

export default function FilesUpload(props) {
    // Random number
    const rand = Math.random();

    // Destructuring
    const { onFileClick, onFileRemove } = props;

    // On each file click
    const onEachFileClick = (file, allFiles) => {
        if (onFileClick) onFileClick(file, allFiles);
    };

    // On each file remove
    const onEachFileRemove = (e, i) => {
        if (onFileRemove) {
            e.preventDefault();
            e.stopPropagation();
            onFileRemove(i);
        }
    };
    return (
        <div className="files-upload">
            <div className="files-upload__blocks">
                <label
                    htmlFor={`files-upload-upload-${rand}`}
                    className="files-upload__block files-upload__block--upload"
                >
                    <input
                        id={`files-upload-upload-${rand}`}
                        type="file"
                        onChange={e => props?.onFilesChange(e.target.files)}
                        multiple
                        hidden
                    />
                    <div className="">
                        <AddIcon />
                    </div>
                </label>
                {props?.files?.map((file, i) => (
                    <FilePreview
                        key={i}
                        file={file}
                        loading={file.loading}
                        onFileRemove={e => onEachFileRemove(e, i)}
                        onClick={() => onEachFileClick(file, props.files)}
                        pointer={onFileClick}
                    />
                ))}
            </div>
        </div>
    );
}
