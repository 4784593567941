import React from 'react';
// Import from Ant design
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function LoadingSpinner(props) {
    const icon = (
        <LoadingOutlined style={{ fontSize: props.size || 28 }} spin />
    );
    return (
        <div className="loading-spinner">
            <Spin className="spin" size="large" indicator={icon} />
        </div>
    );
}
