import React from 'react';

export default function FileTypeAudioIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 18V5L21 3V16"
                stroke="url(#paint0_linear)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 21C7.65685 21 9 19.6569 9 18C9 16.3431 7.65685 15 6 15C4.34315 15 3 16.3431 3 18C3 19.6569 4.34315 21 6 21Z"
                fill="url(#paint1_linear)"
                stroke="url(#paint2_linear)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 19C19.6569 19 21 17.6569 21 16C21 14.3431 19.6569 13 18 13C16.3431 13 15 14.3431 15 16C15 17.6569 16.3431 19 18 19Z"
                fill="url(#paint3_linear)"
                stroke="url(#paint4_linear)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="9"
                    y1="6.44262"
                    x2="15.3831"
                    y2="6.65825"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="3"
                    y1="16.377"
                    x2="6.18952"
                    y2="16.5117"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="3"
                    y1="16.377"
                    x2="6.18952"
                    y2="16.5117"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="15"
                    y1="14.377"
                    x2="18.1895"
                    y2="14.5117"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="15"
                    y1="14.377"
                    x2="18.1895"
                    y2="14.5117"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
            </defs>
        </svg>
    );
}
