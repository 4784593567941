import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

// Import from Ant design
import { Button } from 'antd';
import {
    LeftOutlined,
    RightOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    MinusOutlined,
    PlusOutlined,
    ReloadOutlined,
    CopyOutlined,
    PrinterOutlined,
} from '@ant-design/icons';
// Import components
import BackdropOverlay from '../BackdropOverlay/BackdropOverlay';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
// Import constants
import { OFFICE_FILE_TYPES } from '../../../exports/constants';
// Import Utility functions
import {
    COPY_URL_FROM_FILE,
    PRINT_FROM_FILE,
} from '../../../exports/functions';

export default function PDFViewer(props) {
    const [page, setPage] = useState(1);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const canvasRef = useRef(null);

    // PDF Url
    let pdfUrl;
    if (OFFICE_FILE_TYPES.includes(props.file.file_type)) {
        let file_url =
            props.file.file_url.substr(
                0,
                props.file.file_url.lastIndexOf('.')
            ) + '.pdf';
        pdfUrl = `${process.env.REACT_APP_STORAGE_PDF_PREVIEW}/${file_url}`;
    } else {
        pdfUrl = `${process.env.REACT_APP_STORAGE_FILES}/${props.file.file_url}`;
    }

    const { pdfDocument } = usePdf({
        file: pdfUrl,
        scale,
        rotate,
        page,
        canvasRef,
    });

    return (
        <>
            <div className="pdf-viewer">
                <BackdropOverlay
                    visible={props.visible}
                    onOverlayClose={props.onOverlayClose}
                    clickOutsideToClose={props.clickOutsideToClose}
                >
                    {!pdfDocument && <LoadingSpinner />}
                    {Boolean(pdfDocument && pdfDocument.numPages) && (
                        <nav className="pdf-viewer__menu">
                            <ul className="pdf-viewer__menu-list">
                                <li className="previous">
                                    <Button
                                        icon={<LeftOutlined />}
                                        disabled={page === 1}
                                        onClick={() => setPage(page - 1)}
                                    >
                                        Previous page
                                    </Button>
                                </li>
                                <li className="next">
                                    <Button
                                        icon={<RightOutlined />}
                                        disabled={
                                            page === pdfDocument?.numPages
                                        }
                                        onClick={() => setPage(page + 1)}
                                    >
                                        Next page
                                    </Button>
                                </li>
                                <li className="zoom-decrease">
                                    <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => setScale(scale - 0.1)}
                                    >
                                        Zoom
                                    </Button>
                                </li>
                                <li className="zoom-increase">
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => setScale(scale + 0.1)}
                                    >
                                        Zoom
                                    </Button>
                                </li>
                                <li className="zoom-reset">
                                    <Button
                                        icon={<ReloadOutlined />}
                                        onClick={() => setScale(1)}
                                    >
                                        Reset zoom
                                    </Button>
                                </li>
                                <li className="rotate-left">
                                    <Button
                                        icon={<RotateLeftOutlined />}
                                        onClick={() => {
                                            setRotate((rotate - 90) % 360);
                                        }}
                                    >
                                        Rotate left
                                    </Button>
                                </li>
                                <li className="rotate-right">
                                    <Button
                                        icon={<RotateRightOutlined />}
                                        onClick={() =>
                                            setRotate((rotate + 90) % 360)
                                        }
                                    >
                                        Rotate right
                                    </Button>
                                </li>
                                <li className="rotate-reset">
                                    <Button
                                        icon={<ReloadOutlined />}
                                        onClick={() => setRotate(0)}
                                    >
                                        Reset orientation
                                    </Button>
                                </li>
                                <li className="print-file">
                                    <Button
                                        icon={<PrinterOutlined />}
                                        onClick={() =>
                                            PRINT_FROM_FILE(props.file)
                                        }
                                    >
                                        Print
                                    </Button>
                                </li>
                                <li className="copy-url">
                                    <Button
                                        icon={<CopyOutlined />}
                                        onClick={() =>
                                            COPY_URL_FROM_FILE(props.file)
                                        }
                                    >
                                        Copy url
                                    </Button>
                                </li>
                            </ul>

                        </nav>
                    )}
                    <div className="pdf-viewer__pdf">
                        <canvas ref={canvasRef} />
                    </div>
                </BackdropOverlay>
            </div>
        </>
    );
}
