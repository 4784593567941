import React from 'react';
// Import from Recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
    verificationStatus as verificationStatusAtom,
    userDetails as userDetailsAtom,
} from '../../recoil/atoms';
// Import routes
import { Route } from 'react-router-dom';
import App from '../../App';
import PublicRoutes from '../../exports/publicRoutes';
// Import from libraries
import axios from 'axios';
// Import from exports
import { GET_DISPLAY_NAME } from '../../exports/functions';
import { BLINDS_DISPLAY_TIMEOUT } from '../../exports/constants';
// import Cookies from 'universal-cookie';
// Import components
import HeadingAndDescription from '../../components/Common/HeadingAndDescription/HeadingAndDescripton';

let getUserDetails;
export default function Checkpoint() {
    // Local states
    const [userIsNew, setUserIsNew] = React.useState('');
    const [newEmailUpdated, setNewEmailUpdated] = React.useState(false);
    // Global states
    const setUserDetails = useSetRecoilState(userDetailsAtom);
    const [verificationStatus, setVerificationStatus] = useRecoilState(
        verificationStatusAtom
    );
    // Instance of Cookies
    // const cookies = new Cookies();

    // Get user details function
    React.useEffect(() => {
        getUserDetails = (jwt_token, verification_status) => {
            // Plant JWT token in local storage
            localStorage.setItem('RES_ACCESS', jwt_token);
            setVerificationStatus(verification_status);
            // Redirect to app
            axios
                .get(`${process.env.REACT_APP_HOST}/v1/me`, {
                    headers: {
                        Authorization: 'Bearer ' + jwt_token,
                    },
                })
                .then(response => {
                    // console.log(response.data, 'v1/me');
                    // Plant user details in local storage
                    const userDetails = response.data;
                    const userDetailsUpdated = {
                        ...userDetails,
                        active_team: { ...getActiveTeam(userDetails) },
                    };
                    localStorage.setItem(
                        'RES_USER',
                        JSON.stringify(userDetailsUpdated)
                    );
                    // Set user details in global state
                    setUserDetails(userDetailsUpdated);
                    setVerificationStatus('passed');
                })
                .catch(error => {
                    console.log(error, '/me error');
                    setVerificationStatus('something-wrong');
                });
        };
        // Set active team function
        const getActiveTeam = userDetails => {
            let activeTeam = {};
            if (userDetails.role === 'owner') {
                activeTeam.team_id = userDetails.id;
                activeTeam.email = userDetails.email;
                activeTeam.first_name = userDetails.first_name;
                activeTeam.last_name = userDetails.last_name;
                activeTeam.image_link = userDetails.image_link;
            } else {
                const activeTeamTemp = userDetails.teams.filter(
                    team => team.account_id === userDetails.team_id
                )[0];
                activeTeam.team_id = activeTeamTemp.account_id;
                activeTeam.email = activeTeamTemp.email;
                activeTeam.first_name = activeTeamTemp.first_name;
                activeTeam.last_name = activeTeamTemp.last_name;
                activeTeam.image_link = activeTeamTemp.image_link;
            }
            activeTeam.display_name = GET_DISPLAY_NAME(
                activeTeam.first_name,
                activeTeam.last_name,
                activeTeam.username,
                activeTeam.email
            );
            return activeTeam;
        };
        // Verify code from url
        const url = window.location.href;
        // App being redirected from Accept invitation link
        if (url.includes('accept-invitation/')) {
            // Set verification status
            setVerificationStatus('verifying');
            // Get invitation token
            const invitation_token = url.split('accept-invitation/').pop();
            axios
                .get(
                    `${process.env.REACT_APP_HOST}/v1/accept-invitation/${invitation_token}`
                )
                .then(response => {
                    const jwt_token = response.data.token;
                    // Get user details
                    setTimeout(() => {
                        getUserDetails(jwt_token, 'invitation-accepted');
                    }, BLINDS_DISPLAY_TIMEOUT);
                })
                .catch(error => {
                    // Set verification status
                    setVerificationStatus('verification-error');
                });
            return;
        }
        // App being redirected from Login link
        if (url.includes('login-verify/')) {
            setVerificationStatus('verifying');
            const verification_code = url.split('login-verify/').pop();
            if (
                verification_code.includes('login-verify') ||
                !verification_code
            ) {
                setVerificationStatus('unauthorized');
            } else {
                axios
                    .get(
                        `${process.env.REACT_APP_HOST}/v1/login-verify/${verification_code}`
                    )
                    .then(response => {
                        // console.log(response, 'login-verify');
                        // New user boolean
                        const user_is_new = response.data.new_user;
                        setUserIsNew(user_is_new);
                        // Get user details
                        const jwt_token = response.data.token;
                        getUserDetails(jwt_token, 'email-verified');
                    })
                    .catch(error => {
                        // Show auth failed message
                        setVerificationStatus('verification-error');
                    });
            }
            return;
        }
        // App being redirected from Update email link
        if (url.includes('update-email/')) {
            setVerificationStatus('verifying');
            const verification_code = url.split('update-email/').pop();
            if (
                verification_code.includes('update-email') ||
                !verification_code
            ) {
                setVerificationStatus('unauthorized');
            } else {
                axios
                    .get(
                        `${process.env.REACT_APP_HOST}/v1/update-email/${verification_code}`
                    )
                    .then(response => {
                        setVerificationStatus('email-updated');
                        // Redirect to app
                        axios
                            .get(`${process.env.REACT_APP_HOST}/v1/me`, {
                                headers: {
                                    Authorization:
                                        'Bearer ' +
                                        localStorage.getItem('RES_ACCESS'),
                                },
                            })
                            .then(response => {
                                // Plant user details in local storage
                                const userDetails = response.data;
                                localStorage.setItem(
                                    'RES_USER',
                                    JSON.stringify(userDetails)
                                );
                                // Set user details in global state
                                setUserDetails(userDetails);
                                // Defer to set status as passed
                                setTimeout(() => {
                                    setVerificationStatus('passed');
                                    setNewEmailUpdated(true);
                                }, 5000);
                            })
                            .catch(error => {
                                setVerificationStatus('verification-error');
                            });
                    })
                    .catch(error => {
                        // Show auth failed message
                        setVerificationStatus('verification-error');
                    });
            }
            return;
        }
        // Access token is in local storage, continue loading app
        if (localStorage.getItem('RES_ACCESS')) {
            // If is being landed from update email link
            setVerificationStatus('passed');
            setUserDetails(JSON.parse(localStorage.getItem('RES_USER')));
            return;
        }
        // If none of the above cases
        setVerificationStatus('unauthorized');
    }, [setVerificationStatus, setUserDetails]);
    // Render based on verification status
    if (verificationStatus === 'passed') {
        return (
            <Route
                to="/app"
                render={() => (
                    <App
                        userIsNew={userIsNew}
                        newEmailUpdated={newEmailUpdated}
                        getUserDetails={getUserDetails}
                    />
                )}
            />
        );
    } else if (verificationStatus === 'unauthorized') {
        return <PublicRoutes />;
    } else {
        return (
            <HeadingAndDescription verificationStatus={verificationStatus} />
        );
    }
}
