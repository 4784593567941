import React from 'react';
// Import from dependencies
import { Empty, Button, Skeleton } from 'antd';
// Import from recoil
import { useRecoilValue } from 'recoil';
import { loadedStories as loadedStoriesAtom } from '../../../recoil/atoms';
// Import components
import StoryEach from '../StoryEach/StoryEach';

export default function StoryContent(props) {
    // Global state
    const loadedStories = useRecoilValue(loadedStoriesAtom);
    // Set stories content
    let storiesContent;
    if (loadedStories.count === null) {
        storiesContent = (
            <>
                <div className="stories-feed-block">
                    <Skeleton avatar paragraph={{ rows: 4 }} active />
                </div>
                <div className="stories-feed-block">
                    <Skeleton avatar paragraph={{ rows: 4 }} active />
                </div>
            </>
        );
    } else if (loadedStories.count === 0) {
        storiesContent = (
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 60,
                }}
                description={<span>No stories to show</span>}
            >
                <Button
                    type="primary"
                    className="ant-btn-squared"
                    onClick={props.newStoryModalOpen}
                >
                    Share your story
                </Button>
            </Empty>
        );
    } else {
        storiesContent = loadedStories.data.map(story => (
            <StoryEach
                key={story.id}
                story={story}
                editStoryModalOpen={() => props.editStoryModalOpen(story.id)}
                deleteStoryConfirmationOpen={() =>
                    props.deleteStoryConfirmationOpen(story.id)
                }
                handleStorySave={() => props.handleStorySave(story.id)}
                handleStoryMoveToDrafts={() =>
                    props.handleStoryMoveToDrafts(story.id)
                }
                onStoryEachFileClick={props.onStoryEachFileClick}
                handleStoryValUpdate={props.handleStoryValUpdate}
            />
        ));
    }
    return storiesContent;
}
