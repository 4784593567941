import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Switch, Route, Redirect } from 'react-router-dom';
import { activeAppLanguage as activeAppLanguageAtom } from '../../../recoil/atoms';
import Login from './Login/Login';

export default function GetStarted(props) {
    // Global states
    const [activeAppLanguage, setActiveAppLanguage] = useRecoilState(
        activeAppLanguageAtom
    );
    // Local states
    const [termsAndConditionsModal, setTermsAndConditionsModal] =
        React.useState({
            visible: false,
        });
    const [privacyPolicyModal, setPrivacyPolicyModal] = React.useState({
        visible: false,
    });
    const [roadMapModal, setRoadMapModal] = React.useState({
        visible: false,
    });
    const [mobileMenu, setMobileMenu] = React.useState(false);

    // Destructuring
    const {
        sendMagicLink,
        emailAddress,
        emailAddressIsValid,
        updateEmailAddress,
        magicLinkSentStatus,
    } = props;

    // Terms and conditions modal
    const setTermsAndConditionsModalVisible = bool => {
        setTermsAndConditionsModal(prevValues => ({
            ...prevValues,
            visible: bool,
        }));
    };
    // Privacy policy modal
    const setPrivacyPolicyModalVisible = bool => {
        setPrivacyPolicyModal(prevValues => ({
            ...prevValues,
            visible: bool,
        }));
    };
    // RoadMap modal

    // i18n
    const { t, i18n } = useTranslation();

    // Get started heading
    const getStartedHeading = (
        <div className="get-started__heading-wrapper">
            <h1 className="get-started__heading">
                <span className="get-started__heading-line-breaks text-gradient">
                    {t('getStarted.getStartedHeading.part1')}&nbsp;
                </span>
                <span className="get-started__heading-line-breaks text-gradient">
                    {t('getStarted.getStartedHeading.part2')}&nbsp;
                </span>
                <span className="get-started__heading-line-breaks text-gradient">
                    {t('getStarted.getStartedHeading.part3')}&nbsp;
                </span>
            </h1>
            <h2 className="get-started__subheading">
                {t('getStarted.getStartedHeading.part4')}
            </h2>
        </div>
    );

    /** Change platform language */
    const changeAppLanguage = language => {
        // Set active language in local state
        setActiveAppLanguage(language);
        // Change language
        i18n.changeLanguage(language);
    };

    // Scroll to top

    return (
        <div className="get-started-wrapper">
            <Switch>
                <Route
                    exact
                    path="/auth/start/home"
                    render={() => (
                        <Login {...{
                            getStartedHeading, setTermsAndConditionsModalVisible, setPrivacyPolicyModalVisible, sendMagicLink, emailAddress, 
                            emailAddressIsValid, updateEmailAddress, magicLinkSentStatus,activeAppLanguage, changeAppLanguage
                        }} />
                    )}
                />
                <Redirect to="/auth/start/home" />
            </Switch>
        </div>
    );
}
