import React from 'react';

export default function EmailOpenedIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.667 490.667">
			<path
				d="M475.094 443.094a53.33 53.33 0 01-37.76 15.573h-384a53.333 53.333 0 01-37.76-15.573l174.08-174.08 29.44 21.333c15.596 11.522 36.884 11.522 52.48 0l29.44-21.333 174.08 174.08z"
				fill="#1e88e5"
			/>
			<g fill="#2196f3">
				<path d="M10.027 139.52l179.627 129.493-174.08 174.08A53.334 53.334 0 010 405.334V170.667a52.697 52.697 0 0110.027-31.147zM490.667 170.667v234.667a53.333 53.333 0 01-15.573 37.76l-174.08-174.08 179.413-129.707a53.033 53.033 0 0110.24 31.36z" />
			</g>
			<path
				d="M437.334 117.334h-160v23.168c16.659-5.9 34.947 2.823 40.846 19.482a32.002 32.002 0 01-7.545 33.318l-42.667 42.667a31.79 31.79 0 01-10.475 6.976 32.253 32.253 0 01-12.16 2.389 32.01 32.01 0 01-12.117-2.389l-4.096-2.005a32.428 32.428 0 01-6.4-4.971l-42.667-42.667c-12.495-12.499-12.492-32.76.007-45.255a32 32 0 0133.273-7.545v-23.168h-160a53.033 53.033 0 00-43.307 22.187l179.627 129.493 29.44 21.333c15.596 11.522 36.884 11.522 52.48 0l29.44-21.333 179.413-129.707a53.327 53.327 0 00-43.092-21.973z"
				fill="#64b5f6"
			/>
			<path
				d="M295.552 163.115c-4.165-4.164-10.917-4.164-15.083 0L256 187.584V42.667C256 36.776 251.225 32 245.334 32s-10.667 4.776-10.667 10.667v144.917l-24.448-24.469c-4.237-4.092-10.99-3.975-15.083.262-3.992 4.134-3.992 10.687 0 14.82l42.667 42.667a10.67 10.67 0 003.349 2.133 10.666 10.666 0 0011.584-2.325l42.667-42.667c4.08-4.101 4.146-10.707.149-14.89z"
				fill="#455a64"
			/>
		</svg>
	);
}
