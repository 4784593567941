import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// Import from React router DOM
import { BrowserRouter } from 'react-router-dom';
import Checkpoint from './containers/Checkpoint/Checkpoint';
import * as serviceWorker from './serviceWorker';
// Import styles
import './assets/sass/main.sass';
import { RecoilRoot } from 'recoil';
import './i18n';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { environment } from './exports/vars';

if (environment !== 'development') {
    Sentry.init({
        dsn: 'https://fbb74cd899b94bdcafff0478e2b9aed4@o992640.ingest.sentry.io/5954038',
        integrations: [new Integrations.BrowserTracing()],
        environment: environment,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading</div>}>
            <BrowserRouter>
                <RecoilRoot>
                    <Checkpoint />
                </RecoilRoot>
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
