import React from 'react';
import { Radio, Typography, Select } from 'antd';
import StoryActionEach from '../../Story/StoryEach/StoryEachActions/StoryActionEach/StoryActionEach';
import ShareIconOutlined from '../../../assets/images/svg/ShareIconOutlined';
import ArrowDownIcon from '../../../assets/images/svg/svgComponents/arrowDown';
import SvgHeartSelectedIcon from '../../../assets/images/svg/svgComponents/heartSelectedIcon';
import SvgHeartIcon from '../../../assets/images/svg/svgComponents/heartIcon';
import SvgDislikeIcon from '../../../assets/images/svg/svgComponents/dislikeIcon';
import SvgLikeIcon from '../../../assets/images/svg/svgComponents/likeIcon';
import { STORY_CATEGORIES } from '../../../../src/exports/constants';
const { Text } = Typography;

const AgoraTabButtons = ({ activeCardStory }) => {
    const RadioGroup = Radio.Group;
    const RadioButton = Radio.Button;

    const categoriesElement = (
        <Select
            showSearch
            placeholder="Category"
            defaultValue={'All categories'}
            // onChange={val =>
            //     props.handleStoryValUpdate('category', val, story.id)
            // }
            // loading={isUpdatingStoryCategory && story.id === activeStoryId}
            // disabled={isUpdatingStoryCategory && story.id === activeStoryId}
        >
            {STORY_CATEGORIES.map((category, i) => {
                return (
                    <Select.OptGroup key={i} label={category.category}>
                        {category.subcategories.map(sub_cat => (
                            <Select.Option key={sub_cat} value={sub_cat}>
                                {sub_cat}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                );
            })}
        </Select>
    );
    return (
        <div className="agoraTabButtons">
            <div className="agoraTabButtons_filter">
                <div className="agoraTabButtons_filter_buttosPanel">
                    {categoriesElement}
                    <RadioGroup
                        size="large"
                        defaultValue="text"
                        buttonStyle="solid"
                    >
                        <RadioButton value="text">Latest post</RadioButton>
                        <RadioButton value="images">
                            Most liked <SvgLikeIcon />
                        </RadioButton>
                        <RadioButton value="video">
                            Most disliked <SvgDislikeIcon />
                        </RadioButton>
                        <RadioButton
                            value="bookmark"
                            style={{ marginTop: '5px' }}
                        >
                            <SvgHeartSelectedIcon /> {' 14'}
                        </RadioButton>
                    </RadioGroup>
                </div>
            </div>
            <div className="agoraTabButtons_bar">
                <h2 className="agoraTabButtons_bar_title" ellipsis={true}>
                    {activeCardStory.title}
                </h2>

                <div className="agoraTabButtons_bar_buttosPanel">
                    <RadioGroup
                        size="large"
                        defaultValue="text"
                        buttonStyle="solid"
                    >
                        <RadioButton value="text">Text</RadioButton>
                        <RadioButton value="images">Images</RadioButton>
                        <RadioButton value="video">Video</RadioButton>
                        <RadioButton value="audio">Audio</RadioButton>
                        <RadioButton value="links">Links</RadioButton>
                    </RadioGroup>

                    <StoryActionEach
                        icon={<ShareIconOutlined />}
                        label="Share"
                        count="0"
                        storyId={activeCardStory.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default AgoraTabButtons;
