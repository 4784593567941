import React from 'react';
// Import React player
import ReactPlayer from 'react-player';
// Import components
import BackdropOverlay from '../BackdropOverlay/BackdropOverlay';

export default function FilePlayer(props) {
    return (
        <BackdropOverlay
            visible={props.visible}
            onOverlayClose={props.onPlayerClose}
            clickOutsideToClose={false}
        >
            <ReactPlayer
                height={props.file.file_type === 'audio' ? '40px' : '360px'}
                url={`${process.env.REACT_APP_STORAGE_FILES}/${props.file.file_url}`}
                controls={true}
            />
        </BackdropOverlay>
    );
}
