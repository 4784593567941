import React from 'react';
// Import from dependencies
import { Form, Input, Button } from 'antd';
import { useRecoilValue } from 'recoil';
// Import recoil values
import { userDetails as userDetailsAtom } from '../../../../recoil/atoms';

export default function ProfileSectionLeft(props) {
    // Global state
    const userDetails = useRecoilValue(userDetailsAtom);
    // Variables
    const userDetailsFormFields = [
        {
            label: 'First name',
            placeholder: 'John',
            name: 'first_name',
            value: userDetails.first_name,
        },
        {
            label: 'Last name',
            placeholder: 'Doe',
            name: 'last_name',
            value: userDetails.last_name,
        },
        {
            label: 'Phone number',
            placeholder: '+32',
            name: 'phone',
            value: userDetails.phone,
        },
    ];

    return (
        <div className="user__profile-section-inner-section">
            <Form
                layout="vertical"
                form={props.userDetailsForm}
                hideRequiredMark={true}
            >
                {userDetailsFormFields.map((field, i) => (
                    <Form.Item
                        key={i}
                        className="ant-form-item--general"
                        label={field.label}
                    >
                        <Input
                            placeholder={field.placeholder}
                            name={field.name}
                            onChange={event =>
                                props.onUserDetailsFormValuesChange(event)
                            }
                            value={field.value}
                        />
                    </Form.Item>
                ))}
                {(props.emailChangeStatus === 'unchanged' ||
                    props.emailChangeStatus === 'loading') && (
                    <>
                        <Form.Item
                            className="ant-form-item--general"
                            label="Email address"
                            style={{ marginBottom: '0' }}
                            name="user_email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message:
                                        'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input
                                placeholder="john.doe@gmail.com"
                                onChange={event =>
                                    props.onEmailAddressValueChange(event)
                                }
                                onPressEnter={
                                    props.userEmail.isValid
                                        ? () =>
                                              props.emailAddressRequestChangeHandler(
                                                  'loading'
                                              )
                                        : null
                                }
                                disabled={props.emailChangeStatus === 'loading'}
                            />
                        </Form.Item>
                        <div className="user__profile-change-email-address">
                            <Button
                                type="primary"
                                className="ant-btn-squared"
                                onClick={() =>
                                    props.emailAddressRequestChangeHandler(
                                        'loading'
                                    )
                                }
                                loading={props.emailChangeStatus === 'loading'}
                                disabled={!props.userEmail.isValid}
                            >
                                Change email
                            </Button>
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
}
