import React from 'react';
// Import from dependencies
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
// Import icons
import LogoAlt from '../../../../assets/images/svg/LogoAlt';

export default function MainNavLinks({ toggleMainNavDrawerVisibility }) {
    return (
        <div className="main-nav__logo">
            {/* Button for toggle left side menu */}
            <Button
                onClick={toggleMainNavDrawerVisibility}
                className="Hamburger"
                size="large"
                type="text"
                icon={<MenuOutlined />}
            ></Button>

            <Link className='main-nav-app-logo' to="/">
                <LogoAlt />
            </Link>
        </div>
    );
}
