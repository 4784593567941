import React from 'react';

export default function AddIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.5833 20.7134C18.1667 20.7134 6.09532 20.7134 6.09532 20.7134C4 20.7134 4 20.7134 4 19.7154C4 18.7174 4 18.7174 6.09532 18.7174C6.09532 18.7174 18.1667 18.7174 19.5833 18.7174C21 18.7174 21 19.2797 21 19.7154C21 20.1511 21 20.7134 19.5833 20.7134Z"
                fill="#74788D"
            />
            <path
                d="M19.4 5.45752C18 5.45752 6.07067 5.45752 6.07067 5.45752C4 5.45752 4 5.45752 4 4.45954C4 3.46155 4 3.46155 6.07067 3.46155C6.07067 3.46155 18 3.46155 19.4 3.46155C20.8 3.46155 20.8 4.0238 20.8 4.45954C20.8 4.89527 20.8 5.45752 19.4 5.45752Z"
                fill="#74788D"
            />
            <path
                d="M17.6667 10.543C16.6667 10.543 8.1458 10.543 8.1458 10.543C6.66675 10.543 6.66675 10.543 6.66675 9.54498C6.66675 8.547 6.66675 8.547 8.1458 8.547C8.1458 8.547 16.6667 8.547 17.6667 8.547C18.6667 8.547 18.6667 9.10924 18.6667 9.54498C18.6667 9.98072 18.6667 10.543 17.6667 10.543Z"
                fill="#74788D"
            />
            <path
                d="M17.6667 15.6284C16.6667 15.6284 8.1458 15.6284 8.1458 15.6284C6.66675 15.6284 6.66675 15.6284 6.66675 14.6304C6.66675 13.6324 6.66675 13.6324 8.1458 13.6324C8.1458 13.6324 16.6667 13.6324 17.6667 13.6324C18.6667 13.6324 18.6667 14.1947 18.6667 14.6304C18.6667 15.0662 18.6667 15.6284 17.6667 15.6284Z"
                fill="#74788D"
            />
        </svg>
    );
}
