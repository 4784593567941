// Imports
import { message } from 'antd';
import printJS from 'print-js';
import resAxios from './resAxios';

export const EMAIL_IS_VALID = function (email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );
};

export const PHONE_IS_VALID = function (phone_number) {
    if (phone_number[phone_number.length - 1] === ' ') {
        return false;
    }
    const allowedChars = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '(',
        ')',
        '+',
        '-',
        ' ',
    ];
    for (let i = 0; i < phone_number.length; i++) {
        let found = false;
        for (let j = 0; j < allowedChars.length; j++) {
            if (phone_number[i].includes(allowedChars[j])) {
                found = true;
                break;
            }
        }
        if (!found) {
            return false;
        }
    }
    return true;
};

export const GET_DISPLAY_NAME = (first_name, last_name, show_user_info, username, email, show_agency_info, agency_name) => {
    // if(show_agency_info){
    //     return agency_name
    // }
    if(!show_user_info){
        return "Reload"
    }
    if (first_name || last_name)
        return (first_name || '') + (first_name ? ' ' : '') + (last_name || '');
    if (show_user_info && username) return username;
    
    return email;
};

export const GET_RANDOM_GLOBAL_CITY = cities => {
    return cities[Math.floor(Math.random() * (cities.length - 1))];
};

export const STORIES_FILTER_IS_DRAFTS = cities => {
    return localStorage.getItem('FETCH_STORIES_BY') === 'drafts';
};

/** Update PEB Grade **/
export const UPDATE_PEB_GRADE = value => {
    let grade = null;

    switch (true) {
        case value < 46:
            grade = 'A++';
            break;
        case value < 86:
            grade = 'A';
            break;
        case value < 171:
            grade = 'B';
            break;
        case value < 256:
            grade = 'C';
            break;
        case value < 341:
            grade = 'D';
            break;
        case value < 426:
            grade = 'E';
            break;
        case value < 511:
            grade = 'F';
            break;
        case value < Infinity:
            grade = 'G';
            break;
        default:
            grade = null;
    }

    return grade;
};

export const GET_FILENAME_WITHOUT_EXT = file_name =>
    file_name.replace(/\.[^/.]+$/, '');

export const GET_FILE_EXT = file_name => file_name.split('.').pop();

export const GET_FILE_TYPE = file_name => {
    const ext = GET_FILE_EXT(file_name);
    let fileType;
    switch (ext) {
        case 'pdf':
            fileType = 'pdf';
            break;
        case 'png':
        case 'jpg':
        case 'jpeg':
            fileType = 'image';
            break;
        case 'mp3':
            fileType = 'audio';
            break;
        case 'mp4':
        case 'mov':
            fileType = 'video';
            break;
        case 'ppt':
        case 'pptx':
        case 'pptm':
        case 'ppsx':
            fileType = 'presentation';
            break;
        case 'doc':
        case 'docx':
            fileType = 'word';
            break;
        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'xltx':
        case 'xltm':
            fileType = 'spreadsheet';
            break;
        default:
            fileType = '';
    }
    return fileType;
};

export const COPY_URL_FROM_FILE = file => {
    // Create new element
    const el = document.createElement('textarea');
    // Store full url in a variable
    let fileUrlFull;
    if (['word', 'presentation', 'spreadsheet'].includes(file.file_type)) {
        const file_url =
            file.file_url.substr(0, file.file_url.lastIndexOf('.')) + '.pdf';
        fileUrlFull = `${process.env.REACT_APP_STORAGE_PDF_PREVIEW}/${file_url}`;
    } else {
        fileUrlFull = `${process.env.REACT_APP_STORAGE_FILES}/${file.file_url}`;
    }
    // Set value (string to be copied)
    el.value = fileUrlFull;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
    // Show toast message
    message.success('Copied to clipboard');
};

export const PRINT_FROM_FILE = file => {
    let full_url;
    if (['word', 'presentation', 'spreadsheet'].includes(file.file_type)) {
        const post_url =
            file.file_url.substr(0, file.file_url.lastIndexOf('.')) + '.pdf';
        full_url = `${process.env.REACT_APP_STORAGE_PDF_PREVIEW}/${post_url}`;
        printJS(full_url);
    } else if (file.file_type === 'image') {
        full_url = `${process.env.REACT_APP_STORAGE_FILES}/${file.file_url}`;
        printJS(full_url, 'image');
    } else {
        full_url = `${process.env.REACT_APP_STORAGE_FILES}/${file.file_url}`;
        printJS(full_url);
    }
};

export const UPDATE_RES_AXIOS_HEADER_AUTH = jwt_token => {
    resAxios.defaults.headers = {
        Authorization: `Bearer ` + jwt_token,
    };
};

export const REMOVE_LAST_LINE_BREAK = string => {
    const textToRemove = '<p><br></p>';
    const stringLastPart = string?.slice(-textToRemove.length);
    if (stringLastPart === textToRemove)
        return string.substring(0, string.length - textToRemove.length);
    return string;
};

export const TRIM_LANGUAGE_ABBR = lng_abbr => {
    return lng_abbr.split('-')[0];
};
