import React, { useEffect } from 'react';
// Import from dependencies
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import {
    properties as propertiesAtom,
    isCreatingNewProperty as isCreatingNewPropertyAtom,
    savingStatus as savingStatusAtom,
    appBg as appBgAtom,
    appBgIndex as appBgIndexAtom,
} from './recoil/atoms';
import Axios from 'axios';
import './App.css';
// Import from Ant design
import { message, Modal } from 'antd';
import 'antd/dist/antd.css';
// Import from exports
import resAxios from './exports/resAxios';
import {
    DEFAULT_PROPERTY_TYPE_ON_CREATE,
    DEFAULT_CURRENCY_TYPE_ON_CREATE,
} from './exports/constants';
import { UPDATE_RES_AXIOS_HEADER_AUTH } from './exports/functions';
import { meiliHost } from './exports/vars';
// Import assets
// Import components
import ProtectedRoutes from './exports/protectedRoutes';
import MainNav from './components/Common/MainNav/MainNav';
import WelcomeMessage from './components/Misc/WelcomeMessage/WelcomeMessage';
import SavingStatus from './components/Common/SavingStatus/SavingStatus';
import SideDrawerNav from './components/Common/SideDrawerNav/SideDrawerNav';
import UniversalNotepad from './components/Common/NotepadDrawer/NotepadDrawer';
import MainNavDrawer from './components/Common/MainNav/MainNavDrawer/MainNavDrawer';

let GmapApiScript;
export default function App(props) {
    // Global state
    const setProperties = useSetRecoilState(propertiesAtom);
    const setIsCreatingNewProperty = useSetRecoilState(
        isCreatingNewPropertyAtom
    );
    const savingStatus = useRecoilValue(savingStatusAtom);
    const appBg = useRecoilValue(appBgAtom);
    const [appBgIndex, setAppBgIndex] = useRecoilState(appBgIndexAtom);

    // Local states
    const [welcomeMessage, setWelcomeMessage] = React.useState({
        visible: false,
    });
    const [appClassName, setAppClassName] = React.useState('app');

    // state for main nav drawer
    const [showNavDrawer, setShowNavDrawer] = React.useState(false);

    const [sideDrawerNavVisibility, setSideDrawerNavVisibility] =
        React.useState(false);
    const [universalNotepad, setUniversalNotepad] = React.useState({
        visible: false,
        value: '',
    });

    // History
    let history = useHistory();

    // Destructuring
    const { newEmailUpdated } = props;
    const { pathname } = useLocation();

    // Variables and constants

    // On component mount
    useEffect(() => {
        // Update resAxios headers
        UPDATE_RES_AXIOS_HEADER_AUTH(localStorage.getItem('RES_ACCESS'));
        // Append Google maps API script tag on component load
        GmapApiScript = document.createElement('script');
        GmapApiScript.setAttribute(
            'src',
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places`
        );
        document.body.appendChild(GmapApiScript);
        // Welcome message popup
        setWelcomeMessage(prevValues => ({ ...prevValues, visible: true }));
    }, []);

    // Clean up hook to remove Google maps API script tag on component load
    useEffect(() => {
        return () => {
            document.body.removeChild(GmapApiScript);
        };
    }, []);

    useEffect(() => {
        changeBackground();
    }, [appBg]);

    // On pathname change
    useEffect(() => {
        changeBackground();
        // Close the main nav drawer
        setShowNavDrawer(false);
    }, [pathname]);

    const changeBackground = () => {
        if (pathname.includes('stories') || pathname.includes('/user')) {
            setAppClassName('app app--gray-bg');
            const app_body = document.getElementsByClassName('app-body')[0];
            if (appBg && appBg.url) {
                app_body.style.backgroundImage = `url(${appBg.url})`;
            } else {
                // app_body.style.background = `#2A2E33`;
            }

            if (appBgIndex === 0) {
                app_body.style.backgroundSize = '260px';
                app_body.style.backgroundRepeat = 'repeat';
            } else {
                app_body.style.backgroundSize = 'cover';
                app_body.style.backgroundPosition = 'center center';
                app_body.style.backgroundRepeat = 'no-repeat';
            }
        } else if (pathname.includes('stream')) {
            setAppClassName('app app--dark-bg');
            const app_body = document.getElementsByClassName('app-body')[0];
            app_body.removeAttribute('style');
        } else if (pathname.includes('agora')) {
            setAppClassName('app app--agora-bg');
            const app_body = document.getElementsByClassName('app-body')[0];
            app_body.removeAttribute('style');
        } else {
            setAppClassName('app');
        }
    };

    // CREATE NEW PROPERTY
    // Reference keys
    const creatingPropertyMessageKey = 'creatingPropertyMessageKey';
    // Create new property function
    const createNewProperty = () => {
        setIsCreatingNewProperty(true);
        message.loading({
            content: 'Creating property ...',
            key: creatingPropertyMessageKey,
        });
        resAxios
            .post(`${process.env.REACT_APP_HOST}/v1/properties`, {
                type: DEFAULT_PROPERTY_TYPE_ON_CREATE,
                currency: DEFAULT_CURRENCY_TYPE_ON_CREATE,
            })
            .then(res => {
                history.push('/');
                history.push({ pathname: `/properties/${res.data.id}` });
                message.success({
                    content: 'New property created',
                    key: creatingPropertyMessageKey,
                });
                setProperties(prevProperties => [
                    { ...res.data },
                    ...prevProperties,
                ]);
                setIsCreatingNewProperty(false);
            })
            .catch(err => {
                console.error(err, 'Failed to create property');
                message.error('Failed to create property');
            });
    };

    // Display success message on email update
    React.useEffect(() => {
        const userEmail = JSON.parse(localStorage.getItem('RES_USER'));
        const newEmailUpdatedModalContent = (
            <>
                Your email address has been successfully updated to
                <span className="color-primary"> {userEmail.email}</span>
            </>
        );

        newEmailUpdated &&
            Modal.success({
                title: 'Email address updated',
                content: newEmailUpdatedModalContent,
                okButtonProps: { className: 'ant-btn-squared' },
            });
    }, [newEmailUpdated]);

    // Toggle Main navbar drawer
    const toggleMainNavDrawerVisibility = () => {
        setShowNavDrawer(!showNavDrawer);
    };

    // Toggle Side drawer nav visibility
    const toggleSideDrawerNavVisibility = state => {
        if (state == true) {
            setSideDrawerNavVisibility(true);
        } else if (state == false) {
            setSideDrawerNavVisibility(false);
        } else {
            setSideDrawerNavVisibility(!sideDrawerNavVisibility);
        }
    };

    // Set Universal notepad visibility
    const setUniversalNotepadVisibility = bool => {
        setUniversalNotepad(prevValues => ({
            ...prevValues,
            visible: bool,
        }));
    };

    // On Universal notepad change
    const onUniversalNotepadChange = value => {
        setUniversalNotepad(prevValues => ({
            ...prevValues,
            value,
        }));
    };

    // On Universal notepad close
    const onUniversalNotepadClose = () => {
        setUniversalNotepadVisibility(false);
    };

    // Handle Universal notepad open
    const handleUniversalNotepadOpen = () => {
        setUniversalNotepadVisibility(true);
        toggleSideDrawerNavVisibility();
    };

    React.useEffect(() => {
        const appBgIndex = JSON.parse(localStorage.getItem('APP_BG_INDEX'));
        setAppBgIndex(appBgIndex || 0);
    }, [setAppBgIndex]);

    return (
        <div className={appClassName}>
            {/* Main Nav Drawer */}
            <MainNavDrawer
                onClose={toggleMainNavDrawerVisibility}
                visible={showNavDrawer}
            />
            <MainNav
                toggleMainNavDrawerVisibility={toggleMainNavDrawerVisibility}
                toggleSideDrawerNavVisibility={toggleSideDrawerNavVisibility}
            />
            {/* Side drawer nav */}
            <SideDrawerNav
                visible={sideDrawerNavVisibility}
                toggleSideDrawerNavVisibility={toggleSideDrawerNavVisibility}
                handleUniversalNotepadOpen={handleUniversalNotepadOpen}
            />
            <div className="app-body">
                <div className="app-body__inner">
                    <ProtectedRoutes
                        createNewProperty={createNewProperty}
                        getUserDetails={props.getUserDetails}
                    />
                </div>
            </div>
            {/* Universal notepad */}
            <UniversalNotepad
                visible={universalNotepad.visible}
                onClose={onUniversalNotepadClose}
                value={universalNotepad.value}
                onChange={onUniversalNotepadChange}
            />
            {/* Welcome message */}
            {/* {props.userIsNew && (
                <WelcomeMessage
                    visible={welcomeMessage.visible}
                    onOverlayClose={() =>
                        setWelcomeMessage(prevVales => ({
                            ...prevVales,
                            visible: false,
                        }))
                    }
                />
            )} */}
            {/* Saving status */}
            {savingStatus && <SavingStatus />}
        </div>
    );
}
