import React, { useState } from 'react';
// Import from dependencies
import { Select } from 'antd';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
    userDetails as userDetailsAtom,
    loadedStories as loadedStoriesAtom,
    activeStoryId as activeStoryIdAtom,
    isUpdatingStoryCategory as isUpdatingStoryCategoryAtom,
} from '../../../../recoil/atoms';
// Import utils
import { STORY_CATEGORIES } from '../../../../exports/constants';
import resAxios from '../../../../exports/resAxios';
// Import components
import StoryActionEach from './StoryActionEach/StoryActionEach';
// Import icon components
import ShareIconOutlined from '../../../../assets/images/svg/ShareIconOutlined';
import LightIconFilled from '../../../../assets/images/svg/LightIconFilled';
import LightIconOutlined from '../../../../assets/images/svg/LightIconOutlined';

export default function StoryEachActions(props) {
    // Global states
    const userDetails = useRecoilValue(userDetailsAtom);
    const activeStoryId = useRecoilValue(activeStoryIdAtom);
    const isUpdatingStoryCategory = useRecoilValue(isUpdatingStoryCategoryAtom);
    const [loadedStories, setLoadedStories] = useRecoilState(loadedStoriesAtom);
    // Local states
    const [disableLightBtn, setDisableLightBtn] = React.useState(false);

    // Destructuring
    const { story } = props;

    useState(() => {
        console.log("STORY", story);
    }, [story])

    // Variables and constants
    const storyActionLeft =
        story.user_id === userDetails.id ? (
            <Select
                showSearch
                placeholder="Category"
                defaultValue={story.category}
                onChange={val =>
                    props.handleStoryValUpdate('category', val, story.id)
                }
                loading={isUpdatingStoryCategory && story.id === activeStoryId}
                disabled={isUpdatingStoryCategory && story.id === activeStoryId}
            >
                {STORY_CATEGORIES.map((category, i) => (
                    <Select.OptGroup key={i} label={category.category}>
                        {category.subcategories.map(sub_cat => (
                            <Select.Option key={sub_cat} value={sub_cat}>
                                {sub_cat}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                ))}
            </Select>
        ) : (
            story.category && (
                <div className="story-each__category-static">
                    {story.category}
                </div>
            )
        );

    // If story is lit
    const storyIsLit = () => {
        try{
            return story.story_likes.some(like => like.user_id === userDetails.id)
            ? true
            : false;
        }catch(err){
            console.error(err);
        }
    };

    // Toggle story light
    const toggleStoryLight = () => {
        if (disableLightBtn) return;
        setDisableLightBtn(true);
        const storyIsLitVal = storyIsLit();
        const toggle = storyIsLitVal ? 'unlike' : 'like';
        // Generate updated list of stories
        const stories = loadedStories.data.map(story =>
            story.id === props.story.id
                ? {
                      ...story,
                      story_likes: storyIsLitVal
                          ? story.story_likes.filter(
                                like => like.user_id !== userDetails.id
                            )
                          : [...story.story_likes, { user_id: userDetails.id }],
                  }
                : story
        );
        // Set updated stories in global state
        setLoadedStories(prevValues => ({ ...prevValues, data: stories }));
        // Send request
        resAxios
            .post(
                `${process.env.REACT_APP_HOST}/v1/stories/${story.id}:${toggle}`
            )
            .then()
            .catch()
            .finally(() => setDisableLightBtn(false));
    };

    // Get light icon
    const getLightIcon = () => {
        return storyIsLit() ? <LightIconFilled /> : <LightIconOutlined />;
    };
    return (
        <div className="story-each__actions">
            <div className="story-each__actions-col story-each__actions-col--left">
                {storyActionLeft}
            </div>
            <div className="story-each__actions-col story-each__actions-col--right">
                {/* prettier-ignore */}
                <StoryActionEach icon={<ShareIconOutlined />} label="Share" count="0" storyId={story.id} />
                {/* prettier-ignore */}
                <StoryActionEach className="story-each__action-each--light" icon={getLightIcon()} label="Inspired" count={story?.story_likes?.length + ''} onClick={toggleStoryLight} />
            </div>
        </div>
    );
}
