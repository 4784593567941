import React from 'react';
// Import from dependencies
import { useLocation } from 'react-router-dom';
// Import from Recoil
import { useRecoilValue } from 'recoil';
import { userDetails as userDetailsAtom } from '../../../../recoil/atoms';
// Import icons
import UserIconBorderBottomRound from '../../../../assets/images/svg/user-icon-border-bottom-round.svg';
import UserIconSingle from '../../../../assets/images/svg/UserIconSingle';

export default function MainNavLinks(props) {
    // Global state

    const userDetails = useRecoilValue(userDetailsAtom);
    // Local state
    const [isUserSettingsPage, setIsUserSettingsPage] = React.useState();

    // History
    const location = useLocation();
    // Destructuring
    const { pathname } = location;

    // On component mount
    React.useEffect(() => {
        setIsUserSettingsPage(pathname.includes('/user/'));
    }, [pathname]);
    return (
        <div
            className={
                isUserSettingsPage
                    ? 'main-nav__user main-nav__user--active'
                    : 'main-nav__user'
            }
        >
            <div
                className="main-nav__user-wrapper" id="main-nav-toggler"
                onClick={props.toggleSideDrawerNavVisibility}
            >
                <div
                    className="main-nav__user-img"
                    style={{
                        backgroundImage:
                            userDetails.image_link &&
                            `url(${process.env.REACT_APP_STORAGE_PROFILE}/${userDetails.image_link})`,
                    }}
                >
                    {!userDetails.image_link && (
                        <span className="main-nav__user-img-icon">
                            <UserIconSingle />
                        </span>
                    )}
                    <span className="main-nav__user-img-border">
                        <img src={UserIconBorderBottomRound} alt="Border" />
                    </span>
                </div>
            </div>
        </div>
    );
}
