import React from 'react';
// Import from Ant design
import { Modal } from 'antd';
// Import icon components
import CloseIconRound from '../../../assets/images/svg/CloseIconRound';

export default function MultiPurposeModal(props) {
	return (
		<Modal
			centered={props.centered}
			title={props.title ? props.title : 'Multi purpose modal'}
			visible={props.visible}
			confirmLoading={props.confirmLoading}
			onOk={props.onOk}
			onCancel={props.onCancel}
			closable={props.closable}
			closeIcon={<CloseIconRound />}
			afterClose={props.afterClose}
			maskClosable={props.maskClosable}
			okText={props.okText}
			cancelText={props.cancelText}
			className={
				props.className
					? `ant-modal--multipurpose ${props.className}`
					: 'ant-modal--multipurpose'
			}
			okButtonProps={props.okButtonProps}
			footer={props.footer}
		>
			{props.children ? (
				<>
					{props.subtitle && (
						<p className="ant-modal-subtitle">{props.subtitle}</p>
					)}
					{props.children}
				</>
			) : (
				<p>
					This is a multi purpose modal. Please configure as required.
				</p>
			)}
		</Modal>
	);
}
