import React from 'react';
// Import from dependencies
import { Drawer } from 'antd';
import { useRecoilValue } from 'recoil';
import { savingStatus as savingStatusAtom } from '../../../recoil/atoms';
// Import components
import RichTextEditor from '../RichTextEditor/RichTextEditor';
// Import icon components
import NotepadIconOutlined from '../../../assets/images/svg/NotepadIconOutlined';
import CaretDownIcon from '../../../assets/images/svg/CaretDownIcon';

export default function PropertyNotepad(props) {
    // Global states
    const savingStatus = useRecoilValue(savingStatusAtom);
    return (
        <div className="property-notepad">
            <Drawer
                className="ant-drawer--property-notepad"
                title={<NotepadTitle savingStatus={savingStatus} />}
                placement="bottom"
                height={440}
                onClose={props.onClose}
                visible={props.visible}
                closeIcon={<CaretDownIcon />}
            >
                <RichTextEditor
                    placeholder="Type your note"
                    value={props.value}
                    onChange={props.onChange}
                />
            </Drawer>
        </div>
    );
}

const NotepadTitle = function (props) {
    let status = '';
    switch (props.savingStatus) {
        case 'saving':
            status = 'Saving ...';
            break;
        case 'failed':
            status = 'Failed to save !';
            break;
        default:
            status = '';
    }
    return (
        <>
            <NotepadIconOutlined /> <span>{status || 'Notepad'}</span>
        </>
    );
};
