import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Import from dependencies
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
// Main app
import Auth from '../containers/Auth/Auth';
import Roadmap from '../containers/Roadmap/Roadmap';

export default () => (
    <Switch>
        <Route path="/auth/start" component={Auth} />
        <Route exact path="/auth/linkedin" component={LinkedInPopUp} />
        <Route exact path="/roadmap" component={Roadmap} />
        <Redirect to="/auth/start" />
    </Switch>
);
