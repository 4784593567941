import React from 'react';
// Import from recoil
import { useRecoilState } from 'recoil';
import { userDetails as userDetailsAtom } from '../../../../recoil/atoms';
// Import constants
import { PROPERTY_IMAGE_SUPPORTED_EXT } from '../../../../exports/constants';
// Import from Ant design
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Import from dependencies
import axios from 'axios';
// Import icon components
import EditIconOutlined from '../../../../assets/images/svg/UploadImageEditIcon';
// Import from exports
import resAxios from '../../../../exports/resAxios';

export default function ProfileImageUpload(props) {
    // Local states
    const [isUploading, setIsUploading] = React.useState(false);
    // Global states
    const [userDetails, setUserDetails] = useRecoilState(userDetailsAtom);

    // Destructuring
    const { userField, uploadFor } = props;

    // Variables and constants
    const rand = Math.random();

    const onPropertyImageUploadSuccess = fileName => {
        // Update in global state
        setUserDetails(prevValues => ({
            ...prevValues,
            [userField]: fileName,
        }));
        // Update in local storage
        const userDetailsPrev = JSON.parse(localStorage.getItem('RES_USER'));
        const userDetailsUpdated = JSON.stringify({
            ...userDetailsPrev,
            [userField]: fileName,
        });
        localStorage.setItem('RES_USER', userDetailsUpdated);
    };

    const onProfileImageUploadFail = () => {
        message.error('Failed to update profile image');
        setIsUploading(false);
    };

    const onProfileImageChange = event => {
        setIsUploading(true);
        const image = event.target.files[0];
        let imageType;
        if (image.type === 'image/jpeg' || image.type === 'image/jpg') {
            imageType = 'jpeg';
        } else if (image.type === 'image/png') {
            imageType = 'png';
        }
        resAxios
            .post(`${process.env.REACT_APP_HOST}/v1/file/${uploadFor}`, {
                imageType,
            })
            .then(response => {
                const responseFilename = response.data.filename;
                const responseUrl = response.data.url;
                // console.log(response, 'response');
                return axios
                    .put(`${responseUrl}`, image)
                    .then(response => {
                        return resAxios
                            .patch(`${process.env.REACT_APP_HOST}/v1/me`, {
                                [userField]: responseFilename,
                            })
                            .then(response => {
                                onPropertyImageUploadSuccess(responseFilename);
                                setIsUploading(false);
                            })
                            .catch(error => onProfileImageUploadFail());
                    })
                    .catch(error => onProfileImageUploadFail());
            })
            .catch(error => onProfileImageUploadFail());
    };

    let uploadIcon;
    if (isUploading) {
        uploadIcon = (
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24, zIndex: 1000 }} spin />}
            />
        );
    } else {
        uploadIcon = <EditIconOutlined />;
    }

    return (
        <React.Fragment>
            <label
                htmlFor={`propertyImageUploadInput-${rand}`}
                className={
                    isUploading
                        ? 'property__image-upload uploading'
                        : 'property__image-upload'
                }
                style={{
                    backgroundImage: `url('${process.env.REACT_APP_STORAGE_PROFILE}/${userDetails[userField]}')`,
                }}
            >
                <input
                    id={`propertyImageUploadInput-${rand}`}
                    type="file"
                    onChange={event => onProfileImageChange(event)}
                    accept={PROPERTY_IMAGE_SUPPORTED_EXT}
                    hidden
                />
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    zIndex: '10000',
                    cursor: 'pointer'
                }}>
                    <div style={{
                        height: '50px',
                        width: '50px',
                        backgroundColor: '#f3f3f38b',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '100%'
                    }}>
                        {uploadIcon}
                    </div>
                </div>
            </label>
        </React.Fragment>
    );
}
