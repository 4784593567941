import React, { useEffect, useState } from 'react';
// Import from dependencies
// Import from recoil
import { useRecoilValue } from 'recoil';
import {
    activeStoryId as activeStoryIdAtom,
    savingStory as savingStoryAtom,
    userDetails as userDetailsAtom,
} from '../../../recoil/atoms';
import { Menu, Dropdown, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Import utils
import resAxios from '../../../exports/resAxios';
import { GET_DISPLAY_NAME } from '../../../exports/functions';
// Import functions
import { REMOVE_LAST_LINE_BREAK } from '../../../exports/functions';
// Import icon components
import SaveIconOutlined from '../../../assets/images/svg/SaveIconOutlined';
import SaveIconFilled from '../../../assets/images/svg/SaveIconFilled';
import MoreVerticalIcon from '../../../assets/images/svg/MoreVerticalIcon';
// Import components
import PhotoPreview from '../../Common/PhotoPreview/PhotoPreview';
import StoryEachFiles from './StoryEachFiles/StoryEachFiles';
import StoryEachActions from './StoryEachActions/StoryEachActions';
// reload logo
import ReloadLogo from '../../../assets/images/reload-logo-small.svg';

export default function StoryEach(props) {
    // Global state
    const savingStory = useRecoilValue(savingStoryAtom);
    const userDetails = useRecoilValue(userDetailsAtom);
    const activeStoryId = useRecoilValue(activeStoryIdAtom);

    const [story, setStory] = useState();

    const [formattedDate, setFormattedDate] = useState();
    const [storyWithoutLineBreak, setStoryWithoutLineBreak] = useState("");
    const [authorPhotoUrl, setAuthorPhotoUrl] = useState();
    const [authorDisplayName, setAuthorDisplayName] = useState();

    // Local states
    const [storyFiles, setStoryFiles] = React.useState([]);

    const formateDate = (dateString) => {
        if (!dateString) {
            return;
        }
        const createdAtDate = new Date(dateString);
        if (createdAtDate) {
            const formatedCreatedAtDate = new Intl.DateTimeFormat(navigator.language, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            }).format(createdAtDate);
            setFormattedDate(formatedCreatedAtDate);
        }
    }

    const processAuthorPhotoUrl = (author) => {
        if (author) {
            const authorPhotoUrl =
                author.show_agency_info && author.agency_logo
                    ? `${process.env.REACT_APP_STORAGE_PROFILE}/${author?.agency_logo}`
                    : !author.show_user_info
                        ? ReloadLogo
                        : `${process.env.REACT_APP_STORAGE_PROFILE}/${author?.image_link}`;
            setAuthorPhotoUrl(authorPhotoUrl);
        }
    }

    const procssAuthorDisplayName = (author) => {
        if (author) {
            const _authorDisplayName = GET_DISPLAY_NAME(
                author?.first_name,
                author?.last_name,
                author?.show_user_info,
                author?.username,
                author?.email,
                author?.show_agency_info,
                author?.agency_name
            );
            setAuthorDisplayName(_authorDisplayName);
        }
    }

    useEffect(() => {
        if (props.story) {
            setStory(props.story);
        }
    }, [props.story]);

    useEffect(() => {
        if (story) {
            setStoryWithoutLineBreak(REMOVE_LAST_LINE_BREAK(story.story));
            formateDate(story.created_at);
            processAuthorPhotoUrl(story.user);
            procssAuthorDisplayName(story.user);
        }
    }, [story]);


    // More dropdown menu
    const moreDropdownMenu = (
        <Menu className="dropdown-menu-general dropdown-menu-general--collapsed">
            <Menu.Item onClick={props.editStoryModalOpen}>
                <span>Edit</span>
            </Menu.Item>
            {props.story.published && (
                <Menu.Item onClick={props.handleStoryMoveToDrafts}>
                    <span>Move to archive</span>
                </Menu.Item>
            )}

            <Menu.Item danger onClick={props.deleteStoryConfirmationOpen}>
                Delete
            </Menu.Item>
        </Menu>
    );

    // Save icon
    let saveIcon;
    let saveIconClassName = ['story-each__action-save'];
    if (savingStory && props.story.id === activeStoryId) {
        saveIcon = <LoadingOutlined />;
    } else if (userDetails.saved_stories?.find(id => id === props.story.id)) {
        saveIcon = <SaveIconFilled />;
        saveIconClassName.push('story-each__action-save--active');
    } else {
        saveIcon = <SaveIconOutlined />;
    }

    // Get story files on component mount
    React.useEffect(() => {
        if (story && story.id) {
            resAxios
                .get(`${process.env.REACT_APP_HOST}/v1/media?resourceId=${story.id}`)
                .then(res =>
                    setStoryFiles(prevValues => [...prevValues, ...res.data.data])
                )
                .catch(err => {
                    console.error(err, 'Story files fetch error');
                    message.error('Failed to load files');
                });
        }
    }, [story]);

    return (
        <div className="story-each stories-feed-block">
            <div className="story-each__top">
                <div className="story-each_top__left">
                    <div className="story-each__photo">
                        <PhotoPreview photoUrl={authorPhotoUrl} />
                    </div>
                    <div className="story-each__username">
                        {authorDisplayName}
                    </div>
                    <div className="story-each__date-posted">
                        {formattedDate}
                    </div>
                </div>
                <div className="story-each_top__right">
                    <div
                        className={saveIconClassName.join(' ')}
                        onClick={props.handleStorySave}
                    >
                        {saveIcon}
                    </div>
                    {story && story.user_id === userDetails.id && (
                        <Dropdown
                            overlay={moreDropdownMenu}
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <div className="story-each__action-more">
                                <MoreVerticalIcon />
                            </div>
                        </Dropdown>
                    )}
                </div>
            </div>

            <div className="story-each__title">{props.story.title}</div>
            <div
                className="story-each__content"
                dangerouslySetInnerHTML={{ __html: storyWithoutLineBreak }}
            ></div>
            {storyFiles.length ? (
                <StoryEachFiles
                    story={props.story}
                    storyFiles={storyFiles}
                    onStoryEachFileClick={file =>
                        props.onStoryEachFileClick(file, storyFiles)
                    }
                />
            ) : null}
            {
                story
                    ? <StoryEachActions
                        story={story}
                        handleStoryValUpdate={props.handleStoryValUpdate}
                    />
                    : null
            }
        </div>
    );
}
