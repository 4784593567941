import React from 'react';
// Import from dependencies
import ImageLightboxCommon from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function ImageLightbox({ getter, setter }) {
    const images = getter.images;
    const initialIndex = getter.initialIndex;
    return (
        <ImageLightboxCommon
            mainSrc={images[initialIndex]}
            nextSrc={images[(initialIndex + 1) % images.length]}
            prevSrc={images[(initialIndex + images.length - 1) % images.length]}
            onCloseRequest={() =>
                setter(prevValues => ({
                    ...prevValues,
                    visible: false,
                }))
            }
            onMovePrevRequest={() =>
                setter(prevValues => ({
                    ...prevValues,
                    initialIndex:
                        (initialIndex + images.length - 1) % images.length,
                }))
            }
            onMoveNextRequest={() =>
                setter(prevValues => ({
                    ...prevValues,
                    initialIndex: (initialIndex + 1) % images.length,
                }))
            }
        />
    );
}
