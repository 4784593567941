import React from 'react';
// Import from dependencies
import { Switch, Route, Redirect } from 'react-router-dom';
// Main app
import User from '../containers/User/User';
import StoriesFeed from '../containers/Story/StoriesFeed/StoriesFeed';
import Stream from '../containers/Stream/Stream';
import Agora from '../containers/Agora/Agora';

export default function ProtectedRoutes(props) {
    return (
        <Switch>
            {/* <Route path="/agora" component={Agora} /> */}
            <Route path="/stories" component={StoriesFeed} />
            <Route exact path="/stream" component={Stream} />
            <Route path="/user" component={User} />
            <Redirect to="/stories" />
        </Switch>
    );
}
