import React from 'react';

export default function LightIconOutlined() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.2266 6.73425C11.2266 6.53589 11.3054 6.34565 11.4456 6.20539C11.5859 6.06513 11.7761 5.98633 11.9745 5.98633C12.1728 5.98633 12.3631 6.06513 12.5034 6.20539C12.6436 6.34565 12.7224 6.53589 12.7224 6.73425V8.2331C12.7224 8.43146 12.6436 8.62169 12.5034 8.76196C12.3631 8.90222 12.1728 8.98102 11.9745 8.98102C11.7761 8.98102 11.5859 8.90222 11.4456 8.76196C11.3054 8.62169 11.2266 8.43146 11.2266 8.2331V6.73425Z"
                fill="#3F3D56"
            />
            <path
                d="M16.2387 8.14281C16.0984 8.0026 15.9082 7.92383 15.7099 7.92383C15.5116 7.92383 15.3214 8.0026 15.1811 8.14281L14.1221 9.20337C13.9858 9.34443 13.9104 9.53336 13.9121 9.72946C13.9138 9.92556 13.9925 10.1132 14.1312 10.2518C14.2698 10.3905 14.4574 10.4692 14.6535 10.4709C14.8496 10.4726 15.0386 10.3972 15.1796 10.2609L16.2402 9.20038C16.3804 9.06012 16.4592 8.86992 16.4592 8.67159C16.4592 8.47327 16.3804 8.28307 16.2402 8.14281H16.2387Z"
                fill="#3F3D56"
            />
            <path
                d="M8.76246 8.14239C8.69347 8.07096 8.61094 8.01398 8.51969 7.97478C8.42844 7.93559 8.3303 7.91495 8.23099 7.91409C8.13168 7.91323 8.03319 7.93215 7.94127 7.96976C7.84936 8.00736 7.76585 8.0629 7.69562 8.13312C7.6254 8.20335 7.56986 8.28686 7.53226 8.37877C7.49465 8.47069 7.47573 8.56918 7.47659 8.66849C7.47745 8.7678 7.49809 8.86594 7.53728 8.95719C7.57648 9.04844 7.63346 9.13097 7.7049 9.19996L8.76396 10.2605C8.83295 10.332 8.91548 10.3889 9.00673 10.4281C9.09798 10.4673 9.19612 10.488 9.29543 10.4888C9.39474 10.4897 9.49323 10.4708 9.58514 10.4332C9.67706 10.3955 9.76057 10.34 9.83079 10.2698C9.90102 10.1996 9.95655 10.1161 9.99416 10.0241C10.0318 9.93222 10.0507 9.83374 10.0498 9.73443C10.049 9.63512 10.0283 9.53698 9.98913 9.44573C9.94994 9.35448 9.89296 9.27195 9.82152 9.20295L8.76246 8.14239Z"
                fill="#3F3D56"
            />
            <path
                d="M11.9751 0C16.9323 0 20.9502 3.88472 20.9502 8.67593C20.9502 11.1845 19.8358 13.4866 17.6533 15.5479C17.5588 15.6371 17.4848 15.7458 17.4364 15.8665L17.3976 15.9876L15.9974 21.8469C15.7222 23.0002 14.7035 23.8364 13.4994 23.9261L13.284 23.9336H10.6662C9.44262 23.9336 8.3701 23.1617 8.0096 22.0458L7.95276 21.8469L6.55414 15.9891C6.51417 15.8204 6.42578 15.667 6.29984 15.5479C4.22061 13.5853 3.1092 11.4044 3.00898 9.03343L3 8.67593L3.00449 8.39022C3.16155 3.73065 7.11807 0 11.9751 0ZM8.91609 19.446L9.39326 21.4415L9.43215 21.5851C9.57725 22.0339 9.99459 22.3659 10.4972 22.4273L10.6662 22.4377H13.2286L13.3872 22.4333C13.627 22.4195 13.8581 22.3386 14.0541 22.1997C14.2501 22.0608 14.4031 21.8696 14.4956 21.6479L14.542 21.4983L15.0341 19.446H8.91609V19.446ZM11.9751 1.49585C8.03802 1.49585 4.81297 4.41724 4.51829 8.09853L4.50034 8.41565L4.49585 8.64451L4.50183 8.96911C4.5856 10.9197 5.51004 12.7461 7.326 14.4604C7.60722 14.7266 7.81963 15.0557 7.94528 15.4177L8.0096 15.6421L8.55858 17.9502H11.2272V11.2144C11.2272 11.016 11.306 10.8258 11.4462 10.6855C11.5865 10.5453 11.7767 10.4665 11.9751 10.4665C12.1735 10.4665 12.3637 10.5453 12.504 10.6855C12.6442 10.8258 12.723 11.016 12.723 11.2144V17.9502H15.3931L15.972 15.5344L16.0483 15.31C16.1769 14.9884 16.3744 14.6982 16.6257 14.4604C18.5314 12.6624 19.4543 10.7417 19.4543 8.67593C19.4543 4.7224 16.1171 1.49585 11.9751 1.49585Z"
                fill="#3F3D56"
            />
        </svg>
    );
}
