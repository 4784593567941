import React from 'react';

export default function Policies() {
	return (
		<div className="user__tabs-body-iframe">
			<iframe
				src="https://www.iubenda.com/privacy-policy/76327275"
				title="Reload policies"
			></iframe>
		</div>
	);
}
