import React from 'react';
// Import from dependencies
import { useTranslation } from 'react-i18next';
// Import components
import PlanSubscriptionInfo from '../../Common/MultiPurposeModal/MultiPurposeModal';

export default function PlanSubscriptionInfoModal(props) {
  // i18n
  const { t } = useTranslation();
  return (
    <PlanSubscriptionInfo
      className='ant-modal--plan-subscription-info'
      centered={true}
      visible={props.planSubscriptionModal.visible}
      title={t('pricing.subscriptionInfoModal.title')}
      footer={false}
      onCancel={() => props.setPlanSubscriptionModalVisible(false)}
    >
      <p>{t('pricing.subscriptionInfoModal.p1')}</p>
      <p>{t('pricing.subscriptionInfoModal.p2')}</p>
      <p>{t('pricing.subscriptionInfoModal.p3')}</p>
    </PlanSubscriptionInfo>
  );
}
