import React, { useEffect, useState } from 'react';
// Import from dependencies
import { Link, NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
    userDetails as userDetailsAtom,
    storiesCount as storiesCountAtom,
} from '../../../recoil/atoms';
import { Tag, Anchor, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
// Import from exports
import { STORIES_SIDEBAR_MENU_ITEMS } from '../../../exports/constants';
import { GET_DISPLAY_NAME } from '../../../exports/functions';
// Import assets
import Ad from '../../../assets/images/ad.gif';
// Import components
import StoriesSidebarPhotoPreview from '../../Common/PhotoPreview/PhotoPreview';
// Import icon components
import PostsIcon from '../../../assets/images/svg/PostsIcon';
import DraftIcon from '../../../assets/images/svg/DraftIcon';
import SaveIconFilled from '../../../assets/images/svg/SaveIconFilled';
import ListBarsIcon from '../../../assets/images/svg/ListBarsIcon';

export default function StoriesSidebarRight(props) {
    // Global state
    const userDetails = useRecoilValue(userDetailsAtom);
    const storiesCount = useRecoilValue(storiesCountAtom);
    // Stories sidebar menu icons
    const storiesSidebarRightIcons = [
        <PostsIcon />,
        <ListBarsIcon />,
        <DraftIcon />,
        <SaveIconFilled />,
    ];

    // Array of number of stories by type
    const storiesCountArr = Object.values(storiesCount);

    // sidebar hide/show state
    const [hideSidebars, setHideSideBars] = useState(false);
    const handleToggleSidebar = btnName => {
        const leftSidebar = document.getElementsByClassName(
            'sidebar-left-scrollable'
        );
        const rightSidebar = document.getElementsByClassName(
            'sidebar-right-scrollable'
        );
        if (btnName == 'btn_hide') {
            setHideSideBars(true);
            leftSidebar[0].classList.add('sidebar-hide');
            rightSidebar[0].classList.add('sidebar-hide');
        } else if (btnName == 'btn_show') {
            setHideSideBars(false);
            leftSidebar[0].classList.remove('sidebar-hide');
            rightSidebar[0].classList.remove('sidebar-hide');
        }
    };


    useEffect(() => {
        console.log(storiesCount);
    }, [storiesCount]);

    return (
        <>
            <div className="sidebar-right-container">
                <div className="sidebar-toggler-container">
                    <button
                        onClick={() => handleToggleSidebar('btn_hide')}
                        className={
                            hideSidebars ? 'toggle_sidebar_btn_active' : ''
                        }
                    >
                        <span>hide</span>
                    </button>
                    <button
                        onClick={() => handleToggleSidebar('btn_show')}
                        className={
                            !hideSidebars ? 'toggle_sidebar_btn_active' : ''
                        }
                    >
                        <span>show</span>
                    </button>
                </div>

                <div className="sidebar-right-scrollable">
                    <div className="story-sidebar story-sidebar--right">
                        <div className="story-sidebar__nav stories-feed-block stories-feed-block--sidebar">
                            <Link
                                to={{
                                    pathname: '/user/profile',
                                    state: window.location.pathname || '/',
                                }}
                            >
                                <div className="story-sidebar__profile">
                                    <div className="story-sidebar__profile-image">
                                        <StoriesSidebarPhotoPreview
                                            photoUrl={`${process.env.REACT_APP_STORAGE_PROFILE}/${userDetails.image_link}`}
                                        />
                                    </div>
                                    <div className="story-sidebar__profile-title">
                                        {GET_DISPLAY_NAME(userDetails.username)}
                                    </div>
                                    <div className="story-sidebar__profile-title">
                                        {`${userDetails.first_name}  ${userDetails.last_name}`}
                                    </div>
                                    {userDetails.title !== 'No title' && (
                                        <div className="story-sidebar__profile-subtitle">
                                            {userDetails.title}
                                        </div>
                                    )}
                                </div>
                            </Link>
                            <ul className="story-sidebar__right-links clean">
                                {STORIES_SIDEBAR_MENU_ITEMS.map((item, i) => (
                                    <li
                                        key={i}
                                        onClick={() =>
                                            props.fetchStoriesBy(item.param)
                                        }
                                    >
                                        <NavLink to={`/stories/${item.param}`}>
                                            <span>
                                                {storiesSidebarRightIcons[i]}
                                            </span>
                                            {item.label}
                                            <Tag color="#2196F3">
                                                {storiesCountArr[i]}
                                            </Tag>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* <div className="story-sidebar__ad">
                        <img src={Ad} alt="Advertisement" />
                    </div> */}
                </div>
            </div>

            {/* Stories bottombar only visible to mobile screen */}
            <div className="stories-bottombar">
                {STORIES_SIDEBAR_MENU_ITEMS.map((item, i) =>
                    item.label !== 'All stories' ? (
                        <NavLink
                            key={i}
                            onClick={() => props.fetchStoriesBy(item.param)}
                            to={`/stories/${item.param}`}
                        >
                            <span>{storiesSidebarRightIcons[i]}</span>
                            <p>
                                <span>{item.label}</span>
                                <Tag color="#2196F3">
                                    ( {storiesCountArr[i]} )
                                </Tag>
                            </p>
                        </NavLink>
                    ) : null
                )}
                {/* Back to top for reaching categories bar */}
                {/* <a onClick={() => highlightCategoriesBar()} href={"#stories-sidebar-left"}>
                    <span><PostsIcon /></span>
                    Categories
                </a> */}
            </div>
        </>
    );
}
