import React from 'react';

export default function FileTypeVideoIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23 7L16 12L23 17V7Z"
                fill="url(#paint0_linear)"
                stroke="url(#paint1_linear)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                fill="url(#paint2_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="16"
                    y1="9.29508"
                    x2="19.7245"
                    y2="9.40517"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="16"
                    y1="9.29508"
                    x2="19.7245"
                    y2="9.40517"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="1"
                    y1="8.21311"
                    x2="8.97171"
                    y2="8.57377"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2196F3" />
                    <stop offset="1" stopColor="#0065B5" />
                </linearGradient>
            </defs>
        </svg>
    );
}
