import React from 'react';

export default function SaveIconOutlined() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="save-icon-outlined-outline"
                d="M12.3354 17.3292L12 17.1615L11.6646 17.3292L4.75 20.7865V5C4.75 3.75736 5.75736 2.75 7 2.75H17C18.2426 2.75 19.25 3.75736 19.25 5V20.7865L12.3354 17.3292Z"
                stroke="#74788D"
                strokeWidth="1.5"
            />
            <path
                className="save-icon-outlined-tick"
                d="M9.70351 10.2691C9.31101 9.88057 8.67785 9.88379 8.28931 10.2763C7.90078 10.6688 7.90399 11.3019 8.29649 11.6905L9.70351 10.2691ZM11.5 13.4545L10.7965 14.1652L11.5 14.8616L12.2035 14.1652L11.5 13.4545ZM16.7035 9.71069C17.096 9.32215 17.0992 8.68899 16.7107 8.29649C16.3221 7.90399 15.689 7.90078 15.2965 8.28931L16.7035 9.71069ZM8.29649 11.6905L10.7965 14.1652L12.2035 12.7439L9.70351 10.2691L8.29649 11.6905ZM12.2035 14.1652L16.7035 9.71069L15.2965 8.28931L10.7965 12.7439L12.2035 14.1652Z"
                fill="#74788D"
            />
        </svg>
    );
}
