import React from 'react';
// Import from dependencies
import { Form, Input, Select, Switch } from 'antd';
import { useRecoilValue } from 'recoil';
// Import recoil values
import { userDetails as userDetailsAtom } from '../../../../recoil/atoms';
// Import exports
import { PROFESSIONS } from '../../../../exports/constants';
// Import components
import ProfileImageUpload from '../ProfileImageUpload/ProfileImageUpload';

export default function ProfileSectionLeft(props) {
    // Global state
    const userDetails = useRecoilValue(userDetailsAtom);

    return (
        <div className="user__profile-section-inner-section user__profile-section-inner-section--right">
            <div className="user__profile-social-profile">
                <p className="color-primary">Social Profile</p>
                <div
                    className="user__profile-section-inner-section-top"
                    style={{ marginBottom: '2rem' }}
                >
                    <ProfileImageUpload
                        userField="image_link"
                        uploadFor="profile-picture"
                    />
                </div>
                {/* <Form layout="vertical">
                    <Form.Item label="Username">
                        <Input
                            placeholder="Choose a username"
                            name="username"
                            onChange={e =>
                                props.onUserDetailsFormValuesChange(e)
                            }
                            value={userDetails.username}
                        />
                    </Form.Item>
                </Form> */}
                <div className="user__profile-social-agreement">
                    <p className="user__profile-social-agreement-text">
                        Display my name and avatar
                    </p>
                    <div className="user__profile-social-agreement-switch">
                        <Switch
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            checked={userDetails.show_user_info}
                            onChange={(event) => {
                                props.onUserDetailsFormValuesChange(event, {
                                    inputType: "switch",
                                    name: "show_user_info"
                                })
                            }}
                        />
                    </div>
                </div>
                <small className="small user__profile-section-left-helper-text">
                    If you choose 'yes', your above name will be visible to others.  If 'no', users will only be able to see the RELOAD avatar image and our Reload username.
                </small>
            </div>
        </div>
    );
}
