import React from 'react';
// Import components
import GetFileTypeIcon from '../../../../Misc/GetFileTypeIcon/GetFileTypeIcon';
import ReactPlayer from 'react-player';

export default function StoryEachFile(props) {
    return (
        <div
            className={`story-each__file${props.isLastFile ? ' story-each__file--last' : ''
                }`}
            style={{
                ...(props.file.file_type === 'image' && {
                    backgroundImage: `url(${process.env.REACT_APP_STORAGE_FILES}/${props.file.file_url})`,
                }),
            }}
            onClick={() => props.onStoryEachFileClick(props.file)}
        >
            {/* Display file icon if file is not an image */}
            {props.file.file_type !== 'image' && (
                // <>
                //     <div className="story-each__file-icon">
                //         <GetFileTypeIcon fileType={props.file.file_type} />
                //     </div>
                //     <div className="story-each__file-icon-text">
                //         Click to open this{' '}
                //         {props.file.file_type === 'audio' ||
                //         props.file.file_type === 'video'
                //             ? 'file'
                //             : 'document'}
                //     </div>
                //     <div className="story-each__file-name">
                //         {props.file.title}
                //     </div>
                // </>
                <ReactPlayer
                    height={props.file.file_type === 'audio' ? 'auto' : '100%'} style={{
                        maxWidth: '100%'
                    }}
                    url={`${process.env.REACT_APP_STORAGE_FILES}/${props.file.file_url}`}
                    controls={true}
                />
            )}
            {props.isLastFile && props.remainingFilesToDisplay > 1 && (
                <div className="story-each__file-remaining-count">
                    + {props.remainingFilesToDisplay}
                </div>
            )}
        </div>
    );
}
