import React from 'react';
// Import styles
// import './AwaitingConfirmation.css';
// Import components
import { useTranslation } from 'react-i18next';
// Import icon components
import ReloadLogoPill from '../../../assets/images/Reload-Logo-Pill.png'

export default function AwaitingConfirmation(props) {
    const { t } = useTranslation();

    const AwaitingConfirmation1440Data = {
        awaitingConfirmation: t(
            'auth.awaitingConfirmationData.awaitingConfirmation'
        ),
        spanText: t('auth.awaitingConfirmationData.spanText'),
        spanText2: t('auth.awaitingConfirmationData.spanText2'),
        spanText3: t('auth.awaitingConfirmationData.spanText3'),
        doNotCloseThisWindowUntilOpeningThe: t(
            'auth.awaitingConfirmationData.doNotCloseThisWindowUntilOpeningThe'
        ),
        spanText4: t('auth.awaitingConfirmationData.spanText4'),
        spanText5: 'youremail@mail.com',
        // spanText6: ' (',
        spanText7: 'undo',
        spanText8: (
            <>
                <br />
                {t('auth.awaitingConfirmationData.spanText8')}
            </>
        ),
        randomlyGeneratedWords: 'Randomly Generated Words',
        verifying: t('auth.awaitingConfirmationData.verifying'),
        authFailed: t('auth.awaitingConfirmationData.authFailed'),
        emailConfirmed: t('auth.awaitingConfirmationData.emailConfirmed'),
    };
    return (
        <AwaitingConfirmation1440
            {...AwaitingConfirmation1440Data}
            {...props}
        />
    );
}

function AwaitingConfirmation1440(props) {
    const {
        awaitingConfirmation,
        doNotCloseThisWindowUntilOpeningThe,
        spanText4,
        spanText6,
        spanText8,
    } = props;

    return (
        <div className="awaiting-confirmation-1440">
            <div className="awaiting-confirmation-block">
                <div className="awaiting-confirmation-logo">
                    <img style={{maxHeight: "70px"}} src={ReloadLogoPill} alt="Logo" />
                </div>
                <h1 className="awaiting-c-nfirmation border-class-1 montserrat-medium-gun-powder-36px">
                    {awaitingConfirmation}
                </h1>
                <div className="overlap-group1">
                    <p className="do-not-clo-email-link valign-text-middle border-class-1 montserrat-medium-dodger-blue-16px">
                        {doNotCloseThisWindowUntilOpeningThe}
                    </p>
                </div>
                <p className="we-just-se-owing-text border-class-1 montserrat-normal-white-16px">
                    <span className="span-1">{spanText4}</span>
                    <span className="span2-yNAluT">{props.emailAddress}</span>
                    <span className="span-1">{spanText6}</span>
                    <span className="span-1">{spanText8}</span>
                </p>
                <div className="overlap-group">
                    <div className="randomly-g-ated-words valign-text-middle roboto-normal-black-20px">
                        {props.verificationText}
                    </div>
                </div>
            </div>
        </div>
    );
}
