import React from 'react';
// Import from Recoil
import { useRecoilValue } from 'recoil';
import { savingStatus as savingStatusAtom } from '../../../recoil/atoms';
// Import from Ant design
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Import icon components
import CheckIconRoundFilled from '../../../assets/images/svg/CheckIconRoundFilled';
import ExclamationIconRoundFilled from '../../../assets/images/svg/ExclamationIconRoundFilled';

export default function SavingStatus(props) {
	// Recoil values
	const savingStatus = useRecoilValue(savingStatusAtom);

	let savingStatusText, savingStatusIcon;
	let savingStatusClass = `saving-status saving-status--${savingStatus}`;
	switch (savingStatus) {
		case 'saving':
			savingStatusText = 'Saving ... wait';
			savingStatusIcon = <Spin indicator={<LoadingOutlined />} />;
			break;
		case 'saved':
			savingStatusText = 'Data up to date';
			savingStatusIcon = <CheckIconRoundFilled />;
			break;
		case 'failed':
			savingStatusText = 'Failed to update';
			savingStatusIcon = <ExclamationIconRoundFilled />;
			break;
		case 'invalid_email':
			savingStatusText = 'Invalid email found';
			savingStatusIcon = <ExclamationIconRoundFilled />;
			break;
		default:
			return null;
	}

	return (
		<div className={savingStatusClass}>
			<div className="saving-status__icon">{savingStatusIcon}</div>
			<div className="saving-status__text">{savingStatusText}</div>
		</div>
	);
}
