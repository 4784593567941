// convrted svg from https://svg2jsx.com/

import React from "react";

function IconConnection() {
  const uid = Date.now();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#39372C"
        d="M11.334 12s-4.021 3.516-5.16 4.555c-.883.802.586 2.332 1.423 1.634 2.222-1.856 4.328-3.233 4.328-3.233l.245.35c-.873.527-3.129 1.906-4.3 2.96-.882.793.512 2.368 1.396 1.576 1.215-1.09 3.997-3.043 3.997-3.043l.245.256c-.72.452-1.848 1.293-3.323 2.638-.888.81.412 1.657 1.176 1.023 2.077-1.724 2.154-2.922 4.44-3.9C19.291 15.324 11.335 12 11.335 12z"
      ></path>
      <path
        fill="#fff"
        d="M19.986 5.89c.587-.291 1.102.097 1.404.703.299.607 1.688 3.343 1.969 3.912.283.568.127 1.402-.48 1.702-.607.302-1.489.703-2.061 1.675-.31.53-1.597 1.925-5.086 3.42-2.285.974-4.11 2.625-4.84 3.2-.591.465-1.783-.425-.896-1.234 1.476-1.347 2.604-2.187 3.323-2.639l-.245-.257s-2.92 2.037-4.159 3.1c-.84.723-1.958-.635-1.075-1.428 1.171-1.053 3.268-2.636 4.14-3.163l-.243-.352s-2.287 1.487-4.51 3.346c-.742.62-1.898-.772-1.017-1.576 1.14-1.036 3.362-2.895 4.729-3.666l-.131-.406s-2.145 1.204-3.997 2.996c-.663.64-1.983-.545-1.017-1.528 2.308-2.348 5.305-3.74 6.103-4.276 1.07-.72 1.634-1.17 1.07-1.422-.849-.382-2.264-1.721-2.806-2.806-.702-1.404.293-2.514 1.403-1.404.702.702 2.106 2.105 3.509 2.105 1.886 0 2.174.702 3.509.702.703-.001 1.404-.703 1.404-.703z"
      ></path>
      <path
        fill="#39372C"
        d="M16.699 10.111c-.223.001-.96-.1-1.79-.483-.715-.33-1.27-.927-1.731-1.428.026.107-.043.236-.189.387.454.528 1.149 1.13 1.77 1.43.72.35 1.543.527 1.914.512.38-.016.256-.415.026-.418zm1.444 5.72c-1.51-.702-2.708-1.289-.65-.44.852.35 2.13-1.373.878-1.944C15.849 12.296 8 8 8 8s-4.245 4.958-3.72 5.276c.526.316.745 2.566 3.777 4.545 2.865 1.872 4.65 2.677 5.32 3.012 1.405.702 2.318-1.118 1.222-1.608-1.888-.844-1.822-.895.946.255 1.024.427 1.886-1.5.803-1.987-1.615-.726-1.76-.814.826.351.957.429 2.049-1.51.97-2.013z"
      ></path>
      <path
        fill={`url(#${uid})`}
        d="M4.727 6.185a1.205 1.205 0 00-1.657.41L.857 10.262c-.328.544-.242 1.386.338 1.736.58.35 1.631.985 2.157 1.301.526.318 2.674 2.985 5.775 4.853 3.098 1.872 4.244 2.19 4.941 2.465.7.273 1.625-1.122.529-1.611-1.892-.845-3.224-1.625-3.906-2.137l.212-.39s1.891 1.414 4.658 2.566c1.024.426 1.86-1.17.778-1.658-1.616-.726-3.608-1.93-4.434-2.527l.23-.38s2.631 1.662 5.217 2.827c.954.43 1.755-1.19.675-1.694-1.506-.703-3.632-1.97-4.929-2.852l.225-.333s2.41 1.7 4.47 2.549c.852.352 1.854-1.241.6-1.814-2.52-1.15-5.376-3.163-6.126-3.763-.612-.491-.787-1.609.702-2.105 2.105-.702 2.807-2.106 2.807-2.808 0-.992-.917-1.43-1.403-.702-1.404 2.106-2.11 1.388-3.508 2.106-1.676.86-3.644 1.774-4.786 1.085-.489-.294-1.352-.79-1.352-.79z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.2"
        d="M4.727 6.185a1.205 1.205 0 00-1.657.41L.857 10.262c-.328.544-.242 1.386.338 1.736.58.35 1.631.985 2.157 1.301.526.318 2.674 2.985 5.775 4.853 3.098 1.872 4.244 2.19 4.941 2.465.7.273 1.625-1.122.529-1.611-1.892-.845-3.224-1.625-3.906-2.137l.212-.39s1.891 1.414 4.658 2.566c1.024.426 1.86-1.17.778-1.658-1.616-.726-3.608-1.93-4.434-2.527l.23-.38s2.631 1.662 5.217 2.827c.954.43 1.755-1.19.675-1.694-1.506-.703-3.632-1.97-4.929-2.852l.225-.333s2.41 1.7 4.47 2.549c.852.352 1.854-1.241.6-1.814-2.52-1.15-5.376-3.163-6.126-3.763-.612-.491-.787-1.609.702-2.105 2.105-.702 2.807-2.106 2.807-2.808 0-.992-.917-1.43-1.403-.702-1.404 2.106-2.11 1.388-3.508 2.106-1.676.86-3.644 1.774-4.786 1.085-.489-.294-1.352-.79-1.352-.79z"
      ></path>
      <path
        fill="#fff"
        d="M12.632 7.814l3.617-1.808a5.522 5.522 0 00-1.175-.115c-1.403 0-2.807-1.403-3.509-2.105-1.11-1.11-2.105 0-1.403 1.404.477.952 1.622 2.099 2.47 2.624z"
      ></path>
      <defs>
        <linearGradient
          id={uid}
          x1="9.826"
          x2="9.826"
          y1="3.423"
          y2="20.652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F92ED"></stop>
          <stop offset="1" stopColor="#00CFAA"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconConnection;