// convrted svg from https://svg2jsx.com/

import React from "react";

function IconVibration() {
  const uid = Date.now();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={`url(#${uid})`}
        d="M20.707 9.634a5.356 5.356 0 00-7.803-4.763c-.757.39-1.41.955-1.904 1.648a5.346 5.346 0 00-4.352-2.241 5.356 5.356 0 00-5.356 5.356c0 .418.054.825.145 1.216.743 4.62 5.881 9.495 9.563 10.833 3.681-1.338 8.82-6.213 9.562-10.833.092-.391.145-.797.145-1.216z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.2"
        d="M20.707 9.634a5.356 5.356 0 00-7.803-4.763c-.757.39-1.41.955-1.904 1.648a5.346 5.346 0 00-4.352-2.241 5.356 5.356 0 00-5.356 5.356c0 .418.054.825.145 1.216.743 4.62 5.881 9.495 9.563 10.833 3.681-1.338 8.82-6.213 9.562-10.833.092-.391.145-.797.145-1.216z"
      ></path>
      <path
        fill="#fff"
        d="M.916 6.748a.61.61 0 01-.546-.885c.621-1.241 2.318-3.175 4.451-3.415a.611.611 0 01.136 1.215C3.32 3.848 1.946 5.446 1.463 6.41a.613.613 0 01-.547.338zm-.28-3.31a.61.61 0 01-.505-.954C.703 1.64 1.9.706 2.99.637a.607.607 0 01.649.572.611.611 0 01-.572.649c-.587.036-1.469.637-1.925 1.311a.612.612 0 01-.507.269zm20.448 3.31a.611.611 0 01-.548-.338c-.482-.964-1.857-2.562-3.494-2.747a.61.61 0 11.137-1.215c2.132.24 3.83 2.174 4.45 3.415a.61.61 0 01-.545.885zm.28-3.31a.611.611 0 01-.507-.269c-.456-.674-1.338-1.275-1.925-1.311a.611.611 0 01.076-1.22c1.093.068 2.29 1.002 2.862 1.846a.611.611 0 01-.506.954z"
      ></path>
      <defs>
        <linearGradient
          id={uid}
          x1="11"
          x2="11"
          y1="4.278"
          y2="21.683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F92ED"></stop>
          <stop offset="1" stopColor="#00CFAA"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconVibration;