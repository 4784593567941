import React from "react";

function IconSpotlight() {
  const uid0 = Date.now();
  const uid1 = Date.now();
  const uid2 = Date.now();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill={`url(#paint0_linear_295_175_${uid0})`}
        d="M22 19.42S22 22 19.412 22H2.588C0 22 0 19.42 0 19.42V7.16c0-2.581 2.588-2.581 2.588-2.581h16.824S22 4.579 22 7.159v12.26z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.2"
        d="M22 19.42S22 22 19.412 22H2.588C0 22 0 19.42 0 19.42V7.16c0-2.581 2.588-2.581 2.588-2.581h16.824S22 4.579 22 7.159v12.26z"
      ></path>
      <path
        fill={`url(#paint1_linear_295_175_${uid1})`}
        d="M13.137 6.12a.643.643 0 010 .913.648.648 0 01-.915 0L6.276 1.102a.643.643 0 010-.913.648.648 0 01.915 0l5.947 5.931z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.2"
        d="M13.137 6.12a.643.643 0 010 .913.648.648 0 01-.915 0L6.276 1.102a.643.643 0 010-.913.648.648 0 01.915 0l5.947 5.931z"
      ></path>
      <path
        fill={`url(#paint1_linear_295_175_${uid2})`}
        d="M8.863 6.12a.643.643 0 000 .913.648.648 0 00.915 0l5.947-5.931a.643.643 0 000-.913.648.648 0 00-.915 0L8.863 6.12z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.2"
        d="M8.863 6.12a.643.643 0 000 .913.648.648 0 00.915 0l5.947-5.931a.643.643 0 000-.913.648.648 0 00-.915 0L8.863 6.12z"
      ></path>
      <path
        fill="#fff"
        d="M11 6.514c5.177 0 6.47.646 7.118 1.29.647.646 1.294 1.937 1.294 5.163s-.647 4.516-1.294 5.162c-.647.645-1.941 1.29-7.118 1.29-5.176 0-6.47-.645-7.117-1.29-.648-.646-1.295-1.936-1.295-5.162s.647-4.517 1.294-5.162C4.53 7.16 5.824 6.515 11 6.515z"
      ></path>
      <path
        fill="#2A2E33"
        d="M19.735 20.71a.97.97 0 00.97-.968.97.97 0 00-.97-.968.97.97 0 00-.97.968.97.97 0 00.97.967zM2.265 20.71a.97.97 0 00.97-.968.97.97 0 00-.97-.968.97.97 0 00-.971.968.97.97 0 00.97.967z"
      ></path>
      <defs>
        <linearGradient
          id={`paint0_linear_295_175_${uid0}`}
          x1="11"
          x2="11"
          y1="4.579"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F92ED"></stop>
          <stop offset="1" stopColor="#00CFAA"></stop>
        </linearGradient>
        <linearGradient
          id={`paint1_linear_295_175_${uid1}`}
          x1="9.706"
          x2="9.706"
          y1="0"
          y2="7.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F92ED"></stop>
          <stop offset="1" stopColor="#00CFAA"></stop>
        </linearGradient>
        <linearGradient
          id={`paint2_linear_295_175_${uid2}`}
          x1="12.294"
          x2="12.294"
          y1="0"
          y2="7.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F92ED"></stop>
          <stop offset="1" stopColor="#00CFAA"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconSpotlight;