import React, { useEffect, useMemo } from 'react';
// Import from React router DOM
import { NavLink, Switch, Route, useRouteMatch, Link } from 'react-router-dom';
// Import components
import Profile from '../../components/User/Profile/Profile';
import Team from '../../components/User/Team/Team';
import Policies from '../../components/User/Policies/Policies';
import TermsAndConditions from '../../components/User/TermsAndConditions/TermsAndConditions';
import PlanSubscriptionInfoModal from '../../components/User/PlanSubscriptionInfoModal/PlanSubscriptionInfoModal';
// Import icon components
import UserIconSingle from '../../assets/images/svg/UserIconSingle';
import CheckIconRoundOutlined from '../../assets/images/svg/CheckIconRoundOutlined';
import SearchShieldIconOutlined from '../../assets/images/svg/SearchShieldIconOutlined';
import InfoIconOutlined from '../../assets/images/svg/InfoIconOutlined';
import TeamIconOutlined from '../../assets/images/svg/TeamIconOutlined';
import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { date } from 'faker';

export default function User(props) {
    // Local states
    const [planSubscriptionModal, setPlanSubscriptionModal] = React.useState({
        visible: false,
    });
    const [activeUserProfileTab, setActiveUserProfileTab] =
        React.useState(null);
    // Set PlansSubscriptionModalVisible
    const setPlanSubscriptionModalVisible = bool => {
        setPlanSubscriptionModal(prevValues => ({
            ...prevValues,
            visible: bool,
        }));
    };
    // Extract url
    const { url } = useRouteMatch();

    let previousPagePath = props.location.state;
    useEffect(() => {
        {/* props.location.state contains the previous path name. this prop value is assigned by Link that has to={'user/profile'} attribute */}
        window.localStorage.setItem("previousPagePath", props.location.state)
    }, [])

    return (
        <>
            <div className="container-lg user-settings-container">
                <div className="user">
                    <div className="user__tabs">
                        <div className="user__tabs-heading">
                            <div className="user__tabs-heading_wrapper">
                                <h1 className="page-heading">User Settings</h1>

                                <a className="close-settings-button" href={previousPagePath || window.localStorage.getItem("previousPagePath")} >{<CloseCircleOutlined />}</a>
                            </div>
                            <div className="user__tabs-wrapper">
                                <div className="tabs-general">
                                    <NavLink
                                        exact
                                        to={`${url}/profile`}
                                        className="tabs-general__tab tabs-general__tab--icon-no-fill"
                                    >
                                        <UserIconSingle />
                                        My profile
                                    </NavLink>
                                    {/* <NavLink
                                        exact
                                        to={`${url}/team`}
                                        className="tabs-general__tab tabs-general__tab--icon-no-fill"
                                    >
                                        <TeamIconOutlined />
                                        My team
                                    </NavLink> */}
                                    <NavLink
                                        exact
                                        to={`${url}/policies`}
                                        className="tabs-general__tab"
                                    >
                                        <SearchShieldIconOutlined />
                                        Privacy and cookie policy
                                    </NavLink>
                                    <NavLink
                                        exact
                                        to={`${url}/terms-and-conditions`}
                                        className="tabs-general__tab tabs-general__tab--icon-no-fill"
                                    >
                                        <CheckIconRoundOutlined />
                                        Terms and conditions
                                    </NavLink>
                                </div>
                                {activeUserProfileTab === 'plans' && (
                                    <div
                                        className="subscription-info-trigger"
                                        onClick={() =>
                                            setPlanSubscriptionModalVisible(
                                                true
                                            )
                                        }
                                    >
                                        <InfoIconOutlined />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="user__tabs-body">
                            <Switch>
                                <Route
                                    exact
                                    path="/user/profile"
                                    component={Profile}
                                />
                                {/* <Route
                                    exact
                                    path="/user/team"
                                    component={Team}
                                /> */}
                                <Route
                                    exact
                                    path="/user/terms-and-conditions"
                                    component={TermsAndConditions}
                                />
                                <Route
                                    exact
                                    path="/user/policies"
                                    component={Policies}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
            {/* PlanSubscriptionInfoModal */}
            <PlanSubscriptionInfoModal
                planSubscriptionModal={planSubscriptionModal}
                setPlanSubscriptionModalVisible={
                    setPlanSubscriptionModalVisible
                }
            />
        </>
    );
}
