import React from 'react';

export default function AddIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.7714 12.7174L14.6203 19.5181C14.4293 19.6998 14.1136 19.5644 14.1136 19.3007V15.5C14.1136 15.5 10.959 15.6667 8.33018 16.5C6.238 17.1632 3.47981 18.9877 2.44089 19.7049C2.25158 19.8356 2.00022 19.7174 2 19.4874C1.99875 18.1733 2.33018 14.6049 5.70137 12C9.90749 8.75 14.1136 9 14.1136 9V5.6993C14.1136 5.43565 14.4293 5.30022 14.6203 5.48191L18.0568 8.75L21.7714 12.2826C21.8958 12.4009 21.8958 12.5991 21.7714 12.7174Z"
                stroke="#74788D"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
