import React from 'react';

export default function MagicWandIconOutlined() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.199 9.94491C14.7653 9.53403 14.4863 8.98632 14.409 8.39391L14.006 5.31091L11.276 6.79691C10.7511 7.08293 10.1436 7.17934 9.55597 7.06991L6.49997 6.49991L7.06997 9.55591C7.1794 10.1436 7.08299 10.751 6.79697 11.2759L5.31097 14.0059L8.39397 14.4089C8.98603 14.4864 9.53335 14.7654 9.94397 15.1989L12.082 17.4559L13.418 14.6489C13.6744 14.1095 14.1087 13.6748 14.648 13.4179L17.456 12.0819L15.199 9.94491ZM15.224 15.5079L13.011 20.1579C12.9691 20.2458 12.9065 20.3222 12.8285 20.3805C12.7505 20.4388 12.6594 20.4773 12.5633 20.4925C12.4671 20.5078 12.3686 20.4994 12.2764 20.4681C12.1842 20.4368 12.101 20.3836 12.034 20.3129L8.49197 16.5739C8.39735 16.4741 8.27131 16.4099 8.13497 16.3919L3.02797 15.7239C2.93149 15.7112 2.83954 15.6752 2.76006 15.619C2.68058 15.5629 2.61596 15.4882 2.57177 15.4015C2.52758 15.3148 2.50514 15.2186 2.5064 15.1213C2.50765 15.024 2.53256 14.9285 2.57897 14.8429L5.04097 10.3189C5.10642 10.1979 5.12831 10.0581 5.10297 9.92291L4.15997 4.85991C4.14207 4.76408 4.14778 4.66532 4.17662 4.5722C4.20546 4.47907 4.25656 4.39437 4.3255 4.32544C4.39444 4.2565 4.47913 4.2054 4.57226 4.17656C4.66539 4.14771 4.76414 4.142 4.85997 4.15991L9.92297 5.10291C10.0582 5.12825 10.198 5.10636 10.319 5.04091L14.843 2.57891C14.9286 2.53248 15.0242 2.50759 15.1216 2.50639C15.219 2.50519 15.3152 2.52772 15.4019 2.57202C15.4887 2.61632 15.5633 2.68106 15.6194 2.76067C15.6755 2.84027 15.7114 2.93233 15.724 3.02891L16.392 8.13491C16.4099 8.27125 16.4742 8.39728 16.574 8.49191L20.313 12.0339C20.3836 12.1009 20.4369 12.1841 20.4682 12.2764C20.4995 12.3686 20.5079 12.467 20.4926 12.5632C20.4774 12.6594 20.4389 12.7504 20.3806 12.8284C20.3223 12.9064 20.2459 12.969 20.158 13.0109L15.508 15.2239C15.3835 15.2831 15.2832 15.3834 15.224 15.5079ZM16.021 17.4349L17.435 16.0209L21.678 20.2629L20.263 21.6779L16.021 17.4349Z"
				fill="white"
			/>
		</svg>
	);
}
