import React from 'react';

export default function AddIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.0001 20.4004C17.6001 20.4004 5.67077 20.4004 5.67077 20.4004C3.6001 20.4004 3.6001 20.4004 3.6001 19.2004C3.6001 18.0004 3.6001 18.0004 5.67077 18.0004C5.67077 18.0004 17.6001 18.0004 19.0001 18.0004C20.4001 18.0004 20.4001 18.6764 20.4001 19.2004C20.4001 19.7243 20.4001 20.4004 19.0001 20.4004Z"
                fill="#74788D"
            />
            <path
                d="M19.0001 6C17.6001 6 5.67077 6 5.67077 6C3.6001 6 3.6001 6 3.6001 4.8C3.6001 3.6 3.6001 3.6 5.67077 3.6C5.67077 3.6 17.6001 3.6 19.0001 3.6C20.4001 3.6 20.4001 4.27606 20.4001 4.8C20.4001 5.32394 20.4001 6 19.0001 6Z"
                fill="#74788D"
            />
            <path
                d="M10.2001 15.5996C9.6001 15.5996 4.48753 15.5996 4.48753 15.5996C3.6001 15.5996 3.6001 15.5996 3.6001 11.9996C3.6001 8.39961 3.6001 8.39961 4.48753 8.39961C4.48753 8.39961 9.6001 8.39961 10.2001 8.39961C10.8001 8.39961 10.8001 10.4278 10.8001 11.9996C10.8001 13.5714 10.8001 15.5996 10.2001 15.5996Z"
                fill="#74788D"
            />
            <path
                d="M18.7002 10.7998C18.2002 10.7998 13.9397 10.7998 13.9397 10.7998C13.2002 10.7998 13.2002 10.7998 13.2002 9.5998C13.2002 8.3998 13.2002 8.3998 13.9397 8.3998C13.9397 8.3998 18.2002 8.3998 18.7002 8.3998C19.2002 8.3998 19.2002 9.07586 19.2002 9.5998C19.2002 10.1237 19.2002 10.7998 18.7002 10.7998Z"
                fill="#74788D"
            />
            <path
                d="M18.7002 15.5996C18.2002 15.5996 13.9397 15.5996 13.9397 15.5996C13.2002 15.5996 13.2002 15.5996 13.2002 14.3996C13.2002 13.1996 13.2002 13.1996 13.9397 13.1996C13.9397 13.1996 18.2002 13.1996 18.7002 13.1996C19.2002 13.1996 19.2002 13.8757 19.2002 14.3996C19.2002 14.9236 19.2002 15.5996 18.7002 15.5996Z"
                fill="#74788D"
            />
        </svg>
    );
}
