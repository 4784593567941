import React from 'react';
// Import assets
import UserIconSingle from '../../../assets/images/svg/UserIconSingle';

export default function PhotoPreview(props) {
    const nameOfClass = ['photo-preview'];
    props.className && nameOfClass.push(props.className);
    return (
        <div
            className={nameOfClass.join(' ')}
            style={{
                backgroundImage: `url(${props.photoUrl})`,
                width: `${props.size}px`,
                height: `${props.size}px`,
                borderRadius: props.type === 'square' && '4px',
                border: props.isPerson && '1px solid #E8E8E8',
            }}
        >
            {props.isPerson && !props.photoUrl && <UserIconSingle />}
        </div>
    );
}
