import React from 'react';
// Import from dependencies
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Import icons
import NavIconText from '../../../../assets/images/svg/NavIconText';
import NavIconTv from '../../../../assets/images/svg/NavIconTv';
import NavIconAgora from '../../../../assets/images/svg/NavIconAgora';

export default function MainNavLinks(props) {
    // i8n
    const { t } = useTranslation();
    return (
        <div className="main-nav__links">
            {/* <NavLink to="/agora" className="main-nav__link-each">
                <div className="NavLinkIconBox">
                    <NavIconAgora />
                </div>
                <span>{t('mainNav.agora')}</span>
            </NavLink> */}
            <NavLink to="/stories/all" className="main-nav__link-each">
                <div className="NavLinkIconBox">
                    <NavIconText />
                </div>
                <span>{t('mainNav.theWall')}</span>
            </NavLink>
            <NavLink exact to="/stream" className="main-nav__link-each">
                <div className="NavLinkIconBox">
                    <NavIconTv />
                </div>
                <span>{t('mainNav.channels')}</span>
            </NavLink>
        </div>
    );
}
