import { Drawer } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Logo from '../../../../assets/images/svg/Logo';
import NavIconAgora from '../../../../assets/images/svg/NavIconAgora';
import NavIconText from '../../../../assets/images/svg/NavIconText';
import NavIconTv from '../../../../assets/images/svg/NavIconTv';

const MainNavDrawer = ({ onClose, visible }) => {
    const { t } = useTranslation();
    return (
        <>
            <Drawer
                title={<Logo />}
                className="mainnav-drawer-body"
                placement="left"
                onClose={onClose}
                visible={visible}
            >
                {/* <NavLink to="/agora" className="main-nav__link-each">
                    <div className="NavLinkIconBox">
                        <NavIconAgora />
                    </div>
                    <span>{t('mainNav.agora')}</span>
                </NavLink> */}
                <NavLink to="/stories/all" className="main-nav__link-each">
                    <div className="NavLinkIconBox">
                        <NavIconText />
                    </div>
                    <span>{t('mainNav.theWall')}</span>
                </NavLink>
                <NavLink exact to="/stream" className="main-nav__link-each">
                    <div className="NavLinkIconBox">
                        <NavIconTv />
                    </div>
                    <span>{t('mainNav.channels')}</span>
                </NavLink>
            </Drawer>
        </>
    );
};

export default MainNavDrawer;
