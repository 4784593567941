// Import
import { GET_RANDOM_GLOBAL_CITY } from '../exports/functions';

// eslint-disable-next-line import/prefer-default-export
export const PROFESSIONS = [
    'No title',
    'Tenant',
    'Landlord',
    'Real Estate Entrepreneur',
    'Real Estate Investor',
    'Real Estate Agent',
    'Property Manager',
    'Real Estate Appraiser',
    'Real Estate Expert',
    'Property Administrator',
    'Trustee',
    'Concierge',
    'Loan Officer',
    'Tax Advisor',
    'Insurance Broker',
    'Lawyer',
    'Notary',
    'Auctioneer',
    'Architect',
    'Interior Designer',
    'Builder',
    'Roofer',
    'Plumber',
    'Heating Engineer',
    'Electrician',
    'Domoticist',
    'Plasterer',
    'Tiler',
    'Carpenter',
    'House Painter',
    'Landscaper',
    'Digger',
    'Site Coordinator',
];

export const PROPERTY_STATUS = {
    'to-sell': 'For Sale',
    sold: 'Sold',
    'to-lease': 'To Rent',
    leased: 'Rented',
    renovating: 'Renovating',
    'sale-pending': 'Sale Pending',
};

export const PROPERTY_TYPES = [
    { key: 'house', value: 'House' },
    { key: 'apartment', value: 'Apartment' },
    { key: 'duplex', value: 'Duplex' },
    { key: 'triplex', value: 'Triplex' },
    { key: 'penthouse', value: 'Penthouse' },
    { key: 'loft', value: 'Loft' },
    { key: 'student-housing', value: 'Student Housing' },
    { key: 'land', value: 'Land' },
];

export const PROPERTY_STATUS_OPTIONS = Object.keys(PROPERTY_STATUS).map(i => ({
    key: i,
    value: PROPERTY_STATUS[i],
}));

export const BEDROOMS_LIMIT = 20;

export const BATHROOMS_LIMIT = 4;

export const RENT_PERIOD_LIMIT = 9;

export const OFFICE_FILE_TYPES = ['word', 'presentation', 'spreadsheet'];

export const PROPERTY_IMAGE_SUPPORTED_EXT = ['.jpg', '.jpeg', '.png'];

export const PROPERTY_DOC_FILES_SUPPORTED_EXT = [
    'pdf',
    'png',
    'jpeg',
    'jpg',
    'mp3',
    'mp4',
    'mov',
    'ppt',
    'pptx',
    'pptm',
    'ppsx',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'xlsm',
    'xltx',
    'xltm',
    'odt',
    'ods',
    'odp',
    'odg',
];

export const PROPERTY_DOC_FILES_SUPPORTED_EXT_WITH_DOT =
    PROPERTY_DOC_FILES_SUPPORTED_EXT.map(ext => `.${ext}`);

export const CURRENCIES = [
    {
        symbol: '$',
        name: 'US Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'USD',
        name_plural: 'US dollars',
    },
    {
        symbol: 'CA$',
        name: 'Canadian Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'CAD',
        name_plural: 'Canadian dollars',
    },
    {
        symbol: '€',
        name: 'Euro',
        symbol_native: '€',
        decimal_digits: 2,
        rounding: 0,
        code: 'EUR',
        name_plural: 'euros',
    },
    {
        symbol: 'AED',
        name: 'United Arab Emirates Dirham',
        symbol_native: 'د.إ.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'AED',
        name_plural: 'UAE dirhams',
    },
    {
        symbol: 'Af',
        name: 'Afghan Afghani',
        symbol_native: '؋',
        decimal_digits: 0,
        rounding: 0,
        code: 'AFN',
        name_plural: 'Afghan Afghanis',
    },
    {
        symbol: 'ALL',
        name: 'Albanian Lek',
        symbol_native: 'Lek',
        decimal_digits: 0,
        rounding: 0,
        code: 'ALL',
        name_plural: 'Albanian lekë',
    },
    {
        symbol: 'AMD',
        name: 'Armenian Dram',
        symbol_native: 'դր.',
        decimal_digits: 0,
        rounding: 0,
        code: 'AMD',
        name_plural: 'Armenian drams',
    },
    {
        symbol: 'AR$',
        name: 'Argentine Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'ARS',
        name_plural: 'Argentine pesos',
    },
    {
        symbol: 'AU$',
        name: 'Australian Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'AUD',
        name_plural: 'Australian dollars',
    },
    {
        symbol: 'man.',
        name: 'Azerbaijani Manat',
        symbol_native: 'ман.',
        decimal_digits: 2,
        rounding: 0,
        code: 'AZN',
        name_plural: 'Azerbaijani manats',
    },
    {
        symbol: 'KM',
        name: 'Bosnia-Herzegovina Convertible Mark',
        symbol_native: 'KM',
        decimal_digits: 2,
        rounding: 0,
        code: 'BAM',
        name_plural: 'Bosnia-Herzegovina convertible marks',
    },
    {
        symbol: 'Tk',
        name: 'Bangladeshi Taka',
        symbol_native: '৳',
        decimal_digits: 2,
        rounding: 0,
        code: 'BDT',
        name_plural: 'Bangladeshi takas',
    },
    {
        symbol: 'BGN',
        name: 'Bulgarian Lev',
        symbol_native: 'лв.',
        decimal_digits: 2,
        rounding: 0,
        code: 'BGN',
        name_plural: 'Bulgarian leva',
    },
    {
        symbol: 'BD',
        name: 'Bahraini Dinar',
        symbol_native: 'د.ب.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'BHD',
        name_plural: 'Bahraini dinars',
    },
    {
        symbol: 'FBu',
        name: 'Burundian Franc',
        symbol_native: 'FBu',
        decimal_digits: 0,
        rounding: 0,
        code: 'BIF',
        name_plural: 'Burundian francs',
    },
    {
        symbol: 'BN$',
        name: 'Brunei Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BND',
        name_plural: 'Brunei dollars',
    },
    {
        symbol: 'Bs',
        name: 'Bolivian Boliviano',
        symbol_native: 'Bs',
        decimal_digits: 2,
        rounding: 0,
        code: 'BOB',
        name_plural: 'Bolivian bolivianos',
    },
    {
        symbol: 'R$',
        name: 'Brazilian Real',
        symbol_native: 'R$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BRL',
        name_plural: 'Brazilian reals',
    },
    {
        symbol: 'BWP',
        name: 'Botswanan Pula',
        symbol_native: 'P',
        decimal_digits: 2,
        rounding: 0,
        code: 'BWP',
        name_plural: 'Botswanan pulas',
    },
    {
        symbol: 'Br',
        name: 'Belarusian Ruble',
        symbol_native: 'руб.',
        decimal_digits: 2,
        rounding: 0,
        code: 'BYN',
        name_plural: 'Belarusian rubles',
    },
    {
        symbol: 'BZ$',
        name: 'Belize Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BZD',
        name_plural: 'Belize dollars',
    },
    {
        symbol: 'CDF',
        name: 'Congolese Franc',
        symbol_native: 'FrCD',
        decimal_digits: 2,
        rounding: 0,
        code: 'CDF',
        name_plural: 'Congolese francs',
    },
    {
        symbol: 'CHF',
        name: 'Swiss Franc',
        symbol_native: 'CHF',
        decimal_digits: 2,
        rounding: 0.05,
        code: 'CHF',
        name_plural: 'Swiss francs',
    },
    {
        symbol: 'CL$',
        name: 'Chilean Peso',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        code: 'CLP',
        name_plural: 'Chilean pesos',
    },
    {
        symbol: 'CN¥',
        name: 'Chinese Yuan',
        symbol_native: 'CN¥',
        decimal_digits: 2,
        rounding: 0,
        code: 'CNY',
        name_plural: 'Chinese yuan',
    },
    {
        symbol: 'CO$',
        name: 'Colombian Peso',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        code: 'COP',
        name_plural: 'Colombian pesos',
    },
    {
        symbol: '₡',
        name: 'Costa Rican Colón',
        symbol_native: '₡',
        decimal_digits: 0,
        rounding: 0,
        code: 'CRC',
        name_plural: 'Costa Rican colóns',
    },
    {
        symbol: 'CV$',
        name: 'Cape Verdean Escudo',
        symbol_native: 'CV$',
        decimal_digits: 2,
        rounding: 0,
        code: 'CVE',
        name_plural: 'Cape Verdean escudos',
    },
    {
        symbol: 'Kč',
        name: 'Czech Republic Koruna',
        symbol_native: 'Kč',
        decimal_digits: 2,
        rounding: 0,
        code: 'CZK',
        name_plural: 'Czech Republic korunas',
    },
    {
        symbol: 'Fdj',
        name: 'Djiboutian Franc',
        symbol_native: 'Fdj',
        decimal_digits: 0,
        rounding: 0,
        code: 'DJF',
        name_plural: 'Djiboutian francs',
    },
    {
        symbol: 'Dkr',
        name: 'Danish Krone',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'DKK',
        name_plural: 'Danish kroner',
    },
    {
        symbol: 'RD$',
        name: 'Dominican Peso',
        symbol_native: 'RD$',
        decimal_digits: 2,
        rounding: 0,
        code: 'DOP',
        name_plural: 'Dominican pesos',
    },
    {
        symbol: 'DA',
        name: 'Algerian Dinar',
        symbol_native: 'د.ج.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'DZD',
        name_plural: 'Algerian dinars',
    },
    {
        symbol: 'Ekr',
        name: 'Estonian Kroon',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'EEK',
        name_plural: 'Estonian kroons',
    },
    {
        symbol: 'EGP',
        name: 'Egyptian Pound',
        symbol_native: 'ج.م.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'EGP',
        name_plural: 'Egyptian pounds',
    },
    {
        symbol: 'Nfk',
        name: 'Eritrean Nakfa',
        symbol_native: 'Nfk',
        decimal_digits: 2,
        rounding: 0,
        code: 'ERN',
        name_plural: 'Eritrean nakfas',
    },
    {
        symbol: 'Br',
        name: 'Ethiopian Birr',
        symbol_native: 'Br',
        decimal_digits: 2,
        rounding: 0,
        code: 'ETB',
        name_plural: 'Ethiopian birrs',
    },
    {
        symbol: '£',
        name: 'British Pound Sterling',
        symbol_native: '£',
        decimal_digits: 2,
        rounding: 0,
        code: 'GBP',
        name_plural: 'British pounds sterling',
    },
    {
        symbol: 'GEL',
        name: 'Georgian Lari',
        symbol_native: 'GEL',
        decimal_digits: 2,
        rounding: 0,
        code: 'GEL',
        name_plural: 'Georgian laris',
    },
    {
        symbol: 'GH₵',
        name: 'Ghanaian Cedi',
        symbol_native: 'GH₵',
        decimal_digits: 2,
        rounding: 0,
        code: 'GHS',
        name_plural: 'Ghanaian cedis',
    },
    {
        symbol: 'FG',
        name: 'Guinean Franc',
        symbol_native: 'FG',
        decimal_digits: 0,
        rounding: 0,
        code: 'GNF',
        name_plural: 'Guinean francs',
    },
    {
        symbol: 'GTQ',
        name: 'Guatemalan Quetzal',
        symbol_native: 'Q',
        decimal_digits: 2,
        rounding: 0,
        code: 'GTQ',
        name_plural: 'Guatemalan quetzals',
    },
    {
        symbol: 'HK$',
        name: 'Hong Kong Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'HKD',
        name_plural: 'Hong Kong dollars',
    },
    {
        symbol: 'HNL',
        name: 'Honduran Lempira',
        symbol_native: 'L',
        decimal_digits: 2,
        rounding: 0,
        code: 'HNL',
        name_plural: 'Honduran lempiras',
    },
    {
        symbol: 'kn',
        name: 'Croatian Kuna',
        symbol_native: 'kn',
        decimal_digits: 2,
        rounding: 0,
        code: 'HRK',
        name_plural: 'Croatian kunas',
    },
    {
        symbol: 'Ft',
        name: 'Hungarian Forint',
        symbol_native: 'Ft',
        decimal_digits: 0,
        rounding: 0,
        code: 'HUF',
        name_plural: 'Hungarian forints',
    },
    {
        symbol: 'Rp',
        name: 'Indonesian Rupiah',
        symbol_native: 'Rp',
        decimal_digits: 0,
        rounding: 0,
        code: 'IDR',
        name_plural: 'Indonesian rupiahs',
    },
    {
        symbol: '₪',
        name: 'Israeli New Sheqel',
        symbol_native: '₪',
        decimal_digits: 2,
        rounding: 0,
        code: 'ILS',
        name_plural: 'Israeli new sheqels',
    },
    {
        symbol: 'Rs',
        name: 'Indian Rupee',
        symbol_native: 'টকা',
        decimal_digits: 2,
        rounding: 0,
        code: 'INR',
        name_plural: 'Indian rupees',
    },
    {
        symbol: 'IQD',
        name: 'Iraqi Dinar',
        symbol_native: 'د.ع.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'IQD',
        name_plural: 'Iraqi dinars',
    },
    {
        symbol: 'IRR',
        name: 'Iranian Rial',
        symbol_native: '﷼',
        decimal_digits: 0,
        rounding: 0,
        code: 'IRR',
        name_plural: 'Iranian rials',
    },
    {
        symbol: 'Ikr',
        name: 'Icelandic Króna',
        symbol_native: 'kr',
        decimal_digits: 0,
        rounding: 0,
        code: 'ISK',
        name_plural: 'Icelandic krónur',
    },
    {
        symbol: 'J$',
        name: 'Jamaican Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'JMD',
        name_plural: 'Jamaican dollars',
    },
    {
        symbol: 'JD',
        name: 'Jordanian Dinar',
        symbol_native: 'د.أ.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'JOD',
        name_plural: 'Jordanian dinars',
    },
    {
        symbol: '¥',
        name: 'Japanese Yen',
        symbol_native: '￥',
        decimal_digits: 0,
        rounding: 0,
        code: 'JPY',
        name_plural: 'Japanese yen',
    },
    {
        symbol: 'Ksh',
        name: 'Kenyan Shilling',
        symbol_native: 'Ksh',
        decimal_digits: 2,
        rounding: 0,
        code: 'KES',
        name_plural: 'Kenyan shillings',
    },
    {
        symbol: 'KHR',
        name: 'Cambodian Riel',
        symbol_native: '៛',
        decimal_digits: 2,
        rounding: 0,
        code: 'KHR',
        name_plural: 'Cambodian riels',
    },
    {
        symbol: 'CF',
        name: 'Comorian Franc',
        symbol_native: 'FC',
        decimal_digits: 0,
        rounding: 0,
        code: 'KMF',
        name_plural: 'Comorian francs',
    },
    {
        symbol: '₩',
        name: 'South Korean Won',
        symbol_native: '₩',
        decimal_digits: 0,
        rounding: 0,
        code: 'KRW',
        name_plural: 'South Korean won',
    },
    {
        symbol: 'KD',
        name: 'Kuwaiti Dinar',
        symbol_native: 'د.ك.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'KWD',
        name_plural: 'Kuwaiti dinars',
    },
    {
        symbol: 'KZT',
        name: 'Kazakhstani Tenge',
        symbol_native: 'тңг.',
        decimal_digits: 2,
        rounding: 0,
        code: 'KZT',
        name_plural: 'Kazakhstani tenges',
    },
    {
        symbol: 'LB£',
        name: 'Lebanese Pound',
        symbol_native: 'ل.ل.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'LBP',
        name_plural: 'Lebanese pounds',
    },
    {
        symbol: 'SLRs',
        name: 'Sri Lankan Rupee',
        symbol_native: 'SL Re',
        decimal_digits: 2,
        rounding: 0,
        code: 'LKR',
        name_plural: 'Sri Lankan rupees',
    },
    {
        symbol: 'Lt',
        name: 'Lithuanian Litas',
        symbol_native: 'Lt',
        decimal_digits: 2,
        rounding: 0,
        code: 'LTL',
        name_plural: 'Lithuanian litai',
    },
    {
        symbol: 'Ls',
        name: 'Latvian Lats',
        symbol_native: 'Ls',
        decimal_digits: 2,
        rounding: 0,
        code: 'LVL',
        name_plural: 'Latvian lati',
    },
    {
        symbol: 'LD',
        name: 'Libyan Dinar',
        symbol_native: 'د.ل.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'LYD',
        name_plural: 'Libyan dinars',
    },
    {
        symbol: 'MAD',
        name: 'Moroccan Dirham',
        symbol_native: 'د.م.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'MAD',
        name_plural: 'Moroccan dirhams',
    },
    {
        symbol: 'MDL',
        name: 'Moldovan Leu',
        symbol_native: 'MDL',
        decimal_digits: 2,
        rounding: 0,
        code: 'MDL',
        name_plural: 'Moldovan lei',
    },
    {
        symbol: 'MGA',
        name: 'Malagasy Ariary',
        symbol_native: 'MGA',
        decimal_digits: 0,
        rounding: 0,
        code: 'MGA',
        name_plural: 'Malagasy Ariaries',
    },
    {
        symbol: 'MKD',
        name: 'Macedonian Denar',
        symbol_native: 'MKD',
        decimal_digits: 2,
        rounding: 0,
        code: 'MKD',
        name_plural: 'Macedonian denari',
    },
    {
        symbol: 'MMK',
        name: 'Myanma Kyat',
        symbol_native: 'K',
        decimal_digits: 0,
        rounding: 0,
        code: 'MMK',
        name_plural: 'Myanma kyats',
    },
    {
        symbol: 'MOP$',
        name: 'Macanese Pataca',
        symbol_native: 'MOP$',
        decimal_digits: 2,
        rounding: 0,
        code: 'MOP',
        name_plural: 'Macanese patacas',
    },
    {
        symbol: 'MURs',
        name: 'Mauritian Rupee',
        symbol_native: 'MURs',
        decimal_digits: 0,
        rounding: 0,
        code: 'MUR',
        name_plural: 'Mauritian rupees',
    },
    {
        symbol: 'MX$',
        name: 'Mexican Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'MXN',
        name_plural: 'Mexican pesos',
    },
    {
        symbol: 'RM',
        name: 'Malaysian Ringgit',
        symbol_native: 'RM',
        decimal_digits: 2,
        rounding: 0,
        code: 'MYR',
        name_plural: 'Malaysian ringgits',
    },
    {
        symbol: 'MTn',
        name: 'Mozambican Metical',
        symbol_native: 'MTn',
        decimal_digits: 2,
        rounding: 0,
        code: 'MZN',
        name_plural: 'Mozambican meticals',
    },
    {
        symbol: 'N$',
        name: 'Namibian Dollar',
        symbol_native: 'N$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NAD',
        name_plural: 'Namibian dollars',
    },
    {
        symbol: '₦',
        name: 'Nigerian Naira',
        symbol_native: '₦',
        decimal_digits: 2,
        rounding: 0,
        code: 'NGN',
        name_plural: 'Nigerian nairas',
    },
    {
        symbol: 'C$',
        name: 'Nicaraguan Córdoba',
        symbol_native: 'C$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NIO',
        name_plural: 'Nicaraguan córdobas',
    },
    {
        symbol: 'Nkr',
        name: 'Norwegian Krone',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'NOK',
        name_plural: 'Norwegian kroner',
    },
    {
        symbol: 'NPRs',
        name: 'Nepalese Rupee',
        symbol_native: 'नेरू',
        decimal_digits: 2,
        rounding: 0,
        code: 'NPR',
        name_plural: 'Nepalese rupees',
    },
    {
        symbol: 'NZ$',
        name: 'New Zealand Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NZD',
        name_plural: 'New Zealand dollars',
    },
    {
        symbol: 'OMR',
        name: 'Omani Rial',
        symbol_native: 'ر.ع.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'OMR',
        name_plural: 'Omani rials',
    },
    {
        symbol: 'B/.',
        name: 'Panamanian Balboa',
        symbol_native: 'B/.',
        decimal_digits: 2,
        rounding: 0,
        code: 'PAB',
        name_plural: 'Panamanian balboas',
    },
    {
        symbol: 'S/.',
        name: 'Peruvian Nuevo Sol',
        symbol_native: 'S/.',
        decimal_digits: 2,
        rounding: 0,
        code: 'PEN',
        name_plural: 'Peruvian nuevos soles',
    },
    {
        symbol: '₱',
        name: 'Philippine Peso',
        symbol_native: '₱',
        decimal_digits: 2,
        rounding: 0,
        code: 'PHP',
        name_plural: 'Philippine pesos',
    },
    {
        symbol: 'PKRs',
        name: 'Pakistani Rupee',
        symbol_native: '₨',
        decimal_digits: 0,
        rounding: 0,
        code: 'PKR',
        name_plural: 'Pakistani rupees',
    },
    {
        symbol: 'zł',
        name: 'Polish Zloty',
        symbol_native: 'zł',
        decimal_digits: 2,
        rounding: 0,
        code: 'PLN',
        name_plural: 'Polish zlotys',
    },
    {
        symbol: '₲',
        name: 'Paraguayan Guarani',
        symbol_native: '₲',
        decimal_digits: 0,
        rounding: 0,
        code: 'PYG',
        name_plural: 'Paraguayan guaranis',
    },
    {
        symbol: 'QR',
        name: 'Qatari Rial',
        symbol_native: 'ر.ق.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'QAR',
        name_plural: 'Qatari rials',
    },
    {
        symbol: 'RON',
        name: 'Romanian Leu',
        symbol_native: 'RON',
        decimal_digits: 2,
        rounding: 0,
        code: 'RON',
        name_plural: 'Romanian lei',
    },
    {
        symbol: 'din.',
        name: 'Serbian Dinar',
        symbol_native: 'дин.',
        decimal_digits: 0,
        rounding: 0,
        code: 'RSD',
        name_plural: 'Serbian dinars',
    },
    {
        symbol: 'RUB',
        name: 'Russian Ruble',
        symbol_native: '₽.',
        decimal_digits: 2,
        rounding: 0,
        code: 'RUB',
        name_plural: 'Russian rubles',
    },
    {
        symbol: 'RWF',
        name: 'Rwandan Franc',
        symbol_native: 'FR',
        decimal_digits: 0,
        rounding: 0,
        code: 'RWF',
        name_plural: 'Rwandan francs',
    },
    {
        symbol: 'SR',
        name: 'Saudi Riyal',
        symbol_native: 'ر.س.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'SAR',
        name_plural: 'Saudi riyals',
    },
    {
        symbol: 'SDG',
        name: 'Sudanese Pound',
        symbol_native: 'SDG',
        decimal_digits: 2,
        rounding: 0,
        code: 'SDG',
        name_plural: 'Sudanese pounds',
    },
    {
        symbol: 'Skr',
        name: 'Swedish Krona',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'SEK',
        name_plural: 'Swedish kronor',
    },
    {
        symbol: 'S$',
        name: 'Singapore Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'SGD',
        name_plural: 'Singapore dollars',
    },
    {
        symbol: 'Ssh',
        name: 'Somali Shilling',
        symbol_native: 'Ssh',
        decimal_digits: 0,
        rounding: 0,
        code: 'SOS',
        name_plural: 'Somali shillings',
    },
    {
        symbol: 'SY£',
        name: 'Syrian Pound',
        symbol_native: 'ل.س.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'SYP',
        name_plural: 'Syrian pounds',
    },
    {
        symbol: '฿',
        name: 'Thai Baht',
        symbol_native: '฿',
        decimal_digits: 2,
        rounding: 0,
        code: 'THB',
        name_plural: 'Thai baht',
    },
    {
        symbol: 'DT',
        name: 'Tunisian Dinar',
        symbol_native: 'د.ت.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'TND',
        name_plural: 'Tunisian dinars',
    },
    {
        symbol: 'T$',
        name: 'Tongan Paʻanga',
        symbol_native: 'T$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TOP',
        name_plural: 'Tongan paʻanga',
    },
    {
        symbol: 'TL',
        name: 'Turkish Lira',
        symbol_native: 'TL',
        decimal_digits: 2,
        rounding: 0,
        code: 'TRY',
        name_plural: 'Turkish Lira',
    },
    {
        symbol: 'TT$',
        name: 'Trinidad and Tobago Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TTD',
        name_plural: 'Trinidad and Tobago dollars',
    },
    {
        symbol: 'NT$',
        name: 'New Taiwan Dollar',
        symbol_native: 'NT$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TWD',
        name_plural: 'New Taiwan dollars',
    },
    {
        symbol: 'TSh',
        name: 'Tanzanian Shilling',
        symbol_native: 'TSh',
        decimal_digits: 0,
        rounding: 0,
        code: 'TZS',
        name_plural: 'Tanzanian shillings',
    },
    {
        symbol: '₴',
        name: 'Ukrainian Hryvnia',
        symbol_native: '₴',
        decimal_digits: 2,
        rounding: 0,
        code: 'UAH',
        name_plural: 'Ukrainian hryvnias',
    },
    {
        symbol: 'USh',
        name: 'Ugandan Shilling',
        symbol_native: 'USh',
        decimal_digits: 0,
        rounding: 0,
        code: 'UGX',
        name_plural: 'Ugandan shillings',
    },
    {
        symbol: '$U',
        name: 'Uruguayan Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'UYU',
        name_plural: 'Uruguayan pesos',
    },
    {
        symbol: 'UZS',
        name: 'Uzbekistan Som',
        symbol_native: 'UZS',
        decimal_digits: 0,
        rounding: 0,
        code: 'UZS',
        name_plural: 'Uzbekistan som',
    },
    {
        symbol: 'Bs.F.',
        name: 'Venezuelan Bolívar',
        symbol_native: 'Bs.F.',
        decimal_digits: 2,
        rounding: 0,
        code: 'VEF',
        name_plural: 'Venezuelan bolívars',
    },
    {
        symbol: '₫',
        name: 'Vietnamese Dong',
        symbol_native: '₫',
        decimal_digits: 0,
        rounding: 0,
        code: 'VND',
        name_plural: 'Vietnamese dong',
    },
    {
        symbol: 'FCFA',
        name: 'CFA Franc BEAC',
        symbol_native: 'FCFA',
        decimal_digits: 0,
        rounding: 0,
        code: 'XAF',
        name_plural: 'CFA francs BEAC',
    },
    {
        symbol: 'CFA',
        name: 'CFA Franc BCEAO',
        symbol_native: 'CFA',
        decimal_digits: 0,
        rounding: 0,
        code: 'XOF',
        name_plural: 'CFA francs BCEAO',
    },
    {
        symbol: 'YR',
        name: 'Yemeni Rial',
        symbol_native: 'ر.ي.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'YER',
        name_plural: 'Yemeni rials',
    },
    {
        symbol: 'R',
        name: 'South African Rand',
        symbol_native: 'R',
        decimal_digits: 2,
        rounding: 0,
        code: 'ZAR',
        name_plural: 'South African rand',
    },
    {
        symbol: 'ZK',
        name: 'Zambian Kwacha',
        symbol_native: 'ZK',
        decimal_digits: 0,
        rounding: 0,
        code: 'ZMK',
        name_plural: 'Zambian kwachas',
    },
    {
        symbol: 'ZWL$',
        name: 'Zimbabwean Dollar',
        symbol_native: 'ZWL$',
        decimal_digits: 0,
        rounding: 0,
        code: 'ZWL',
        name_plural: 'Zimbabwean Dollar',
    },
];


export const COUNTRIES = [
    {
        "name": "Algerian Arabic",
        "code": "arq"
    },
    {
        "name": "Bengali",
        "code": "bn"
    },
    {
        "name": "English",
        "code": "en"
    },
    {
        "name": "French",
        "code": "fr"
    },
    {
        "name": "Hindi",
        "code": "hi"
    },
    {
        "name": "Mandarin",
        "code": "cmn"
    },
    {
        "name": "Portuguese",
        "code": "pt"
    },
    {
        "name": "Russian",
        "code": "ru"
    },
    {
        "name": "Spanish",
        "code": "es"
    },
    {
        "name": "Swahili",
        "code": "sw"
    },
]

export const PRICING_BANDS = [
    {
        properties: 1,
        pricePerMonth: 0,
        pricePerProperty: 0,
        storage: 50,
    },
    {
        properties: 2,
        pricePerMonth: 60,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 3,
        pricePerMonth: 90,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 4,
        pricePerMonth: 120,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 5,
        pricePerMonth: 150,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 6,
        pricePerMonth: 180,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 7,
        pricePerMonth: 210,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 8,
        pricePerMonth: 240,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 9,
        pricePerMonth: 270,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 10,
        pricePerMonth: 300,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 11,
        pricePerMonth: 330,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 12,
        pricePerMonth: 360,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 13,
        pricePerMonth: 390,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 14,
        pricePerMonth: 420,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 15,
        pricePerMonth: 450,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 16,
        pricePerMonth: 480,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 17,
        pricePerMonth: 510,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 18,
        pricePerMonth: 540,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 19,
        pricePerMonth: 570,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 20,
        pricePerMonth: 600,
        pricePerProperty: 30,
        storage: 100,
    },
    {
        properties: 21,
        pricePerMonth: 525,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 22,
        pricePerMonth: 550,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 23,
        pricePerMonth: 575,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 24,
        pricePerMonth: 600,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 25,
        pricePerMonth: 625,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 26,
        pricePerMonth: 650,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 27,
        pricePerMonth: 675,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 28,
        pricePerMonth: 700,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 29,
        pricePerMonth: 725,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 30,
        pricePerMonth: 750,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 31,
        pricePerMonth: 775,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 32,
        pricePerMonth: 800,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 33,
        pricePerMonth: 825,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 34,
        pricePerMonth: 850,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 35,
        pricePerMonth: 875,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 36,
        pricePerMonth: 900,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 37,
        pricePerMonth: 925,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 38,
        pricePerMonth: 950,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 39,
        pricePerMonth: 975,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 40,
        pricePerMonth: 1000,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 41,
        pricePerMonth: 1025,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 42,
        pricePerMonth: 1050,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 43,
        pricePerMonth: 1075,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 44,
        pricePerMonth: 1100,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 45,
        pricePerMonth: 1125,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 46,
        pricePerMonth: 1150,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 47,
        pricePerMonth: 1175,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 48,
        pricePerMonth: 1200,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 49,
        pricePerMonth: 1225,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 50,
        pricePerMonth: 1250,
        pricePerProperty: 25,
        storage: 250,
    },
    {
        properties: 51,
        pricePerMonth: 1020,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 52,
        pricePerMonth: 1040,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 53,
        pricePerMonth: 1060,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 54,
        pricePerMonth: 1080,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 55,
        pricePerMonth: 1100,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 56,
        pricePerMonth: 1120,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 57,
        pricePerMonth: 1140,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 58,
        pricePerMonth: 1160,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 59,
        pricePerMonth: 1180,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 60,
        pricePerMonth: 1200,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 61,
        pricePerMonth: 1220,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 62,
        pricePerMonth: 1240,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 63,
        pricePerMonth: 1260,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 64,
        pricePerMonth: 1280,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 65,
        pricePerMonth: 1300,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 66,
        pricePerMonth: 1320,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 67,
        pricePerMonth: 1340,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 68,
        pricePerMonth: 1360,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 69,
        pricePerMonth: 1380,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 70,
        pricePerMonth: 1400,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 71,
        pricePerMonth: 1420,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 72,
        pricePerMonth: 1440,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 73,
        pricePerMonth: 1460,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 74,
        pricePerMonth: 1480,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 75,
        pricePerMonth: 1500,
        pricePerProperty: 20,
        storage: 350,
    },
    {
        properties: 76,
        pricePerMonth: 1140,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 77,
        pricePerMonth: 1155,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 78,
        pricePerMonth: 1170,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 79,
        pricePerMonth: 1185,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 80,
        pricePerMonth: 1200,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 81,
        pricePerMonth: 1215,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 82,
        pricePerMonth: 1230,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 83,
        pricePerMonth: 1245,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 84,
        pricePerMonth: 1260,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 85,
        pricePerMonth: 1275,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 86,
        pricePerMonth: 1290,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 87,
        pricePerMonth: 1305,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 88,
        pricePerMonth: 1320,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 89,
        pricePerMonth: 1335,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 90,
        pricePerMonth: 1350,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 91,
        pricePerMonth: 1365,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 92,
        pricePerMonth: 1380,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 93,
        pricePerMonth: 1395,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 94,
        pricePerMonth: 1410,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 95,
        pricePerMonth: 1425,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 96,
        pricePerMonth: 1440,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 97,
        pricePerMonth: 1455,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 98,
        pricePerMonth: 1470,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 99,
        pricePerMonth: 1485,
        pricePerProperty: 15,
        storage: 500,
    },
    {
        properties: 100,
        pricePerMonth: 1500,
        pricePerProperty: 15,
        storage: 500,
    },
];

export const BLINDS_DISPLAY_TIMEOUT = 1500;

export const GLOBAL_CITIES = [
    'Hong Kong',
    'Ashgabat',
    'Tokyo',
    'Zurich',
    'Singapore',
    'New York City',
    'Shanghai',
    'Bern',
    'Geneva',
    'Beijing',
    'Seoul',
    'Tel Aviv',
    'Shenzhen',
    'Victoria',
    'Ndjamena',
    'San Francisco',
    'Los Angeles',
    'Lagos',
    'London',
    'Guangzhou',
    'Moscow',
    'Osaka',
    'Dubai',
    'Kinshasa',
    'Copenhagen',
    'Nagoya',
    'Dhaka',
    'Taipei',
    'Honolulu',
    'Chicago',
    'Riyadh',
    'Washington',
    'Libreville',
    'Nanjing',
    'Bangkok',
    'Abidjan',
    'Miami',
    'Tianjin',
    'Abu Dhabi',
    'Chengdu',
    'Boston',
    'Noumea',
    'Qingdao',
    'Brazzaville',
    'Beirut',
    'Dublin',
    'Milan',
    'White Plains',
    'Bangui',
    'Paris',
    'Houston',
    'Manama',
    'Dallas',
    'Vienna',
    'Seattle',
    'Yaounde',
    'Accra',
    'Djibouti',
    'St. Petersburg',
    'Mumbai',
    'Atlanta',
    'Morristown',
    'Shenyang',
    'Amsterdam',
    'Rome',
    'Sydney',
    'San Juan',
    'Abuja',
    'Minneapolis',
    'Douala',
    'Amman',
    'Munich',
    'Port Of Spain',
    'Helsinki',
    'Dakar',
    'Oslo',
    'Frankfurt',
    'Brussels',
    'San Jose',
    'Detroit',
    'Manila',
    'Berlin',
    'Luxembourg',
    'St. Louis',
    'Yangon',
    'Jakarta',
    'Madrid',
    'Montevideo',
    'Conakry',
    'Panama City',
    'Pittsburgh',
    'Portland',
    'Cleveland',
    'Vancouver',
    'Nairobi',
    'Muscat',
    'Prague',
    'Toronto',
    'Melbourne',
    'Dusseldorf',
    'New Delhi',
    'Barcelona',
    'Auckland',
    'Perth',
    'Jeddah',
    'Lisbon',
    'Hamburg',
    'Kiev',
    'Phnom Penh',
    'Doha',
    'Ho Chi Minh City',
    'Lima',
    'Riga',
    'Kuwait City',
    'Luanda',
    'Hanoi',
    'Niamey',
    'Canberra',
    'Bandar Seri Begawan',
    'Mexico City',
    'Casablanca',
    'Guatemala City',
    'Wellington',
    'Lyon',
    'Havana',
    'Brisbane',
    'Adelaide',
    'Cairo',
    'Birmingham',
    'Sao Paulo',
    'Stuttgart',
    'Winston Salem',
    'Stockholm',
    'Santiago',
    'Pointe A Pitre',
    'Aberdeen',
    'Montreal',
    'Athens',
    'Bratislava',
    'Cotonou',
    'Glasgow',
    'Bamako',
    'Chennai',
    'Kuala Lumpur',
    'Quito',
    'Tallinn',
    'Calgary',
    'Ljubljana',
    'Belfast',
    'Kingston',
    'Ottawa',
    'Zagreb',
    'Buenos Aires',
    'Lome',
    'Nuremberg',
    'Istanbul',
    'Vilnius',
    'Leipzig',
    'Rabat',
    'Rio De Janeiro',
    'Maputo',
    'Ouagadougou',
    'Tirana',
    'Dar Es Salaam',
    'Harare',
    'Limassol',
    'Santo Domingo',
    'San Salvador',
    'Warsaw',
    'Budapest',
    'Bengaluru',
    'Baku',
    'Port Louis',
    'Bucharest',
    'Port Au Prince',
    'Kigali',
    'Colombo',
    'Kampala',
    'Monterrey',
    'Sofia',
    'Bogota',
    'Addis Ababa',
    'Belgrade',
    'Nouakchott',
    'Kolkata',
    'Blantyre',
    'Cape Town',
    'Asuncion',
    'Almaty',
    'Brasilia',
    'Yerevan',
    'Johannesburg',
    'La Paz',
    'Algiers',
    'Minsk',
    'Tegucigalpa',
    'Sarajevo',
    'Managua',
    'Gaborone',
    'Islamabad',
    'Lusaka',
    'Skopje',
    'Tbilisi',
    'Banjul',
    'Karachi',
    'Bishkek',
    'Tashkent',
    'Windhoek',
    'Tunis',
];

export const RANDOM_GLOBAL_CITY = GET_RANDOM_GLOBAL_CITY(GLOBAL_CITIES);

export const SUPPORTED_APP_LANGUAGES = ['en', 'fr'];

export const STORY_CATEGORIES = [
    {
        category: 'Civilisation',
        subcategories: [
            'Agriculture',
            'Digitalisation',
            'Government',
            'Society',
            'Urban / Extra-Urban',
            'Other-Civilisation'
        ]
    },
    {
        category: 'Connections',
        subcategories: [
            'Cultural connnection', 
            'Dance & Music connection', 
            'Rapport connection',
            'Social connection',
            'Spiritual connection',
            'Other-Connections'
        ],
    },
    {
        category: 'Engineering',
        subcategories: [
            'Aerospace or Marine engineering',
            'Biological engineering', 
            'Chemical engineering', 
            'Civil or Mechanical engineering',
            'Computer or Electrical engineering',
            'Other-Engineering'
        ],
    },
    {
        category: 'Environment',
        subcategories: [
            'Biophysical environment',
            'Ecology',
            'Environmental system',
            'Natural environment',
            'Social environment',
            'other-Environment'
        ],
    },

    {
        category: 'Health',
        subcategories: [
            'Mental Health', 
            'Physical Health', 
            'Public & Global Health',
            'Self-Care',
            'Social Health',
            'Other-Health'
        ],
    },
    {
        category: 'Science',
        subcategories: [
            'Applied science',
            'Formal science',
            'Life science',
            'Physical science',
            'Social science',
            'other-Science'
        ],
    },
    
];

export const STORIES_SIDEBAR_MENU_ITEMS = [
    { label: 'All stories', param: 'all' },
    { label: 'My stories', param: 'my-stories' },
    { label: 'My archive', param: 'drafts' },
    { label: 'Saved stories', param: 'saved' },
];

export const STORY_FILES_DISPLAY_MAX = 4;

export const DEFAULT_PROPERTY_TYPE_ON_CREATE = 'house';

export const DEFAULT_CURRENCY_TYPE_ON_CREATE = 'EUR';

export const PROPERTY_PUBLIC_PAGE_FEATURES = [
    'Garden',
    'Balcony',
    'Dressing',
    'Terrace',
    'Attached bathroom',
    'Elevator',
    'Disabled access',
    'Swimming pool',
    'Laundry room',
    'Car park',
    'Jacuzzi',
    'Alarm',
    'Garage',
    'Intercom',
    'Basement',
];

export const PROPERTY_PUBLIC_PAGE_BUILDING_CONDITION = [
    'Good',
    'Just renovated',
    'To renovate',
    'As new',
    'To restore',
    'To rebuild',
];

export const PROPERTY_ENERGY_RATING_RANGES = [
    {
        range: '< = 45',
        label: 'a',
    },
    {
        range: '46 - 95',
        label: 'b',
    },
    {
        range: '96 - 150',
        label: 'c',
    },
    {
        range: '151 - 210',
        label: 'd',
    },
    {
        range: '211 - 275',
        label: 'e',
    },
    {
        range: '276 - 345',
        label: 'f',
    },
    {
        range: '> 346',
        label: 'g',
    },
];

export const PROPERTY_ENERGY_PERFORMANCES = [
    {
        label: 'Primary energy consumption',
        name: 'primary_energy_consumption',
        inputType: 'text',
    },
    {
        label: 'EPC report number',
        name: 'epc_report_number',
        inputType: 'text',
    },
    {
        label: 'CO₂ emission',
        name: 'co2_emission',
        inputType: 'text',
    },
    {
        label: 'Yearly energy consumption',
        name: 'yearly_energy_consumption',
        inputType: 'text',
    },
    {
        label: 'Heating type',
        name: 'heating_type',
        inputType: 'select',
    },
    {
        label: 'Heat pump',
        name: 'heat_pump',
        inputType: 'select',
    },
    {
        label: 'Photovoltaic solar panels',
        name: 'photovoltaic_solar_panels',
        inputType: 'select',
    },
    {
        label: 'Thermic solar panels',
        name: 'thermic_solar_panels',
        inputType: 'select',
    },
    {
        label: 'Double glazing',
        name: 'double_glazing',
        inputType: 'select',
    },
];

export const SITE_MENU = [
    {
        title: 'Home',
        to: '/auth/start/home',
    },
    {
        title: 'About',
        to: '/auth/start/about',
    },
    {
        title: 'Solutions',
        to: '/auth/start/solutions',
    },
];
