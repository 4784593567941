import React from 'react';

export default function SearchShieldIconOutlined() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.6718 4.80675L12.1718 2.06285C12.0609 2.02227 11.9391 2.02227 11.8282 2.06285L4.32821 4.80675C4.13109 4.87887 4 5.06642 4 5.27631V16.3675C4 16.5341 4.08306 16.6899 4.22148 16.7827L11.7215 21.8132C11.89 21.9262 12.11 21.9262 12.2785 21.8132L19.7785 16.7827C19.9169 16.6899 20 16.5341 20 16.3675V5.27631C20 5.06642 19.8689 4.87887 19.6718 4.80675Z"
                stroke="#74788D"
                strokeWidth="1.5"
            />
            <path
                d="M13.0003 13.8268C12.9186 13.7438 12.8723 13.6323 12.8714 13.5158L12.8703 13.3891C12.8701 13.3571 12.8577 13.3264 12.8357 13.3031C12.7885 13.2532 12.7102 13.2505 12.6572 13.2943C12.1154 13.7428 11.4327 13.9725 10.7367 13.9374C10.0001 13.9003 9.30604 13.5693 8.80024 13.0141C8.29444 12.4589 8.01625 11.7226 8.02407 10.9598C8.03189 10.197 8.32512 9.467 8.84219 8.92309C9.35926 8.37918 10.06 8.06367 10.7972 8.04279C11.5344 8.02191 12.2507 8.29728 12.7959 8.81111C13.341 9.32493 13.6725 10.0372 13.7208 10.7985C13.7665 11.5194 13.5548 12.2312 13.1282 12.7997C13.0877 12.8538 13.0917 12.9298 13.1399 12.977C13.1645 13.0012 13.1978 13.0146 13.2324 13.0143L13.3491 13.0133C13.4613 13.0124 13.569 13.057 13.6475 13.1371L15.4112 14.9348C15.5885 15.1154 15.5907 15.4041 15.4162 15.5876C15.2332 15.7801 14.9272 15.7829 14.7409 15.5938L13.0003 13.8268ZM12.8507 10.9788C12.8474 10.5755 12.7286 10.1823 12.5093 9.84883C12.29 9.51538 11.98 9.25669 11.6185 9.10547C11.2571 8.95425 10.8604 8.91729 10.4786 8.99927C10.0969 9.08125 9.74724 9.27848 9.47388 9.56603C9.20052 9.85358 9.01573 10.2185 8.94289 10.6147C8.87005 11.0109 8.91243 11.4206 9.06466 11.7919C9.21689 12.1632 9.47214 12.4794 9.79813 12.7007C10.1241 12.922 10.5062 13.0383 10.8961 13.0349C11.155 13.033 11.4111 12.9783 11.6496 12.8739C11.8881 12.7695 12.1043 12.6174 12.2859 12.4264C12.4674 12.2354 12.6108 12.0092 12.7077 11.7608C12.8047 11.5124 12.8532 11.2467 12.8507 10.9788Z"
                fill="#74788D"
                stroke="#74788D"
                strokeWidth="0.5"
            />
        </svg>
    );
}
