import React, { useEffect } from 'react';
// Import from Ant design
import { Modal, Form, Input, Select, Row, Col, Button } from 'antd';
// Import from Recoil
import { useRecoilValue } from 'recoil';
// Import atoms
import {
    // countries as countriesAtom,
    storyAction as storyActionAtom,
    activeStory as activeStoryAtom,
} from '../../../recoil/atoms';
// Import from exports
import { STORY_CATEGORIES, COUNTRIES } from '../../../exports/constants';
import { STORIES_FILTER_IS_DRAFTS } from '../../../exports/functions';
import { STORY_LENGTH_LIMIT } from '../../../utils/story';
// Import components
import FilesUpload from '../../Common/FilesUpload/FilesUpload';
import StoryRichTextEditor from '../../Common/RichTextEditor/RichTextEditor';
// Import icon components
import CloseIconRound from '../../../assets/images/svg/CloseIconRound';

function StoryModal(props) {
    // Local states
    const [storyLength, setStoryLength] = React.useState(0);
    // Read-only atoms
    // const countries = useRecoilValue(countriesAtom);
    const storyAction = useRecoilValue(storyActionAtom);
    const activeStory = useRecoilValue(activeStoryAtom);

    // Form instance
    const [storyForm] = Form.useForm();

    // Destructuring props
    const { storyModal } = props;

    // Reset form fields
    useEffect(() => {
        storyForm.resetFields();
    }, [storyModal.reset, storyForm]);

    // Update Story form fields on activeStory value change
    useEffect(() => {
        if (activeStory) {
            storyForm.setFieldsValue({
                story_title: activeStory.title,
                story_country: activeStory.country,
                story_category: activeStory.category,
                story_text: activeStory.story,
            });
        } else {
            storyForm.resetFields();
        }
    }, [activeStory, storyForm]);

    // // Utility functions
    // const storiesFilterIsDrafts = () => {
    //     return localStorage.getItem('FETCH_STORIES_BY') === 'drafts';
    // };

    let okTextPrimary, okTextSecondary, cancelText;
    switch (storyAction) {
        case 'new':
            okTextPrimary = 'Publish';
            okTextSecondary = 'Save in archive';
            cancelText = 'Delete';
            break;
        case 'edit':
            okTextPrimary = 'Update';
            okTextSecondary = STORIES_FILTER_IS_DRAFTS()
                ? 'Publish'
                : 'Move to archive';
            cancelText = 'Cancel';
            break;
        default:
            return null;
    }

    // Modal footer
    const modalFooter = [
        <Button key="back" type="text" onClick={props.onCancel}>
            {cancelText}
        </Button>,
        <Button
            loading={props.storyModal.confirmLoading === 'secondary'}
            onClick={props.onOkSecondary}
            disabled={storyLength > STORY_LENGTH_LIMIT}
        >
            {okTextSecondary}
        </Button>,
        <Button
            key="submit"
            type="primary"
            loading={props.storyModal.confirmLoading === 'primary'}
            onClick={props.onOkPrimary}
            disabled={storyLength > STORY_LENGTH_LIMIT}
        >
            {okTextPrimary}
        </Button>,
    ];

    return (
        <Modal
            centered
            visible={props.storyModal.visible}
            closable={false}
            afterClose={props.storyModalAfterClose}
            maskClosable={false}
            className="story-modal"
            title={storyAction === 'new' ? 'Post a story' : 'Edit your story'}
            footer={modalFooter}
        >
            <div className="modal-close" onClick={props.onStoryModalClose}>
                <CloseIconRound />
            </div>
            <Form
                name="story-form"
                hideRequiredMark={false}
                colon={false}
                form={storyForm}
            >
                <div className="story-title-wrapper">
                    <Row gutter={32}>
                        <Col xs={24} lg={12}>
                            <div className="story-title">
                                <Form.Item
                                    name="story_title"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter a story title',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Story title *"
                                        name="title"
                                        onChange={event =>
                                            props.onStoryFormInputChange(event)
                                        }
                                    />
                                </Form.Item>
                            </div>
                        </Col>

                        {/* country and category dropdown section */}
                        <Col xs={24} lg={12}>
                            <Row gutter={32}>
                                <Col span={12}>
                                    <div className="story-country">
                                        <Form.Item name="story_country">
                                            <Select
                                                allowClear
                                                showSearch
                                                placeholder="Language *"
                                                defaultValue='English'
                                                onChange={val =>
                                                    props.onStoryFormSelectChange(
                                                        val,
                                                        'country'
                                                    )
                                                }
                                            >
                                                {COUNTRIES.map(country => (
                                                    <Select.Option
                                                        key={country.name}
                                                        value={country.name}
                                                    >
                                                        {country.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="story-country">
                                        <Form.Item name="story_category">
                                            <Select
                                                allowClear
                                                showSearch
                                                placeholder="Category *"
                                                onChange={val =>
                                                    props.onStoryFormSelectChange(
                                                        val,
                                                        'category'
                                                    )
                                                }
                                            >
                                                {STORY_CATEGORIES.map(
                                                    (category, i) => (
                                                        <Select.OptGroup
                                                            key={i}
                                                            label={
                                                                category.category
                                                            }
                                                        >
                                                            {category.subcategories.map(
                                                                sub_cat => (
                                                                    <Select.Option
                                                                        key={
                                                                            sub_cat
                                                                        }
                                                                        value={
                                                                            sub_cat
                                                                        }
                                                                    >
                                                                        {
                                                                            sub_cat.endsWith("-"+category.category)?"Other":sub_cat
                                                                        }
                                                                    </Select.Option>
                                                                )
                                                            )}
                                                        </Select.OptGroup>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="story-content">
                    <StoryRichTextEditor
                        placeholder="Write your story or click on the film icon to embed videos *"
                        value={activeStory?.story || ''}
                        onChange={val =>
                            props.onStoryFormSelectChange(val, 'story')
                        }
                        maxTextLength={STORY_LENGTH_LIMIT}
                        textLength={storyLength}
                        setTextLength={setStoryLength}
                    />
                </div>
            </Form>
            {storyAction === 'new' && (
                <>
                    <div className="story-files">
                        <div className="story-files-heading">
                            <span className="color-primary">Share media</span>{' '}
                            (share audio, video or document files)
                        </div>
                        <FilesUpload
                            onFilesChange={props.onStoryModalFilesChange}
                            files={props.storyFiles}
                            onFileRemove={props.onStoryFileRemove}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
}

export default StoryModal;
