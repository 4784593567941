import React from 'react';

export default function HomeIconTextOutlined() {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <path
                className="main-nav__icon-border"
                d="M0 0H2V28H30V30H2C0.89543 30 0 29.1046 0 28V0Z"
                fill="#2196F3"
            /> */}
            <path
                className="main-nav__icon-stroke"
                d="M22.6 9.5H11.4C10.6268 9.5 10 10.1268 10 10.9V18.6C10 19.3732 10.6268 20 11.4 20H22.6C23.3732 20 24 19.3732 24 18.6V10.9C24 10.1268 23.3732 9.5 22.6 9.5Z"
                stroke="#2A2E33"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="main-nav__icon-stroke"
                d="M20.5 6L17 9.5L13.5 6"
                stroke="#2A2E33"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
