import React from 'react';
// Import dependencies
import { useRecoilValue } from 'recoil';
import {
    activeStory as activeStoryAtom,
    userDetails as userDetailsAtom,
} from '../../../recoil/atoms';
import { Skeleton } from 'antd';
// Import utils
import { GET_DISPLAY_NAME } from '../../../exports/functions';
// Import components
import BackdropOverlay from '../../Common/BackdropOverlay/BackdropOverlay';
import StoryEach from '../StoryEach/StoryEach';

export default function StoryParticular(props) {
    // Global states
    const activeStory = useRecoilValue(activeStoryAtom);
    const userDetails = useRecoilValue(userDetailsAtom);
    // Local states
    const [storyParticular, setStoryParticular] = React.useState(null);

    // On component mount
    React.useEffect(() => {
        activeStory && setStoryParticular(activeStory);
        console.log(activeStory);
    }, [activeStory, setStoryParticular]);

    return (
        <BackdropOverlay
            visible={props.visible}
            className="backdrop-overlay--story-particular"
            onOverlayClose={props.onClose}
            clickOutsideToClose={false}
        >
            {storyParticular ? (
                <StoryEach
                    key={storyParticular.id}
                    story={storyParticular}
                    authorPhotoUrl={`${process.env.REACT_APP_STORAGE_PROFILE}/${userDetails.image_link}`}
                    authorDisplayName={GET_DISPLAY_NAME(
                        userDetails.first_name,
                        userDetails.last_name,
                        userDetails.username,
                        userDetails.email
                    )}
                    editStoryModalOpen={() =>
                        props.editStoryModalOpen(storyParticular.id)
                    }
                    deleteStoryConfirmationOpen={() =>
                        props.deleteStoryConfirmationOpen(storyParticular.id)
                    }
                    handleStorySave={() =>
                        props.handleStorySave(storyParticular.id)
                    }
                    handleStoryMoveToDrafts={() =>
                        props.handleStoryMoveToDrafts(storyParticular.id)
                    }
                    onStoryEachFileClick={props.onStoryEachFileClick}
                />
            ) : (
                <Skeleton avatar paragraph={{ rows: 4 }} active />
            )}
        </BackdropOverlay>
    );
}
