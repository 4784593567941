import React from 'react';

function CloseIconRound() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="close-icon-round-outline"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#FB4E4E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M15 9L9 15"
                stroke="#FB4E4E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M9 9L15 15"
                stroke="#FB4E4E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
        </svg>
    );
}

export default CloseIconRound;
