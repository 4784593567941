import React from 'react';
// Import from dependencies
import { Row, Col } from 'antd';
// Import from constants
import { STORY_FILES_DISPLAY_MAX } from '../../../../exports/constants';
// Import components
import StoryEachFile from './StoryEachFile/StoryEachFile';

export default function StoryEachFiles(props) {
    // Constants
    const storyFiles = props.storyFiles;
    const storyFilesCount = props.storyFiles.length;
    // Variables
    let firstColSpan, secondColSpan, thirdColSpan;
    let storyFilesClassName = [
        'story-each__files',
        `story-each__files--${storyFilesCount}`,
    ];
    // Checks
    if (storyFilesCount === 1) firstColSpan = 24;
    else if (storyFilesCount === 2) {
        firstColSpan = 12;
        secondColSpan = 12;
    } else if (storyFilesCount === 3) {
        firstColSpan = 8;
        secondColSpan = 8;
        thirdColSpan = 8;
    } else {
        firstColSpan = 10;
        secondColSpan = 8;
        thirdColSpan = 6;
        storyFilesClassName.push('story-each__files--full');
        if (storyFilesCount > 4)
            storyFilesClassName.push('story-each__files--overflow');
    }
    return (
        <>
            {/* {storyFilesCount} */}
            <div className={storyFilesClassName.join(' ')}>
                {/* Render story files */}
                <Row gutter={storyFilesCount > 1 && 2}>
                    <Col span={firstColSpan}>
                        <StoryEachFile
                            key={storyFiles[0].id}
                            file={storyFiles[0]}
                            onStoryEachFileClick={props.onStoryEachFileClick}
                        />
                    </Col>
                    {storyFilesCount > 1 && (
                        <Col span={secondColSpan}>
                            <StoryEachFile
                                key={storyFiles[1].id}
                                file={storyFiles[1]}
                                onStoryEachFileClick={
                                    props.onStoryEachFileClick
                                }
                            />
                        </Col>
                    )}
                    {storyFilesCount > 2 && (
                        <Col
                            span={thirdColSpan}
                            className={
                                storyFilesCount > 3 &&
                                'ant-col--story-files-full'
                            }
                        >
                            {storyFilesCount === 3 ? (
                                <StoryEachFile
                                    key={storyFiles[2].id}
                                    file={storyFiles[2]}
                                    onStoryEachFileClick={
                                        props.onStoryEachFileClick
                                    }
                                />
                            ) : (
                                storyFiles
                                    .filter((_, i) => i > 1)
                                    .map((file, i) => (
                                        <StoryEachFile
                                            key={file.id}
                                            file={file}
                                            isLastFile={
                                                i ===
                                                storyFilesCount -
                                                    (STORY_FILES_DISPLAY_MAX -
                                                        1)
                                            }
                                            remainingFilesToDisplay={
                                                storyFilesCount -
                                                (STORY_FILES_DISPLAY_MAX - 1)
                                            }
                                            onStoryEachFileClick={
                                                props.onStoryEachFileClick
                                            }
                                        />
                                    ))
                            )}
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
}
