import React from 'react';
import { Popover, Button } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
// Import components
import StorySharePopoverContent from './StorySharePopoverContent/StorySharePopoverContent';
import ShareIconOutlined from '../../../../../assets/images/svg/ShareIconOutlined';

export default function StoryActionEach(props) {
    // Local states
    const [storySharePopoverVisibility, setStorySharePopoverVisibility] =
        React.useState(false);

    // Handle story share popover visibility
    const handleStorySharePopoverVisibility = visibility => {
        setStorySharePopoverVisibility(visibility);
    };

    // Action each class name
    let className = ['story-each__action-each'];
    // Add className if available as prop
    if (props.className) className.push(props.className);

    // Story share popover content
    const content = (
        <div className={className.join(' ')} onClick={props.onClick}>
            <div className="story-each__action-icon">{props.icon}</div>
            <div className="story-each__action-label">{props.label}</div>
            <div className="story-each__action-count">{props.count}</div>
        </div>
    );

    return props.label === 'Share' ? (
        <Popover
            content={
                <StorySharePopoverContent
                    storyId={props.storyId}
                    setStorySharePopoverVisibility={
                        setStorySharePopoverVisibility
                    }
                />
            }
            trigger="click"
            visible={storySharePopoverVisibility}
            onVisibleChange={handleStorySharePopoverVisibility}
            overlayClassName="ant-popover--story-share"
        >
            {content}
        </Popover>
    ) : (
        content
    );
}
