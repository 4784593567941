import React from 'react';
// Import from Ant design
import { Modal } from 'antd';

function ConfirmationModal(props) {
	return (
		<Modal
			centered
			className={
				props.className
					? `ant-modal--confirmation ${props.className}`
					: 'ant-modal--confirmation'
			}
			visible={props.visible}
			confirmLoading={props.confirmLoading}
			closable={false}
			maskClosable={false}
			okText={props.okText}
			cancelText={props.cancelText}
			afterClose={props.afterClose}
			onOk={props.onOk}
			onCancel={props.onCancel}
			okButtonProps={props.theme === 'negative' && { danger: true }}
		>
			<p className="ant-modal-title">{props.title}</p>
			<p className="ant-modal-message">{props.message}</p>
		</Modal>
	);
}

export default ConfirmationModal;
