import React from 'react';

export default function AddIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="save-icon-outlined-outline"
                d="M4 5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V21.191C20 21.5627 19.6088 21.8044 19.2764 21.6382L12 18L4.72361 21.6382C4.39116 21.8044 4 21.5627 4 21.191V5Z"
                fill="#8296A9"
            />
            <path
                className="save-icon-outlined-tick"
                d="M9.70351 10.2691C9.31101 9.88057 8.67785 9.88379 8.28931 10.2763C7.90078 10.6688 7.90399 11.3019 8.29649 11.6905L9.70351 10.2691ZM11.5 13.4545L10.7965 14.1652L11.5 14.8616L12.2035 14.1652L11.5 13.4545ZM16.7035 9.71069C17.096 9.32215 17.0992 8.68899 16.7107 8.29649C16.3221 7.90399 15.689 7.90078 15.2965 8.28931L16.7035 9.71069ZM8.29649 11.6905L10.7965 14.1652L12.2035 12.7439L9.70351 10.2691L8.29649 11.6905ZM12.2035 14.1652L16.7035 9.71069L15.2965 8.28931L10.7965 12.7439L12.2035 14.1652Z"
                fill="#FFFFFF"
            />
        </svg>
    );
}
