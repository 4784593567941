import React from 'react';
// Import from recoil
import { useRecoilValue } from 'recoil';
import { userDetails as userDetailsAtom } from '../../../recoil/atoms';
// import from exports
import { GET_DISPLAY_NAME } from '../../../exports/functions';
// Import components
import PhotoPreview from '../../Common/PhotoPreview/PhotoPreview';

export default function StoryCreate(props) {
    // Global state
    const userDetails = useRecoilValue(userDetailsAtom);
    return (
        <div
            className="story-create stories-feed-block"
            onClick={props.newStoryModalOpen}
        >
            <div className="story-create__user">
                <PhotoPreview
                    photoUrl={`${process.env.REACT_APP_STORAGE_PROFILE}/${userDetails.image_link}`}
                />
                <div className="story-create__user-display-name">
                    {GET_DISPLAY_NAME(
                        userDetails.first_name,
                        userDetails.last_name,
                        userDetails.username,
                        userDetails.email
                    )}
                </div>
            </div>
            <div className="story-create__message">
                Share your story with the community
            </div>
        </div>
    );
}
