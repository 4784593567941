import React from 'react';

export default function AddIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.4 19.3999C18 19.3999 6.07067 19.3999 6.07067 19.3999C4 19.3999 4 19.3999 4 18.1999C4 16.9999 4 16.9999 6.07067 16.9999C6.07067 16.9999 18 16.9999 19.4 16.9999C20.8 16.9999 20.8 17.676 20.8 18.1999C20.8 18.7238 20.8 19.3999 19.4 19.3999Z"
                fill="#74788D"
            />
            <path
                d="M12.0914 11.809L10.1795 9.89963C10.5211 9.29899 11.0194 8.70401 11.6736 8.11657C12.5173 7.35761 14.0063 6.07466 16.1403 4.26773C16.3565 4.08459 16.6337 3.98935 16.917 4.00095C17.2003 4.01254 17.4688 4.13011 17.6693 4.33031C17.8697 4.53051 17.9874 4.79869 17.9991 5.08158C18.0107 5.36447 17.9153 5.64137 17.7319 5.85728C15.9201 7.99217 14.6361 9.47931 13.8786 10.3181C13.2904 10.9696 12.6947 11.4666 12.0914 11.809ZM9.31322 10.8125L11.1785 12.6747L10.3192 13.8722L7 15L8.11415 11.6701L9.31322 10.8125Z"
                fill="#74788D"
            />
        </svg>
    );
}
