import React from 'react';

export default function UploadImageEditIcon(props) {
	return (
		<svg
			width={props.size || '24'}
			height={props.size || '24'}
			viewBox="0 0 27 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{' '}
			<path
				d="M8.14709 24.4745L1.39496 26.6956L3.20515 19.8269L19.93 2.14139C20.0206 2.05086 20.1434 2 20.2715 2C20.3996 2 20.5224 2.05086 20.613 2.14139L24.8442 6.15047C24.8909 6.19347 24.9286 6.24526 24.9553 6.30287C24.982 6.36048 24.997 6.42277 24.9996 6.48621C25.0022 6.54964 24.9922 6.61295 24.9703 6.67254C24.9484 6.73212 24.915 6.7868 24.8719 6.83345L8.14709 24.4745Z"
				stroke="#252525"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>{' '}
			<path
				d="M-1.3722e-05 30.8545H20.5396"
				stroke="#252525"
				strokeWidth="2"
				strokeLinejoin="round"
			/>{' '}
		</svg>
	);
}
