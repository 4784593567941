import { atom, selector } from 'recoil';
import moment from 'moment';
// Import utils
import { isPropertyNew } from '../utils/properties';
// Import assets
import AppBg01 from '../assets/images/app-bg-01.png';
import AppBg02 from '../assets/images/app-bg-02.png';
import AppBg03 from '../assets/images/app-bg-03.png';
import AppBg04 from '../assets/images/app-bg-04.png';
import AppBg05 from '../assets/images/app-bg-05.png';

// C.O.M.M.O.N

// Multipurpose Id
export const multipurposeId = atom({
    key: 'multipurposeId',
    default: null,
});

// Saving status
export const savingStatus = atom({
    key: 'savingStatus',
    default: null,
});

export const appBgAll = atom({
    key: 'appBgAll',
    default: [{
        url: null,
        credit: null
    },
    {
        url: AppBg01,
        credit: {
            label: 'Natacha Bisarre - Visual Artist',
            link: 'https://www.natachabisarre.com'
        }
    },
    {
        url: AppBg02,
        credit: {
            label: 'Natacha Bisarre - Visual Artist',
            link: 'https://www.natachabisarre.com'
        }
    },
    {
        url: AppBg03,
        credit: {
            label: 'Natacha Bisarre - Visual Artist',
            link: 'https://www.natachabisarre.com'
        }
    },
    {
        url: AppBg04,
        credit: {
            label: 'Casey Horner',
            link: 'https://www.instagram.com/mischievous_penguins/ '
        }
    },
    {
        url: AppBg05,
        credit: {
            label: 'Casey Horner',
            link: 'https://www.instagram.com/mischievous_penguins/ '
        }
    }],
});

export const appBgIndex = atom({
    key: 'appBgIndex',
    default: null,
});

export const appBg = selector({
    key: 'appBg',
    get: ({ get }) => {
        const appBgAllClone = get(appBgAll);
        const i = get(appBgIndex);
        return appBgAllClone[i];
    },
});

// M.A.I.N - A.P.P

// Creating property boolean
export const isCreatingNewProperty = atom({
    key: 'isCreatingNewProperty',
    default: false,
});

// User details
export const userDetails = atom({
    key: 'userDetails',
    default: {},
});

// Verification status
export const verificationStatus = atom({
    key: 'verificationStatus',
    default: '',
});

// List of properties
export const properties = atom({
    key: 'properties',
    default: null,
});

// Filter properties by
export const filterPropertiesBy = atom({
    key: 'filterPropertiesBy',
    default: '',
});

// Current property
export const currentProperty = atom({
    key: 'currentProperty',
    default: null,
});

// Property documents
export const propertyDocuments = atom({
    key: 'propertyDocuments',
    default: [],
});

// Current team
export const activeTeam = atom({
    key: 'activeTeam',
    default: {},
});

// Team switch name
export const teamSwitchName = atom({
    key: 'teamSwitchName',
    default: '',
});

// Active app language
export const activeAppLanguage = atom({
    key: 'activeAppLanguage',
    default: 'en',
});

// S.T.O.R.I.E.S
// Story themes
export const themes = atom({
    key: 'themes',
    default: [
        { title: 'buying', totalStories: 25 },
        { title: 'contracts', totalStories: 86 },
        { title: 'insurance', totalStories: 15 },
        { title: 'legal', totalStories: 45 },
        { title: 'maintenance', totalStories: 8 },
        { title: 'renovation', totalStories: 14 },
        { title: 'renting', totalStories: 59 },
        { title: 'selling', totalStories: 15 },
    ],
});

// UI
export const UI = atom({
    key: 'UI',
    default: {},
});

// Story
export const story = atom({
    key: 'story',
    default: {},
});

// Story action
export const storyAction = atom({
    key: 'storyAction',
    default: '',
});

// Loaded stories
export const loadedStories = atom({
    key: 'loadedStories',
    default: {
        data: [],
        count: null,
    },
});

// Stories count
export const storiesCount = atom({
    key: 'storiesCount',
    default: {},
});

// Loaded stories
export const savingStory = atom({
    key: 'savingStory',
    default: false,
});

// Is updating story category
export const isUpdatingStoryCategory = atom({
    key: 'isUpdatingStoryCategory',
    default: false,
});

// Active theme
export const activeTheme = atom({
    key: 'activeTheme',
    default: '',
});

// Active Story ID
export const activeStoryId = atom({
    key: 'activeStoryId',
    default: '',
});

// Filter Active story by Active page ID
export const activeStory = selector({
    key: 'activeStory',
    get: ({ get }) => {
        const stories = get(loadedStories);
        const [activeStory] = stories.data.filter(
            story => story.id === get(activeStoryId)
        );
        // console.log(activeStory, 'Active story');
        return activeStory;
    },
});

// Filter properties by
export const filteredProperties = selector({
    key: 'filteredProperties',
    get: ({ get }) => {
        const propertiesClone = get(properties);
        const filter = get(filterPropertiesBy);
        if (filter === '') {
            return propertiesClone;
        } else {
            let data;
            if (filter === 'newly-listed') {
                data = propertiesClone.filter(property =>
                    isPropertyNew(property.created_at)
                );
            } else if (filter === 'all') {
                data = propertiesClone;
            } else {
                data = propertiesClone.filter(
                    property => property.status === filter
                );
            }
            return data;
        }
    },
});

// PROPERTY EVENTS
// Property events list
export const propertyEvents = atom({
    key: 'propertyEvents',
    default: null,
});

export const propertyEventsActivePanelKey = atom({
    key: 'propertyEventsActivePanelKey',
    default: 1,
});

export const filterPropertyEventsByDate = atom({
    key: 'filterPropertyEventsByDate',
    default: null,
});

export const filteredPropertyEvents = selector({
    key: 'filteredPropertyEvents',
    get: ({ get }) => {
        const propertyEventsClone = get(propertyEvents);
        const filter = get(filterPropertyEventsByDate);

        if (!filter || filter === 'Invalid date') return propertyEventsClone;

        const filteredEvents = propertyEventsClone.filter(
            event => moment(event.event_date).format('DD/MM/YYYY') === filter
        );
        return filteredEvents;
    },
});
