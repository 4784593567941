import React from 'react';

export default function TermsAndConditions() {
	return (
		<div className="user__tabs-body-iframe">
			<iframe
				src="https://www.iubenda.com/terms-and-conditions/76327275"
				title="Reload terms and conditions"
			></iframe>
		</div>
	);
}
