import React from 'react';

export default function CheckIconRoundOutlined() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 11.5L10.5 15L16.5 9"
				stroke="#74788D"
				strokeWidth="1.5"
			/>
			<circle
				cx="12"
				cy="12"
				r="9.25"
				stroke="#74788D"
				strokeWidth="1.5"
			/>
		</svg>
	);
}
