import React from 'react';
// Import from dependencies
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamSwitchName as teamSwitchNameAtom } from '../../../recoil/atoms';
// Import assets
import SwitchingIcon from '../../../assets/images/switching.gif';
// Import icon components
import ReloadLogoPill from '../../../assets/images/Reload-Logo-Pill.png'

export default function HeadingAndDescription(props) {
  // Global states
  const teamSwitchName = useRecoilValue(teamSwitchNameAtom);
  // i18n
  const { t } = useTranslation();

  let verificationStatusHeading,
    verificationStatusMessageFirst,
    verificationStatusMessageSecond;
  let headingAndDescriptionHeadingClass = ['heading-and-description__heading'];
  switch (props.verificationStatus) {
    case 'verifying':
      verificationStatusHeading = t(
        'auth.verificationStatus.verifying.verificationStatusHeading'
      );
      verificationStatusMessageFirst = t(
        'Please wait until the verification process has been completed ...'
      );
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--primary'
      );
      break;
    case 'switching-team':
      verificationStatusHeading = t('Switching team ...');
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--primary'
      );
      verificationStatusMessageFirst = t('Please wait ...');
      break;
    case 'leaving-team':
      verificationStatusHeading = t('Leaving team ...');
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--primary'
      );
      verificationStatusMessageFirst = t('Please wait ...');
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--error'
      );
      break;
    case 'email-verified':
      verificationStatusHeading = t(
        'auth.verificationStatus.verified.verificationStatusHeading'
      );
      verificationStatusMessageFirst = t(
        'auth.verificationStatus.verified.verificationStatusMessageFirst'
      );
      verificationStatusMessageSecond = t(
        'auth.verificationStatus.verified.verificationStatusMessageSecond'
      );
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--primary'
      );
      break;
    case 'invitation-accepted':
      verificationStatusHeading = 'Invitation accepted';
      verificationStatusMessageFirst = 'Your invitation has been accepted.';
      verificationStatusMessageSecond =
        'You are now being redirected to the dashboard.';
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--primary'
      );
      break;
    case 'verification-error':
      verificationStatusHeading = t(
        'auth.verificationStatus.verificationError.verificationStatusHeading'
      );
      verificationStatusMessageFirst = t(
        'auth.verificationStatus.verificationError.verificationStatusMessageFirst'
      );
      verificationStatusMessageSecond = t(
        'auth.verificationStatus.verificationError.verificationStatusMessageSecond'
      );
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--error'
      );
      break;
    case 'something-wrong':
      verificationStatusHeading = 'Oops! Something went wrong';
      verificationStatusMessageFirst =
        'Something unexpected has caused this error.';
      verificationStatusMessageSecond =
        'Please close this window and try logging in again.';
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--error'
      );
      break;
    case 'email-updated':
      verificationStatusHeading = t(
        'auth.verificationStatus.emailUpdated.verificationStatusHeading'
      );
      verificationStatusMessageFirst = t(
        'auth.verificationStatus.emailUpdated.verificationStatusMessageFirst'
      );
      verificationStatusMessageSecond = t(
        'auth.verificationStatus.emailUpdated.verificationStatusMessageSecond'
      );
      headingAndDescriptionHeadingClass.push(
        'heading-and-description__heading--primary'
      );
      break;
    default:
      verificationStatusHeading = null;
  }

  return (
    <div className='heading-and-description'>
      <div className='heading-and-description__inner'>
        <div className='heading-and-description__logo'>
          <img style={{maxHeight: "70px"}} src={ReloadLogoPill} alt="Logo" />
        </div>
        {props.verificationStatus === 'switching-team' ? (
          <>
            <div className='heading-and-description__switching-icon'>
              <img src={SwitchingIcon} alt='Switching ...' />
            </div>
            <p className='heading-and-description__switching-text'>
              Switching you to{' '}
              <span className='color-primary'>{teamSwitchName}</span>
              's project
            </p>
          </>
        ) : (
          <>
            {verificationStatusHeading && (
              <p className={headingAndDescriptionHeadingClass.join(' ')}>
                {verificationStatusHeading}
              </p>
            )}
            {verificationStatusMessageFirst && (
              <p className='heading-and-description__description'>
                {verificationStatusMessageFirst}
                <br />
                {verificationStatusMessageSecond}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}
