import React from 'react';

export default function HomeIconTextOutlined() {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {/* <path
                className="main-nav__icon-border"
                d="M0 0H2V28H30V30H2C0.89543 30 0 29.1046 0 28V0Z"
                fill="#2196F3"
            /> */}
            <path
                d="M21.8333 21C20.6667 21 10.7256 21 10.7256 21C9 21 9 21 9 20C9 19 9 19 10.7256 19C10.7256 19 20.6667 19 21.8333 19C23 19 23 19.5634 23 20C23 20.4366 23 21 21.8333 21Z"
                fill="#2A2E33"
            />
            <path
                d="M21.8333 9C20.6667 9 10.7256 9 10.7256 9C9 9 9 9 9 8C9 7 9 7 10.7256 7C10.7256 7 20.6667 7 21.8333 7C23 7 23 7.56338 23 8C23 8.43662 23 9 21.8333 9Z"
                fill="#2A2E33"
            />
            <path
                d="M14.5 17C14 17 9.73953 17 9.73953 17C9 17 9 17 9 14C9 11 9 11 9.73953 11C9.73953 11 14 11 14.5 11C15 11 15 12.6901 15 14C15 15.3099 15 17 14.5 17Z"
                fill="#2A2E33"
            />
            <path
                d="M21.5833 13C21.1667 13 17.6163 13 17.6163 13C17 13 17 13 17 12C17 11 17 11 17.6163 11C17.6163 11 21.1667 11 21.5833 11C22 11 22 11.5634 22 12C22 12.4366 22 13 21.5833 13Z"
                fill="#2A2E33"
            />
            <path
                d="M21.5833 17C21.1667 17 17.6163 17 17.6163 17C17 17 17 17 17 16C17 15 17 15 17.6163 15C17.6163 15 21.1667 15 21.5833 15C22 15 22 15.5634 22 16C22 16.4366 22 17 21.5833 17Z"
                fill="#2A2E33"
            />
        </svg>
    );
}
