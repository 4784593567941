import React from 'react';
// Import from dependencies
import { Button } from 'antd';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import {
    verificationStatus as verificationStatusAtom,
    activeAppLanguage as activeAppLanguageAtom,
    appBgAll as appBgAllAtom,
    appBgIndex as appBgIndexAtom,
} from '../../../recoil/atoms';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Import utils
import { SUPPORTED_APP_LANGUAGES } from '../../../exports/constants';
import { TRIM_LANGUAGE_ABBR } from '../../../exports/functions';
// Import components
import BackdropOverlay from '../../Common/BackdropOverlay/BackdropOverlay';
// Import icon components
import SettingsIcon from '../../../assets/images/svg/SettingsIcon';
import NotepadIconOutlined from '../../../assets/images/svg/NotepadIconOutlined';
import CalculatorIconFilled from '../../../assets/images/svg/CalculatorIconFilled';
import DoorArrowIconOutlined from '../../../assets/images/svg/DoorArrowIconOutlined';
import BitcoinIconOutlined from '../../../assets/images/svg/BitcoinIconOutlined';
import { useSwipeable } from 'react-swipeable';

export default function SideDrawerNav(props) {
    const handlers = useSwipeable({
        onSwiped: eventData => {
            if (eventData.dir == 'Right') {
                console.log(props.visible);
                props.toggleSideDrawerNavVisibility(false);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });
    // Global states
    const setVerificationStatus = useSetRecoilState(verificationStatusAtom);
    const [activeAppLanguage, setActiveAppLanguage] = useRecoilState(
        activeAppLanguageAtom
    );
    const appBgAll = useRecoilValue(appBgAllAtom);
    const [appBgIndex, setAppBgIndex] = useRecoilState(appBgIndexAtom);

    // History
    let history = useHistory();
    // i8n
    const { t, i18n } = useTranslation();
    // Logout
    const logout = () => {
        localStorage.clear();
        setVerificationStatus('unauthorized');
        history.replace('/auth/start/home');
    };
    /* Change platform language */
    const changeAppLanguage = lng => {
        // Set active language in local state
        setActiveAppLanguage(lng);
        // Change language
        i18n.changeLanguage(lng);
    };

    // Redirect to Settings page
    const redirectToSettingsPage = () => {
        history.replace({
            pathname: '/user/profile',
            state: window.location.pathname || '/',
        });
        props.toggleSideDrawerNavVisibility();
    };

    // On bg change click
    const onBgChangeClick = i => {
        setAppBgIndex(i);
        localStorage.setItem('APP_BG_INDEX', i);
        props.toggleSideDrawerNavVisibility();
    };

    // On component mount
    React.useEffect(() => {
        const lng = localStorage.getItem('i18nextLng');
        setActiveAppLanguage(lng);
    }, [setActiveAppLanguage]);
    return (
        <BackdropOverlay
            className="backdrop-overlay--side-drawer-nav"
            visible={props.visible}
            closeIcon={false}
            onOverlayClose={props.toggleSideDrawerNavVisibility}
        >
            <div
                {...handlers}
                className="side-drawer-nav"
                onClick={e => e.stopPropagation()}
            >
                <div className="side-drawer-nav__header">
                    <div className="side-drawer-nav__header-left">
                        <Button
                            className="ant-btn-icon ant-btn-icon--left ant-btn-squared"
                            icon={<SettingsIconBtn />}
                            onClick={redirectToSettingsPage}
                        >
                            {t('mainNav.settings')}
                        </Button>
                    </div>
                    <div className="side-drawer-nav__header-right">
                        {SUPPORTED_APP_LANGUAGES.map(lng => (
                            <Button
                                key={lng}
                                className="ant-btn-squared"
                                type={
                                    lng ===
                                    TRIM_LANGUAGE_ABBR(activeAppLanguage)
                                        ? 'primary'
                                        : 'default'
                                }
                                onClick={() => changeAppLanguage(lng)}
                            >
                                {lng}
                            </Button>
                        ))}
                    </div>
                </div>

                <div className="side-drawer-nav__body">
                    <div className="side-drawer-nav__body-each">
                        <div className="side-drawer-nav__body-header">
                            App Background
                        </div>
                        <div className="side-drawer-nav__body-content">
                            <div className="side-drawer-nav__bg-selection">
                                <ul className="side-drawer-nav__bg-selection-list clean">
                                    {appBgAll.map((bg, i) => (
                                        <li
                                            key={i}
                                            style={{
                                                backgroundImage: `url(${bg.url})`,
                                            }}
                                            onClick={() => onBgChangeClick(i)}
                                            className={
                                                appBgIndex === i
                                                    ? 'side-drawer-nav__bg-selection-item side-drawer-nav__bg-selection-item--active'
                                                    : 'side-drawer-nav__bg-selection-item'
                                            }
                                        ></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="side-drawer-nav__body-each">
                        <div className="side-drawer-nav__body-header">
                            My Apps
                        </div>
                        <div className="side-drawer-nav__body-content">
                            <div
                                className="side-drawer-nav__body-content-each"
                                onClick={props.handleUniversalNotepadOpen}
                            >
                                <div className="side-drawer-nav__body-content-each-icon">
                                    <NotepadIconOutlined />
                                </div>
                                <div className="side-drawer-nav__body-content-each-label">
                                    Notepad
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="side-drawer-nav__footer">
                    <Button
                        className="ant-btn-icon ant-btn-icon--left ant-btn-squared"
                        icon={<DoorArrowIconOutlined />}
                        onClick={logout}
                    >
                        {t('mainNav.logout')}
                    </Button>
                </div>
            </div>
        </BackdropOverlay>
    );
}

const SettingsIconBtn = function () {
    return (
        <span className="icon-wrapper">
            <SettingsIcon />
        </span>
    );
};
