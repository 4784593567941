import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Import components
import GetFileTypeIcon from '../../../Misc/GetFileTypeIcon/GetFileTypeIcon';
// Import icon components
import CloseIconRound from '../../../../assets/images/svg/CloseIconRound';

export default function FilePreview(props) {
    let preview;
    const fileType = props.file.fileType || props.file.file_type;
    const fileName = props.file.name || props.file.title;
    if (fileType === 'image')
        preview = (
            <img
                src={
                    props.file.file_url
                        ? `${process.env.REACT_APP_STORAGE_FILES}/${props.file.file_url}`
                        : props.file.base64
                }
                alt={props.file.name}
            />
        );
    else preview = <GetFileTypeIcon fileType={fileType} />;

    // Spinner
    const spinner = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <Spin indicator={spinner} spinning={props.loading || false}>
            <div
                className="files-upload__preview files-upload__block"
                onClick={props.onClick}
                style={{ cursor: props.pointer ? 'pointer' : 'default' }}
            >
                <div
                    className="files-upload__preview-remove"
                    onClick={props.onFileRemove}
                >
                    <CloseIconRound />
                </div>
                <div className="files-upload__preview-content">
                    <div className="files-upload__preview-icon">{preview}</div>
                    <div className="files-upload__preview-title">
                        {fileName}
                    </div>
                </div>
            </div>
        </Spin>
    );
}
