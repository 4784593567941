import React from 'react';
// Import assets
import ResidellySingleLogo from '../svg/residelly-single-logo.svg';
import LogoAltSrc from '../svg/logo.svg';

export default function LogoAlt() {
    return(
        <div className="app-logos" style={{display: "flex", alignItems: "center"}}>
            <img className="icon-logo" src={LogoAltSrc} alt="logo" />
            {/* <img className="text-logo" src={ResidellySingleLogo} alt="logo" /> */}
        </div>
    );
}
