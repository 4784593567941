import React from 'react';
// Import icon components
import FileTypeImageIcon from '../../../assets/images/svg/FileTypeImageIcon';
import FileTypeAudioIcon from '../../../assets/images/svg/FileTypeAudioIcon';
import FileTypeVideoIcon from '../../../assets/images/svg/FileTypeVideoIcon';
import FileTypePdfIcon from '../../../assets/images/svg/FileTypePdfIcon';
import FileTypePresentationIcon from '../../../assets/images/svg/FileTypePresentationIcon';
import FileTypeWordIcon from '../../../assets/images/svg/FileTypeWordIcon';
import FileTypeSpreadsheetIcon from '../../../assets/images/svg/FileTypeSpreadsheetIcon';

export default function GetFileTypeIcon(props) {
    let preview;
    switch (props.fileType) {
        case 'image':
            preview = <FileTypeImageIcon />;
            break;
        case 'audio':
            preview = <FileTypeAudioIcon />;
            break;
        case 'video':
            preview = <FileTypeVideoIcon />;
            break;
        case 'pdf':
            preview = <FileTypePdfIcon />;
            break;
        case 'presentation':
            preview = <FileTypePresentationIcon />;
            break;
        case 'word':
            preview = <FileTypeWordIcon />;
            break;
        case 'spreadsheet':
            preview = <FileTypeSpreadsheetIcon />;
            break;
        default:
            preview = '';
    }
    return preview;
}
