import React from 'react';

export default function GetStartedMailOutlined() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.625 6.96875V14.6875C17.625 15.3174 17.3748 15.9215 16.9294 16.3669C16.484 16.8123 15.8799 17.0625 15.25 17.0625H3.375C2.74511 17.0625 2.14102 16.8123 1.69562 16.3669C1.25022 15.9215 1 15.3174 1 14.6875V6.375C1 5.74511 1.25022 5.14102 1.69562 4.69562C2.14102 4.25022 2.74511 4 3.375 4H12.2812"
				stroke="#979797"
				strokeWidth="1.8"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.25 4H20"
				stroke="#979797"
				strokeWidth="1.8"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.375 7.5625L9.3125 11.125L15.25 7.5625"
				stroke="#979797"
				strokeWidth="1.8"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
