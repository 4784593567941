/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import React from 'react';
// Import from dependencies

// Import components
import MainNavLogo from './MainNavLogo/MainNavLogo';
import MainNavLinks from './MainNavLinks/MainNavLinks';
import MainNavSearch from './MainNavSearch/MainNavSearch';
import MainNavUser from './MainNavUser/MainNavUser';

export default function MainNav(props) {
    return (
        <>
            <nav className="main-nav">
                <div className="main-nav__inner">
                    <MainNavLogo toggleMainNavDrawerVisibility={props.toggleMainNavDrawerVisibility} />
                    <MainNavLinks />
                    <div className='main-nav-right'>
                        <MainNavSearch
                            handlePropertiesSearch={props.handlePropertiesSearch}
                        />
                        <MainNavUser
                            toggleSideDrawerNavVisibility={
                                props.toggleSideDrawerNavVisibility
                            }
                        />
                    </div>
                </div>
            </nav>
        </>
    );
}
