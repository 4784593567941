import moment from 'moment';

/** Find out if property is new **/
export const isPropertyNew = createdAt => {
    const propertyCreatedAt = moment(createdAt);
    const timeNow = moment();
    const ageOfPropertyInMilliseconds = timeNow.diff(propertyCreatedAt);
    const thresholdInMilliseconds = 12 * 60 * 60 * 1000;

    return ageOfPropertyInMilliseconds < thresholdInMilliseconds;
};
