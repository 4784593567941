import React, { useEffect, useState } from 'react';
import { Tabs, Menu } from 'antd';
import { useLocation } from 'react-router';
import IconConnection from '../../assets/images/svg/channel-icons/IconConnection';
import IconSpotlight from '../../assets/images/svg/channel-icons/IconSpotlight';
import IconIntention from '../../assets/images/svg/channel-icons/IconIntention';
import IconExpression from '../../assets/images/svg/channel-icons/IconExpression';
import IconVibration from '../../assets/images/svg/channel-icons/IconVibration';
import resAxios from '../../exports/resAxios';

import { useRecoilValue } from 'recoil';
import { userDetails as userDetailsAtom } from '../../recoil/atoms';


const tabItems = [
    {
        title: "Spotlight",
        icon: <IconSpotlight />,
        content: "https://player.viloud.tv/embed/channel/3fe11be1460ff77086edaffb8b3943df?autoplay=1&volume=1&controls=1&title=1&share=1&open_playlist=1&random=0"
    },
    {
        title: "Intention",
        icon: <IconIntention />,
        content: "https://player.viloud.tv/embed/channel/c57f8798b0393f9f890bbe2bde33322d?autoplay=1&volume=1&controls=1&title=1&share=1&open_playlist=0&random=0"
    },
    {
        title: "Expression",
        icon: <IconExpression />,
        content: "https://player.viloud.tv/embed/channel/9bf6defb475fb0c60a9b33637da2d420?autoplay=1&volume=1&controls=1&title=1&share=1&open_playlist=0&random=0"
    },
    {
        title: "Connection",
        icon: <IconConnection />,
        content: "https://player.viloud.tv/embed/channel/52ea97f519d07c083bffbf49dc173b6b?autoplay=1&volume=1&controls=1&title=1&share=1&open_playlist=0&random=0"
    },
    {
        title: "Vibration",
        icon: <IconVibration />,
        content: "https://player.viloud.tv/embed/channel/46ba750219625efd37efbdd41c8bf866?autoplay=1&volume=1&controls=1&title=1&share=1&open_playlist=0&random=0"
    },
]

export default function OnAir() {
    const { TabPane } = Tabs;
    const { SubMenu, Item } = Menu;
    const [activeTab, setActiveTab] = useState("Spotlight");
    const userDetails = useRecoilValue(userDetailsAtom);
    const [viewerCount, setViewerCount] = useState();

    function callback(tabName) {
        setActiveTab(tabName);
    }

    const sendActiveViewerStatus = () => {
        resAxios.post(`${process.env.REACT_APP_HOST}/v1/stream-viewers`, {
            "userId": userDetails.id,
            "streamingAt": Date.now(),
            "streamingChannel": activeTab
        })
            .then(({ data }) => {
                setViewerCount(data)
                clearInterval(window.viewerCountInterval);
                window.viewerCountInterval = setInterval(() => {
                    sendActiveViewerStatus();
                }, 5000);
            })
            .catch()
    }

    useEffect(() => {
        console.log(activeTab)
        sendActiveViewerStatus();
    }, [activeTab])



    return (
        <div className="stream">
            <Tabs destroyInactiveTabPane={true} activeKey={activeTab} centered onChange={callback} className="stream-tab" type="card">
                <TabPane disabled key="viewer-counter" tab={
                    <span className="viewer-counter">
                        VIEWERS: {viewerCount}
                    </span>
                }>
                </TabPane>
                {
                    tabItems.map((item, key) => (
                        <TabPane key={item.title} tab={
                            <span className="channel-icon-span">
                                {item.icon}
                                <span className="channel-name-span">{item.title}</span>
                            </span>
                        }>
                            <div className="iframe-container">
                                <iframe
                                    src={item.content}
                                    frameborder='0'
                                    allow='autoplay'
                                    allowfullscreen>
                                </iframe>

                            </div>
                        </TabPane>
                    ))
                }

            </Tabs>

            <div className="tab-list">
                <p className="channel-list-heading">
                {viewerCount} watching {activeTab}
                </p>
                <Menu defaultSelectedKeys={['0']}>
                    {
                        tabItems.map((item, key) => (
                            <Item defaultChecked onClick={() => setActiveTab(item.title)} key={key} icon={item.icon}>
                                <span>{item.title}</span>
                            </Item>
                        ))
                    }
                </Menu>
            </div>


        </div>
    );
}
