import React from 'react';
// Import Icon components
import CloseIconRound from '../../../assets/images/svg/CloseIconRound';

export default function BackdropOverlay(props) {
    if (props.visible) {
        const overlayClassName = ['backdrop-overlay'];
        if (props.className) overlayClassName.push(props.className);
        return (
            <>
                <div
                    className={overlayClassName.join(' ')}
                    onClick={
                        props.clickOutsideToClose !== false &&
                        props.onOverlayClose
                    }
                >
                    {props.closeIcon !== false && (
                        <div
                            className="backdrop-overlay__close"
                            onClick={
                                props.clickOutsideToClose === false &&
                                props.onOverlayClose
                            }
                        >
                            <CloseIconRound />
                        </div>
                    )}

                    <div className="backdrop-overlay__body">
                        {props.children}
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}
