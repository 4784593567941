import React from 'react';
// Import from dependencies
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Spin, Form, message } from 'antd';
// Import recoil values
import {
    userDetails as userDetailsAtom,
    savingStatus as savingStatusAtom,
} from '../../../recoil/atoms';
// Import exports
import { PHONE_IS_VALID, EMAIL_IS_VALID } from '../../../exports/functions';
import resAxios from '../../../exports/resAxios';
import { RANDOM_GLOBAL_CITY } from '../../../exports/constants';
// Import components
import ProfileSectionLeft from '../../User/Profile/ProfileSectionLeft/ProfileSectionLeft';
import ProfileSectionSocial from '../../User/Profile/ProfileSectionSocial/ProfileSectionSocial';
import ProfileSectionRight from '../../User/Profile/ProfileSectionRight/ProfileSectionRight';
// Import icon components
import EmailOpenedIcon from '../../../assets/images/svg/EmailOpenedIcon';
import SmileyIcon from '../../../assets/images/svg/SmileyIcon';

let USER_AND_AGENCY_FIELDS_UPDATE_TIMER_ID;
export default function Profile() {
    // Global state
    const [userDetails, setUserDetails] = useRecoilState(userDetailsAtom);
    const setSavingStatus = useSetRecoilState(savingStatusAtom);
    // Local states
    const [userEmail, setUserEmail] = React.useState({
        email: '',
        isValid: false,
    });
    const [emailChangeStatus, setEmailChangeStatus] =
        React.useState('unchanged'); // unchanged || unverified || verified || resend
    const [codeName, setCodeName] = React.useState();

    // User details form instance
    const [userDetailsForm] = Form.useForm();

    // On component mount
    React.useEffect(() => {
        // Set user email in local state
        setUserEmail(prevValues => ({
            ...prevValues,
            email: userDetails.email,
        }));
        // Set user email in User details form field
        userDetailsForm.setFieldsValue({
            user_email: userDetails.email,
        });
    }, [userDetails.email, userDetailsForm]);

    // On email address value change
    const onEmailAddressValueChange = event => {
        const email = event.target.value;
        // Check if user email produces validation error
        setTimeout(() => {
            setUserEmail(prevValues => ({
                ...prevValues,
                email: email,
                isValid:
                    // Return true if both 'email format validation' and 'prev and current value comparison' are true
                    !(userDetails.email === email) &&
                    !userDetailsForm.getFieldError('user_email').length,
            }));
        }, 1);
    };

    // Request user details change
    const requestUserDetailsChange = (
        payload,
        endpoint = 'me',
        method = 'patch'
    ) => {
        resAxios[method](
            `${process.env.REACT_APP_HOST}/v1/${endpoint}`,
            payload
        )
            .then(res => {
                // Update saving status
                setSavingStatus('saved');
                // Update in local storage
                let userDetails = JSON.parse(localStorage.getItem('RES_USER'));
                userDetails = JSON.stringify({
                    ...userDetails,
                    ...payload,
                });
                localStorage.setItem('RES_USER', userDetails);
            })
            .catch(err => {
                console.log(err, 'Failed to update user');
                // Update saving status
                setSavingStatus('failed');
            });
    };

    // On user details form values change
    let tempValue;
    const onUserDetailsFormValuesChange = (event, inputSelectInfo = {}) => {
        let name, value;
        if (inputSelectInfo.inputType === 'select') {
            name = inputSelectInfo.name;
            value = event;
        }
        else if(inputSelectInfo.inputType === 'switch') {
            name = inputSelectInfo.name;
            value = event;
        } else {
            name = event.target.name;
            value = event.target.value;
        }
        // Check if value is valid if phone number
        if (name === 'phone' || name === 'agency_phone') {
            if (!PHONE_IS_VALID(value)) {
                return;
            }
        }
        // Update values in global state
        setUserDetails(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
        // Validate agency email
        if (name === 'agency_email') {
            if (!EMAIL_IS_VALID(value)) {
                setSavingStatus('invalid_email');
                return;
            }
        }
        // Update saving status to Saving
        setSavingStatus('saving');
        // Defer request to update values
        tempValue = value;
        if (value === tempValue) {
            clearTimeout(USER_AND_AGENCY_FIELDS_UPDATE_TIMER_ID);
        }
        USER_AND_AGENCY_FIELDS_UPDATE_TIMER_ID = setTimeout(() => {
            const payload = { [name]: value };
            if (name === 'username') {
                requestUserDetailsChange(payload, 'me/update-username', 'post');
            } else {
                requestUserDetailsChange(payload);
            }
        }, 500);
    };

    // Email address submit handler
    const emailAddressRequestChangeHandler = emailChangeStatusPara => {
        emailChangeStatusPara === 'loading'
            ? setEmailChangeStatus('loading')
            : setEmailChangeStatus('resend');
        const random = RANDOM_GLOBAL_CITY;
        setCodeName(random);
        resAxios
            .post(`${process.env.REACT_APP_HOST}/v1/send-email-update-link`, {
                codeName: random,
                email: userEmail.email,
            })
            .then(response => {
                setEmailChangeStatus('unverified');
                emailChangeStatusPara === 'loading'
                    ? message.success('Sent')
                    : message.success('Resent');
            })
            .catch(error => {
                setEmailChangeStatus('unchanged');
                message.error('Failed to send email update link');
            });
    };

    // Email address update link resend
    const emailAddressRequestChangeResendHandler = () => {
        setEmailChangeStatus('resend');
        emailAddressRequestChangeHandler('resend');
    };

    // Variables
    let emailAddressHelperMessage;

    switch (emailChangeStatus) {
        case 'unchanged':
            emailAddressHelperMessage = null;
            break;
        case 'unverified':
            emailAddressHelperMessage = (
                <>
                    <small className="user__profile-email-helper">
                        <span className="user__profile-email-helper-icon">
                            <EmailOpenedIcon />
                        </span>
                        We've sent you an email with the following code:&nbsp;
                        <span className="color-primary text-uppercase">
                            {codeName}
                        </span>
                        , to
                        <span className="color-secondary">
                            &nbsp;{userDetails.email}
                        </span>
                        .
                    </small>
                    <div
                        className="user__profile-email-resend"
                        onClick={emailAddressRequestChangeResendHandler}
                    >
                        Click here to resend
                    </div>
                </>
            );
            break;
        case 'resend':
            emailAddressHelperMessage = (
                <div className="user__profile-email-resend user__profile-email-resend--sending">
                    <Spin /> <span className="sending">Please wait ...</span>
                </div>
            );
            break;
        case 'verified':
            emailAddressHelperMessage = (
                <small className="user__profile-email-helper user__profile-email-helper--success">
                    Your email address has been changed successfully
                    <span className="user__profile-email-helper-icon user__profile-email-helper-icon--right">
                        <SmileyIcon />
                    </span>
                </small>
            );
            break;
        default:
            emailAddressHelperMessage = null;
    }
    return (
        <div className="user__profile">
            <div className="user__profile-section-row">
                <div className="user__profile-section-col">
                    <div className="user__profile-section">
                        <div className="user__profile-section-inner">
                            <ProfileSectionLeft
                                userEmail={userEmail}
                                setUserEmail={setUserEmail}
                                emailChangeStatus={emailChangeStatus}
                                setEmailChangeStatus={setEmailChangeStatus}
                                setCodeName={setCodeName}
                                userDetailsForm={userDetailsForm}
                                onUserDetailsFormValuesChange={
                                    onUserDetailsFormValuesChange
                                }
                                onEmailAddressValueChange={
                                    onEmailAddressValueChange
                                }
                                emailAddressRequestChangeHandler={
                                    emailAddressRequestChangeHandler
                                }
                            />
                            <ProfileSectionSocial
                                onUserDetailsFormValuesChange={
                                    onUserDetailsFormValuesChange
                                }
                            />
                        </div>
                        <div className="user__profile-email-helper-message">
                            {emailAddressHelperMessage || null}
                        </div>
                    </div>
                </div>
                {/* <div className="user__profile-section-col">
                    <div className="user__profile-section">
                        <div className="user__profile-section-inner">
                            <ProfileSectionRight
                                onUserDetailsFormValuesChange={
                                    onUserDetailsFormValuesChange
                                }
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
