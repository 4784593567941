import React from 'react';

export default function Roadmap() {
	return (
		<div className="roadmap">
			<iframe
				src="https://app.productstash.io/residelly"
				style={{
					width: '100%',
					minHeight: '100vh',
					border: '0',
					outline: '0',
				}}
				title="Roadmap"
				frameborder="0"
			></iframe>
		</div>
	);
}
