import React from 'react';
// Import from dependencies
import { message } from 'antd';
import axios from 'axios';
// Import styles
import './Auth.css';
// Import exports
import { EMAIL_IS_VALID } from '../../exports/functions';
import { RANDOM_GLOBAL_CITY } from '../../exports/constants';
// Import components
import GetStarted from '../../components/Auth/GetStarted/GetStarted';
import AwaitingConfirmation from '../../components/Auth/AwaitingConfirmation/AwaitingConfirmation';

export default function Auth() {
    // Local states
    const [emailAddress, setEmailAddress] = React.useState('');
    const [emailAddressIsValid, setEmailAddressIsValid] = React.useState(false);
    const [verificationText, setVerificationText] = React.useState('');
    const [magicLinkSentStatus, setMagicLinkSentStatus] = React.useState('');

    // Send magic link
    const sendMagicLink = event => {
        event.preventDefault();
        setMagicLinkSentStatus('sending');
        const random = RANDOM_GLOBAL_CITY;
        setVerificationText(random);
        axios
            .post(`${process.env.REACT_APP_HOST}/v1/send-magic-link`, {
                codeName: random,
                email: emailAddress,
            })
            .then(response => {
                // Set email sent
                setMagicLinkSentStatus('sent');
            })
            .catch(error => {
                setTimeout(() => {
                    message.error('Failed to send magic link');
                    setMagicLinkSentStatus('');
                }, 400);
            });
    };

    // Set email address
    const updateEmailAddress = event => {
        const email_address = event.target.value;
        setEmailAddress(email_address);
        setEmailAddressIsValid(EMAIL_IS_VALID(email_address));
    };

    // eslint-disable-next-line
    {
        if (magicLinkSentStatus === 'sent') {
            return (
                <AwaitingConfirmation
                    emailAddress={emailAddress}
                    verificationText={verificationText}
                    magicLinkSentStatus={magicLinkSentStatus}
                />
            );
        } else {
            return (
                <GetStarted
                    sendMagicLink={sendMagicLink}
                    emailAddress={emailAddress}
                    emailAddressIsValid={emailAddressIsValid}
                    updateEmailAddress={updateEmailAddress}
                    magicLinkSentStatus={magicLinkSentStatus}
                />
            );
        }
    }
}
