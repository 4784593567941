import { useState, useEffect } from 'react';
// Import from dependencies
import { Select, Collapse, Typography, Radio } from 'antd';
// Import from exports
import { STORY_CATEGORIES, COUNTRIES } from '../../../exports/constants';
// Import assets
import CheckIcon from '../../../assets/images/svg/CheckIcon';
import NavIconText from '../../../assets/images/svg/NavIconText';
import { useLocation } from 'react-router';

import PaypalIcon from '../../../assets/images/svg/paypal-icon.svg';
import CreditcardIcon from '../../../assets/images/svg/credit-card-icon.svg';
import BitcoinIcon from '../../../assets/images/svg/bitcoin-icon.svg';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';

// category svg icon files
import iconCivilisation from '../../../assets/images/svg/icon-civilisation.svg';
import iconConnection from '../../../assets/images/svg/icon-connection.svg';
import iconEngineering from '../../../assets/images/svg/icon-engineering.svg';
import iconHealth from '../../../assets/images/svg/icon-health.svg';
import iconScience from '../../../assets/images/svg/icon-science.svg';
import iconEnvironment from '../../../assets/images/svg/icon-environment.svg';

import { appBg as appBgAtom } from '../../../recoil/atoms';

const { Title } = Typography;

export default function StoriesSidebarLeft(props) {
    let location = useLocation();
    const appBg = useRecoilValue(appBgAtom);

    const categoryIcons = [
        iconCivilisation,
        iconConnection,
        iconEngineering,
        iconEnvironment,
        iconHealth,
        iconScience,
    ];
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategoryValue, setSelectedSubCategoryValue] =
        useState('');
    const [selectedSubCategories, setSelectedSubCategories] = useState();

    const handleCategoryChange = e => {
        setSelectedCategory(e.target.value);
        setSelectedSubCategories(STORY_CATEGORIES[e.target.value]);
    };

    // useEffect(()=>{
    //     changeBackground();
    // }, [appBg]);

    const CountrySelect = () => {
        return (
            <>
                <Select
                    showSearch
                    allowClear
                    style={{
                        width: 200,
                        background: 'rgba(33, 150, 243, 0.1)',
                    }}
                    placeholder="All languages"
                    optionFilterProp="children"
                    onChange={val => props.fetchStoriesBy('country', val)}
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    showArrow
                >
                    <Select.Option value={''}>All languages</Select.Option>
                    {COUNTRIES.map(country => (
                        <Select.Option key={country.code} value={country.name}>
                            {country.name}
                        </Select.Option>
                    ))}
                </Select>
            </>
        );
    };

    useEffect(() => {
        const bottomNavPaths = ['my-stories', 'drafts', 'saved'];
        // if current path name ends with any of the item of bottomNavPaths then,
        // clear selection of top bar radio button group
        if (
            bottomNavPaths.some(bottomNavPath =>
                location.pathname.endsWith(bottomNavPath)
            )
        ) {
            setSelectedCategory('');
            setSelectedSubCategoryValue('');
            setSelectedSubCategories();
        }
    }, [location]);

    const handleBitCoinPayment = () => {
        window.open(
            'https://commerce.coinbase.com/checkout/ff4e2e96-172e-4380-aef3-cb3e8a73e0a1',
            '_blank'
        );
    };

    return (
        <div className="sidebar-left-container">
            {/* Donate buttons */}
            <div className="donate-buttons-wrapper">
                <span>Donate via</span>
                <button className="button-paypal">
                    <img src={PaypalIcon} />
                </button>
                <button className="button-creditcard">
                    <img src={CreditcardIcon} />
                </button>
                <button
                    onClick={handleBitCoinPayment}
                    className="button-bitcoin"
                >
                    <img src={BitcoinIcon} />
                </button>
            </div>
            <div className="sidebar-left-scrollable">
                <div
                    id="stories-sidebar-left"
                    className="story-sidebar story-sidebar--left"
                >
                    {/* story category selection bar, visible only on mobile screen */}
                    <div className="story-topbar">
                        <div className="story-topbar__titlebar">
                            <Title level={4}>Stories</Title>
                            <CountrySelect />
                        </div>
                        <div className="story-topbar__body">
                            <Radio.Group
                                defaultValue="all"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                className="story-topbar__categories"
                            >
                                <Radio.Button
                                    onClick={() => props.fetchStoriesBy('all')}
                                    className="category-btn"
                                    value="all"
                                >
                                    <span
                                        className="all-story-icon"
                                        type="text"
                                    >
                                        <NavIconText />
                                    </span>
                                    <span className="category-title">
                                        All stories
                                    </span>
                                </Radio.Button>

                                {STORY_CATEGORIES.map((category, i) => (
                                    <Radio.Button
                                        key={i}
                                        className="category-btn"
                                        value={i}
                                    >
                                        {/* <span className="category-icon" type="text">
                                            {collapseHeaderIcons[i]}
                                        </span> */}
                                        <img
                                            className="category-icon"
                                            src={categoryIcons[i]}
                                        />
                                        <span className="category-title">
                                            {category.category}
                                        </span>
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                            <Radio.Group
                                value={selectedSubCategoryValue}
                                className="story-topbar__subcategories"
                                onChange={e =>
                                    setSelectedSubCategoryValue(e.target.value)
                                }
                            >
                                {selectedSubCategories
                                    ? selectedSubCategories.subcategories.map(
                                          (cat, i) => (
                                              <Radio.Button
                                                  onChange={() =>
                                                      props.fetchStoriesBy(
                                                          'category',
                                                          cat
                                                      )
                                                  }
                                                  className="subcategory-btn"
                                                  key={i}
                                                  value={cat}
                                              >
                                                  {cat}
                                              </Radio.Button>
                                          )
                                      )
                                    : null}
                            </Radio.Group>
                        </div>
                    </div>

                    {/* story category sidebar, visible on desktop screen */}
                    <div className="story-sidebar__nav stories-feed-block stories-feed-block--sidebar">
                        <div className="story-sidebar__country story-sidebar__block-padded">
                            <CountrySelect />
                        </div>
                        <div className="story-sidebar__categories">
                            <div className="story-sidebar__block-padded">
                                <label
                                    className="story-sidebar__categories-checkbox-master"
                                    htmlFor="story-sidebar__categories-checkbox-master"
                                >
                                    All categories
                                    <input
                                        id="story-sidebar__categories-checkbox-master"
                                        type="radio"
                                        name="categories_check"
                                        onChange={() =>
                                            props.fetchStoriesBy('all')
                                        }
                                        // checked
                                    />
                                </label>
                            </div>
                            <Collapse expandIconPosition="right">
                                {STORY_CATEGORIES.map((category, i) => (
                                    <Collapse.Panel
                                        key={i}
                                        header={
                                            <AccordionHeader
                                                iconImgSrc={categoryIcons[i]}
                                                title={category.category}
                                            />
                                        }
                                    >
                                        <div className="story-sidebar__collapse-body">
                                            <ul className="clean">
                                                {category.subcategories.map(
                                                    (cat, i) => {
                                                        const random =
                                                            Math.random();
                                                        return (
                                                            <li key={i}>
                                                                <label
                                                                    htmlFor={
                                                                        random
                                                                    }
                                                                >
                                                                    {cat}
                                                                    <input
                                                                        id={
                                                                            random
                                                                        }
                                                                        name="categories_check"
                                                                        type="radio"
                                                                        onChange={() =>
                                                                            props.fetchStoriesBy(
                                                                                'category',
                                                                                cat
                                                                            )
                                                                        }
                                                                        hidden
                                                                    />
                                                                    <span className="story-sidebar__collapse-check">
                                                                        <CheckIcon />
                                                                    </span>
                                                                </label>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </div>
                                    </Collapse.Panel>
                                ))}
                            </Collapse>
                        </div>
                    </div>
                </div>

                <div className="image-credit-box">
                    {appBg && appBg.credit && appBg.credit.label ? (
                        <a
                            href={appBg.credit.link}
                            className="image-credit-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {appBg.credit.label}
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export function AccordionHeader({ iconImgSrc, title }) {
    return (
        <div className="story-sidebar__collapse-header">
            <img src={iconImgSrc} alt={title} />
            {title}
        </div>
    );
}
