import React from 'react';
// Import dependencies
import { Button, Tooltip, message } from 'antd';
// Import assets
import Twitter from '../../../../../../assets/images/social/twitter-icon-square-rounded.svg';
import WhatsApp from '../../../../../../assets/images/social/whatsapp-icon-square-rounded.svg';
import Facebook from '../../../../../../assets/images/social/fb-icon-square-rounded.svg';
import LinkedIn from '../../../../../../assets/images/social/linkedin-icon-square-rounded.svg';
import Instagram from '../../../../../../assets/images/social/insta-icon-square-rounded.svg';
import Google from '../../../../../../assets/images/social/google-icon-square-rounded.svg';

export default function StorySharePopoverContent(props) {
    // Variables and constants
    const storyLink = `${window.location.host}/stories/${props.storyId}`;

    const socialIcons = [
        {
            icon: Twitter,
            title: 'Twitter',
        },
        {
            icon: WhatsApp,
            title: 'WhatsApp',
        },
        {
            icon: Facebook,
            title: 'Facebook',
        },
        {
            icon: LinkedIn,
            title: 'LinkedIn',
        },
        {
            icon: Instagram,
            title: 'Instagram',
        },
        {
            icon: Google,
            title: 'Google',
        },
    ];

    // Copy story link
    const copyStoryLink = () => {
        /* Get the text field */
        const link = storyLink;
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(link);
        // Hide popover
        props.setStorySharePopoverVisibility(false);
        // Display toast message
        message.success('Copied to clipboard');
    };
    return (
        <div className="story-share-popover-content">
            <div className="story-share-popover-content__title">
                Share this story
            </div>
            <div className="story-share-popover-content__icons">
                {socialIcons.map((icon, i) => (
                    <Tooltip key={i} title={icon.title}>
                        <img src={icon.icon} alt={icon.title} />
                    </Tooltip>
                ))}
            </div>
            <div id="test" className="story-share-popover-content__copyable">
                {storyLink}
            </div>
            <Button type="primary" onClick={copyStoryLink}>
                Copy link
            </Button>
        </div>
    );
}
