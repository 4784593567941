import React from 'react';
// Import from dependencies
import { Button, Input, message } from 'antd';
import { useLocation } from 'react-router';
import axios from 'axios';
import { loadedStories as loadedStoriesAtom } from '../../../../recoil/atoms';
import { useSetRecoilState } from 'recoil';
import algoliasearch from 'algoliasearch';
// Import icons
import SearchIcon from '../../../../assets/images/svg/SearchIcon';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';

let SEARCH_STORY_TIMER_ID;
export default function MainNavLinks(props) {
    // Local states
    const [searchIndex, setSearchIndex] = React.useState('stories');
    // Global states
    const setLoadedStories = useSetRecoilState(loadedStoriesAtom);

    // Location
    const { pathname } = useLocation();

    // Algolia client
    const searchClient = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID,
        process.env.REACT_APP_ALGOLIA_SEARCH_KEY
    );
    
    const index = searchClient.initIndex('stories');
    // Handle search
    const handleSearch = e => {
        const searchString = e.target.value;
        setLoadedStories({
            data: [],
            count: null,
        });
        index
            .search(searchString)
            .then(res => {
                const hits = res.hits;

                setLoadedStories({
                    data: hits,
                    count: hits.length,
                });
            })
            .catch((err) => {
                console.log(err)
            });
    };


    const mainNav = document.getElementsByClassName("main-nav")[0];
    let searchBarState = false;

    const toggleSearchBar = (state) => {
        document.querySelector(".main-nav__search-input input").value = ""
        searchBarState = state
        state ? mainNav.classList.add("main-nav__toggle-searchbar") : mainNav.classList.remove("main-nav__toggle-searchbar")
    }

    return (
        <div className="main-nav__search">
            <Button onClick={() => toggleSearchBar(true)} size="large" className="search-button" type="text" icon={<SearchOutlined />} />
            <form className='search-form' onSubmit={handleSearch}>
                <Input
                    onBlur={() => toggleSearchBar(false)}
                    className="main-nav__search-input"
                    size="large"
                    placeholder="Search Reload"
                    prefix={
                        <>
                            <SearchIcon />
                            <CloseCircleOutlined className="closeSearchIcon" onClick={() => toggleSearchBar(false)} />
                        </>
                    }
                    onChange={handleSearch}
                    allowClear
                />
            </form>
        </div>
    );
}
