import React, { useState, useEffect } from 'react';
// Import from recoil
import {
    useRecoilState,
    useResetRecoilState,
    useRecoilValue,
    useSetRecoilState,
} from 'recoil';
import {
    activeStory as activeStoryAtom,
    story as storyAtom,
    storyAction as storyActionAtom,
    activeStoryId as activeStoryIdAtom,
    loadedStories as loadedStoriesAtom,
    savingStory as savingStoryAtom,
    userDetails as userDetailsAtom,
    isUpdatingStoryCategory as isUpdatingStoryCategoryAtom,
    storiesCount as storiesCountAtom,
} from '../../../recoil/atoms';
// Import from dependencies
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// import from exports
import resAxios from '../../../exports/resAxios';
import {
    STORIES_FILTER_IS_DRAFTS,
    GET_FILE_TYPE,
    GET_FILE_EXT,
} from '../../../exports/functions';
// Import components
import StoryCreate from '../../../components/Story/StoryCreate/StoryCreate';
import StoryModal from '../../../components/Story/StoryModal/StoryModal';
import StoriesSidebarLeft from '../../../components/Story/StoriesSidebarLeft/StoriesSidebarLeft';
import StoriesSidebarRight from '../../../components/Story/StoriesSidebarRight/StoriesSidebarRight';
import StoriesContent from '../../../components/Story/StoriesContent/StoriesContent';
import DeleteStoryConfirmationModal from '../../../components/Common/ConfirmationModal/ConfirmationModal';
import StoryMoveToDraftsConfirmationModal from '../../../components/Common/ConfirmationModal/ConfirmationModal';
import StoriesFeedImageLightbox from '../../../components/Common/ImageLightbox/ImageLightbox';
import StoryFilesFilePlayer from '../../../components/Common/FilePlayer/FilePlayer';
import StoryFilesPDFViewer from '../../../components/Common/PDFViewer/PDFViewer';
import StoryParticular from '../../../components/Story/StoryParticular/StoryParticular';

function Stories(props) {
    // Global state
    // Read-only recoil values
    const activeStory = useRecoilValue(activeStoryAtom);
    // Write only recoil values
    const setIsUpdatingStoryCategory = useSetRecoilState(
        isUpdatingStoryCategoryAtom
    );
    const setStoriesCount = useSetRecoilState(storiesCountAtom);
    // Read-write recoil values
    const [storyData, setStoryData] = useRecoilState(storyAtom);
    const [storyAction, setStoryAction] = useRecoilState(storyActionAtom);
    const [activeStoryId, setActiveStoryId] = useRecoilState(activeStoryIdAtom);
    const [loadedStories, setLoadedStories] = useRecoilState(loadedStoriesAtom);
    const setSavingStory = useSetRecoilState(savingStoryAtom);
    const [userDetails, setUserDetails] = useRecoilState(userDetailsAtom);
    // Reset recoil values
    // const resetLoadedStories = useResetRecoilState(loadedStoriesAtom);
    const resetStoryData = useResetRecoilState(storyAtom);
    const resetStoryAction = useResetRecoilState(storyActionAtom);
    const resetActiveStoryId = useResetRecoilState(activeStoryIdAtom);
    const resetStoriesCount = useResetRecoilState(storiesCountAtom);

    // LOCAL STATES
    // Story modal
    const [storyModal, setStoryModal] = React.useState({
        visible: false,
        confirmLoading: false,
        reset: false,
    });
    const [storyFiles, setStoryFiles] = React.useState([]);
    const [storyFilesImageLightbox, setStoryFilesImageLightbox] =
        React.useState({
            visible: false,
            initialIndex: 0,
            images: [],
        });
    const [storyFilesPlayer, setStoryFilesPlayer] = React.useState({
        visible: false,
        file: {},
    });
    const [storyFilesPDFViewer, setStoryFilesPDFViewer] = React.useState({
        visible: false,
        file: null,
    });
    const [showStoryParticular, setShowStoryParticular] = React.useState(false);
    

    // Delete story confirmation modal
    const [deleteStoryConfirmationModal, setDeleteStoryConfirmationModal] =
        useState({
            visible: false,
            confirmLoading: false,
        });
    // Story move to drafts confirmation modal
    const [
        storyMoveToDraftsConfirmationModal,
        setStoryMoveToDraftsConfirmationModal,
    ] = useState({
        visible: false,
        confirmLoading: false,
    });

    // Router history
    let history = useHistory();

    // UTILITY FUNCTIONS
    // Reset story
    const resetStory = () => {
        resetStoryData();
        resetStoryAction();
        resetStoryForm();
        resetActiveStoryId();
    };
    // A bundle of basic functions to delete a story
    const deleteStoryBasics = () => {
        setDeleteStoryConfirmationModalVisibility(false);
        resetStory();
        message.success('Story deleted');
    };
    // Get kebab case of a string
    const getKebabCase = string => {
        let path = string.split(' ').join('-');
        path = path.charAt(0).toLowerCase() + path.slice(1);
        return path;
    };

    // LOCAL STATE MANIPULATIONS
    // Set the visibility of the Story modal
    const setStoryModalVisibility = bool => {
        setStoryModal(prevState => ({
            ...prevState,
            visible: bool,
        }));
    };

    // Set the visibility of the Delete story confirmation modal
    const setDeleteStoryConfirmationModalVisibility = bool => {
        setDeleteStoryConfirmationModal(prevState => ({
            ...prevState,
            visible: bool,
        }));
    };

    // Set the visibility of the Loading icon in OK button of the Story modal
    const setStoryModalConfirmLoading = val => {
        setStoryModal(prevState => ({
            ...prevState,
            confirmLoading: val,
        }));
    };

    // Set the visibility of the Loading icon in OK button of the Delete story confirmation modal
    const setDeleteStoryConfirmLoading = bool => {
        setDeleteStoryConfirmationModal(prevState => ({
            ...prevState,
            confirmLoading: bool,
        }));
    };

    // Set whether to reset the values in the form in Story modal
    const resetStoryForm = () => {
        setStoryModal(prevState => ({
            ...prevState,
            reset: !prevState.reset,
        }));
    };

    // MANIPULATIONS
    // Open modal for new story
    const newStoryModalOpen = () => {
        setStoryModalVisibility(true);
        setStoryAction('new');
    };

    // Open modal to edit a story
    const editStoryModalOpen = storyId => {
        setStoryModalVisibility(true);
        setStoryAction('edit');
        setActiveStoryId(storyId);
    };

    // Delete story confirmation open
    const deleteStoryConfirmationOpen = storyId => {
        setActiveStoryId(storyId);
        setDeleteStoryConfirmationModalVisibility(true);
    };

    // LOGICS
    // Delete a story
    const deleteStoryFromState = storyId => {
        const stories = loadedStories.data.filter(
            story => story.id !== storyId
        );
        setLoadedStories(prevValues => ({
            ...prevValues,
            data: stories,
            count: stories.length,
        }));
    };

    // Add a story
    const addStoryToState = story => {
        setLoadedStories(prevValues => ({
            ...prevValues,
            data: [story, ...prevValues.data],
            count: prevValues.count + 1,
        }));
    };

    // Update a story
    const updateStoryInState = (data, storyId = activeStoryId) => {
        const stories = loadedStories.data.map(story =>
            story.id === storyId ? data : story
        );
        setLoadedStories(prevValues => ({
            ...prevValues,
            data: stories,
        }));
    };

    // EVENTS HANDLERS
    // On denial of the story modal
    const onStoryModalCancel = () => {
        if (storyAction === 'new') {
            setDeleteStoryConfirmationModalVisibility(true);
        }
        setStoryModalVisibility(false);
    };

    // On close of the Story modal
    const onStoryModalClose = () => {
        if (storyAction === 'new') return onStoryModalCancel();
        setStoryModalVisibility(false);
        setStoryModalConfirmLoading(false);
        resetStory();
    };

    // Handle Publish story success
    const handlePublishStorySuccess = (story, action) => {
        setStoryModalVisibility(false);
        setStoryModalConfirmLoading(false);
        let msg;
        if (action === 'publish') {
            addStoryToState({
                ...story,
            });
            msg = 'Published';
        } else {
            msg = 'Saved to drafts';
        }
        message.success(msg);
        resetStory();
    };

    // Upload story files
    const uploadStoryFiles = (story, action) => {
        let storyFilesUploadsCount = 0;
        storyFiles.forEach(file => {
            resAxios
                .post(`${process.env.REACT_APP_HOST}/v1/file/story-doc`, {
                    docType: file.ext,
                })
                .then(res => {
                    const resFilename = res.data.filename;
                    const resUrl = res.data.url;
                    const resPreviewUrl = res.data.previewFile;
                    axios
                        .put(`${resUrl}`, file.blob
                        // , 
                        // {
                        //     headers: {"Access-Control-Allow-Origin": "*"} 
                        // }
                        )
                        .then(res => {
                            const payload = {
                                title: file.name,
                                file_type: file.fileType,
                                file_url: resFilename,
                                preview_url: resPreviewUrl,
                                resource_type: 'stories',
                                resource_id: story.id,
                            };
                            resAxios
                                .post(
                                    `${process.env.REACT_APP_HOST}/v1/media`,
                                    payload
                                )
                                .then(res => {
                                    storyFilesUploadsCount++;
                                    if (
                                        storyFilesUploadsCount ===
                                        storyFiles.length
                                    ) {
                                        handlePublishStorySuccess(
                                            story,
                                            action
                                        );
                                        setStoryFiles([]);
                                    }
                                })
                                .catch(err => {
                                    throw err;
                                });
                        })
                        .catch(err => {
                            throw err;
                        });
                })
                .catch(err => {
                    throw err;
                });
        });
    };

    const handleStoryCreate = (publish = true) => {
        const payload = { ...storyData, published: publish };
        // Send a server request to store the newly created story
        resAxios
            .post(`${process.env.REACT_APP_HOST}/v1/stories`, payload)
            .then(res => {
                // Upload story files
                if (storyFiles.length)
                    uploadStoryFiles(res.data, publish ? 'publish' : 'draft');
                // Handle story publish success
                else
                    handlePublishStorySuccess(
                        res.data,
                        publish ? 'publish' : 'draft'
                    );
                // Update stories count
                if (publish) updateStoriesCount('my_stories');
                else updateStoriesCount('my_drafts');
            })
            .catch(err => {
                console.error(err, 'Publish story error');
                setStoryModalConfirmLoading(false);
                message.error('Failed to publish story');
            });
    };

    const handleStoryUpdate = (publish = false, deleteStory) => {
        console.log("storyData", storyData);
        const data = { ...storyData };
        const payload = {
            title: data.title,
            country: data.country,
            category: data.category,
            story: data.story,
            published: publish,
        };
        resAxios
            .patch(
                `${process.env.REACT_APP_HOST}/v1/stories/${activeStoryId}`,
                payload
            )
            .then(res => {
                setStoryModalVisibility(false);
                // Set Toast message
                let msg;
                if (publish) msg = 'Story updated';
                if (deleteStory) {
                    if (STORIES_FILTER_IS_DRAFTS()) {
                        // Set toast message
                        msg = 'Story published';
                        // Update stories count
                        updateStoriesCount('my_stories');
                        updateStoriesCount('my_drafts', -1);
                    } else {
                        // Set toast message
                        msg = 'Moved to drafts';
                        // Update stories count
                        updateStoriesCount('my_stories', -1);
                        updateStoriesCount('my_drafts');
                    }
                }
                message.success(msg);
                // Update story in state
                updateStoryInState(data);
                // Delete story from state
                if (deleteStory) deleteStoryFromState(activeStoryId);
            })
            .catch(error => {
                const msg = 'Failed to update story';
                console.error(error, msg);
                message.error(msg);
                setStoryModalConfirmLoading(false);
            });
    };

    const handleStoryValUpdate = (key, val, storyId) => {
        setActiveStoryId(storyId);
        setIsUpdatingStoryCategory(true);
        const payload = { [key]: val };
        resAxios
            .patch(
                `${process.env.REACT_APP_HOST}/v1/stories/${storyId}`,
                payload
            )
            .then(res => {
                const affectedStory = loadedStories.data.find(
                    story => story.id === storyId
                );
                const updatedStory = {
                    ...affectedStory,
                    payload,
                };
                updateStoryInState(updatedStory, storyId);
                message.success('Updated');
            })
            .catch(err => {
                console.error('Failed to update category');
                message.error('Failed to update category');
            })
            .finally(() => setIsUpdatingStoryCategory(false));
    };

    // On approval of Story modal Primary btn
    const onStoryModalOkPrimary = () => {
        setStoryModalConfirmLoading('primary');
        if (storyAction === 'new') {
            handleStoryCreate();
            return;
        }
        if (storyAction === 'edit') {
            handleStoryUpdate(activeStory.published, false);
        }
    };

    // On approval of Story modal Secondary btn
    const onStoryModalOkSecondary = () => {
        setStoryModalConfirmLoading('secondary');
        if (storyAction === 'new') {
            handleStoryCreate(false);
            return;
        }
        if (storyAction === 'edit') {
            handleStoryUpdate(STORIES_FILTER_IS_DRAFTS(), true);
        }
    };

    // On approval of the Delete story confirmation modal
    const onDeleteStoryConfirmationModalOk = () => {
        if (storyAction === 'new') {
            deleteStoryBasics();
            // } else if (storyAction === 'edit') {
        } else {
            setDeleteStoryConfirmLoading(true);
            resAxios
                .delete(
                    `${process.env.REACT_APP_HOST}/v1/stories/${activeStoryId}`
                )
                .then(response => {
                    deleteStoryFromState(activeStoryId);
                    setDeleteStoryConfirmLoading(false);
                    deleteStoryBasics();
                    // Update stories count
                    const story = loadedStories.data.find(
                        story => story.id === activeStoryId
                    );
                    updateStoriesCount(
                        story.published ? 'my_stories' : 'my_drafts',
                        -1
                    );
                });
        }
    };
    // On denial of the Delete story confirmation modal
    const onDeleteStoryConfirmationModalCancel = () => {
        setDeleteStoryConfirmationModalVisibility(false);
        if (storyAction === 'new') {
            newStoryModalOpen();
        } else if (storyAction === 'edit') {
            setStoryModalVisibility(true);
            setStoryAction('edit');
        }
    };
    // On close of the Delete story confirmation modal
    const deleteStoryConfirmationModalAfterClose = () => {
        // resetStoryForm();
    };
    // After the Story modal has been closed
    const storyModalAfterClose = () => {
        if (storyAction === 'edit') {
            resetStory();
        }
        setStoryModalConfirmLoading(false);
    };
    // On Story form input change
    const onStoryFormInputChange = event => {
        setStoryData({
            ...storyData,
            [event.target.name]: event.target.value,
        });
    };
    const onStoryFormSelectChange = (val, key) => {
        setStoryData({
            ...storyData,
            [key]: val,
        });
    };

    // Story move to drafts confirmation modal
    const onStoryMoveToDraftsConfirmationModalOk = () => {
        // Modal loader
        setStoryMoveToDraftsConfirmationModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        const payload = { published: false };
        resAxios
            .patch(
                `${process.env.REACT_APP_HOST}/v1/stories/${activeStoryId}`,
                payload
            )
            .then(res => {
                deleteStoryFromState(activeStoryId);
                setStoryMoveToDraftsConfirmationModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                    confirmLoading: false,
                }));
                // Update stories count
                updateStoriesCount('my_stories', -1);
                updateStoriesCount('my_drafts');
                // Set toast message
                message.success('Moved to drafts');
            })
            .catch(err => {
                console.log(err, 'Failed to move to drafts');
                message.error('Failed to move to drafts');
            })
            .finally(() => {
                setStoryMoveToDraftsConfirmationModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    const onStoryMoveToDraftsConfirmationModalCancel = () => {
        setStoryMoveToDraftsConfirmationModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };
    const onStoryMoveToDraftsConfirmationModalClose = () => {
        resetActiveStoryId();
    };

    // USE EFFECT HOOK
    // Set story atom equal to the value of activeStory atom when the value of activeStory changes
    useEffect(() => {
        const activeStoryCloned = { ...activeStory };
        setStoryData(activeStoryCloned);
    }, [activeStory]);

    // Handle story save
    const handleStorySave = storyId => {
        setSavingStory(true);
        setActiveStoryId(storyId);
        let userDetailsTemp = { ...userDetails };
        // Set storyId
        if (userDetailsTemp.saved_stories) {
            if (userDetailsTemp.saved_stories.find(id => id === storyId)) {
                userDetailsTemp.saved_stories =
                    userDetailsTemp.saved_stories.filter(id => id !== storyId);
                updateStoriesCount('saved_stories', -1);
            } else {
                userDetailsTemp.saved_stories = [
                    ...userDetailsTemp.saved_stories,
                    storyId,
                ];
                updateStoriesCount('saved_stories');
            }
        } else {
            userDetailsTemp.saved_stories = [storyId];
            updateStoriesCount('saved_stories');
        }
        // Request
        const payload = { saved_stories: [...userDetailsTemp.saved_stories] };
        console.log(payload)
        resAxios
            .patch(`${process.env.REACT_APP_HOST}/v1/me`, payload)
            .then(res => {
                console.log(userDetailsTemp)
                setUserDetails(userDetailsTemp);
                localStorage.setItem(
                    'RES_USER',
                    JSON.stringify(userDetailsTemp)
                );
            })
            .catch(err => {
                console.log(err, 'Failed to save story');
            })
            .finally(() => {
                resetActiveStoryId();
                setSavingStory(false);
            });
    };

    // Handle story draft
    const handleStoryMoveToDrafts = storyId => {
        setActiveStoryId(storyId);
        setStoryMoveToDraftsConfirmationModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // Fetch stories by Sidebar right
    const fetchStoriesBy = React.useCallback(
        (param, info) => {
            setLoadedStories(prevValues => ({ ...prevValues, count: null }));
            localStorage.setItem('FETCH_STORIES_BY', param);
            const allStoriesPublished = `${process.env.REACT_APP_HOST}/v1/stories?published=1`;
            let url;
            switch (param) {
                case 'all':
                    url = allStoriesPublished;
                    history.push('/stories/all');
                    break;
                case 'my-stories':
                    url = `${process.env.REACT_APP_HOST}/v1/me/my-stories?published=1`;
                    break;
                case 'drafts':
                    url = `${process.env.REACT_APP_HOST}/v1/me/my-stories?published=0`;
                    break;
                case 'saved':
                    url = `${process.env.REACT_APP_HOST}/v1/me/saved-stories`;
                    break;
                case 'country':
                    url = `${process.env.REACT_APP_HOST}/v1/stories?country=${info}&published=1`;
                    url = encodeURI(url);
                    let countryPath;
                    if (info) countryPath = getKebabCase(info);
                    history.push(`/stories/${countryPath}`);
                    break;
                case 'category':
                    url = `${process.env.REACT_APP_HOST}/v1/stories?category=${info}&published=1`;
                    url = encodeURI(url);
                    let categoryPath;
                    if (info) categoryPath = getKebabCase(info);
                    history.push(`/stories/${categoryPath}`);
                    break;
                default:
                    url = `${process.env.REACT_APP_HOST}/v1/stories?country=1`;
            }
            if (param === 'country' && info === undefined) {
                url = allStoriesPublished;
                history.push('/stories/all');
            }
            resAxios.get(url).then(res => {
                const stories = [...res.data.data];
                const count = stories.length;
                setLoadedStories(prevValues => ({
                    ...prevValues,
                    data: stories,
                    count: count,
                }));
                // Set stories count when all stories are fetched (data available on this endpoint only)
                let storiesCount;
                if (param === 'all') {
                    storiesCount = {
                        all_stories: +count,
                        my_stories: +res.data.my_stories,
                        my_drafts: +res.data.my_drafts,
                        saved_stories: +res.data.saved_stories,
                    };
                    localStorage.setItem(
                        'RES_STORIES_COUNT',
                        JSON.stringify(storiesCount)
                    );
                } else {
                    storiesCount = JSON.parse(
                        localStorage.getItem('RES_STORIES_COUNT')
                    );
                }
                setStoriesCount(storiesCount);
            });
        },
        [setLoadedStories, history, setStoriesCount]
    );

    // Fetch stories on component mount
    useEffect(() => {
        const path = window.location.pathname;
        const storyId = path.split('/').pop();

        if (storyId && /\d/.test(storyId) && storyId.includes('-')) {
            setActiveStoryId(storyId);
            setShowStoryParticular(true);
        }

        const item = localStorage.getItem('FETCH_STORIES_BY') || 'all';
        fetchStoriesBy(item);
    }, [setLoadedStories, fetchStoriesBy, setActiveStoryId]);

    // Clean up
    useEffect(() => {
        return () => {
            localStorage.removeItem('FETCH_STORIES_BY');
            localStorage.removeItem('RES_STORIES_COUNT');
            resetStoriesCount();
        };
    }, [resetStoriesCount]);

    // On story modal files change
    const onStoryModalFilesChange = files => {
        const data = files;
        Array.from(data).forEach(file => {
            // console.log(file);
            const fileType = GET_FILE_TYPE(file.name);
            const ext = GET_FILE_EXT(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setStoryFiles(prevValues => [
                    ...prevValues,
                    {
                        name: file.name,
                        base64: reader.result,
                        blob: file,
                        fileType,
                        ext,
                    },
                ]);
            };
            reader.readAsDataURL(file);
        });
    };

    // On story file remove
    const onStoryFileRemove = i => {
        let storyFilesClone = [...storyFiles];
        storyFilesClone.splice(i, 1);
        setStoryFiles(storyFilesClone);
    };

    // On story each file click
    const onStoryEachFileClick = (file, storyFiles) => {
        switch (file.file_type) {
            case 'image':
                // Get an array of urls of all image files in the story
                const storyImages = storyFiles
                    .filter(file => file.file_type === 'image')
                    .map(file => file.file_url);
                const initialIndex = storyImages.indexOf(`${file.file_url}`);
                setStoryFilesImageLightbox(prevValues => ({
                    ...prevValues,
                    visible: true,
                    initialIndex,
                    images: storyImages.map(
                        image_url =>
                            `${process.env.REACT_APP_STORAGE_FILES}/${image_url}`
                    ),
                }));
                break;
            case 'audio':
            case 'video':
                setStoryFilesPlayer(prevValues => ({
                    ...prevValues,
                    visible: true,
                    file,
                }));
                break;
            case 'word':
            case 'spreadsheet':
            case 'presentation':
            case 'pdf':
                setStoryFilesPDFViewer(prevValues => ({
                    ...prevValues,
                    visible: true,
                    file,
                }));
                break;
            default:
                return null;
        }
    };

    // Set story particular visibility
    const setStoryParticularVisibility = visibility => {
        setShowStoryParticular(visibility);
    };

    // Update stories count
    const updateStoriesCount = (key, change = 1) => {
        setStoriesCount(prevValues => ({
            ...prevValues,
            // If key is not 'saved_stories'
            ...(key !== 'saved_stories' && {
                all_stories: prevValues.all_stories + change,
            }),
            // If key is not 'all_stories'
            ...(key !== 'all_stories' && { [key]: prevValues[key] + change }),
        }));
    };

    return (
        <>
            <div className="stories">
                <div className="stories-container">
                    <div className="container-inner">
                        <StoriesSidebarLeft fetchStoriesBy={fetchStoriesBy} />
                        <div className="container-middle">
                            <StoryCreate
                                newStoryModalOpen={newStoryModalOpen}
                            />
                            <StoriesContent
                                newStoryModalOpen={newStoryModalOpen}
                                editStoryModalOpen={editStoryModalOpen}
                                deleteStoryConfirmationOpen={
                                    deleteStoryConfirmationOpen
                                }
                                handleStorySave={handleStorySave}
                                handleStoryMoveToDrafts={
                                    handleStoryMoveToDrafts
                                }
                                onStoryEachFileClick={onStoryEachFileClick}
                                handleStoryValUpdate={handleStoryValUpdate}
                            />
                        </div>
                        <StoriesSidebarRight fetchStoriesBy={fetchStoriesBy} />
                    </div>
                    <StoryModal
                        storyModal={storyModal}
                        onCancel={onStoryModalCancel}
                        onOkPrimary={onStoryModalOkPrimary}
                        onOkSecondary={onStoryModalOkSecondary}
                        onStoryFormInputChange={onStoryFormInputChange}
                        onStoryFormSelectChange={onStoryFormSelectChange}
                        storyModalAfterClose={storyModalAfterClose}
                        onStoryModalClose={onStoryModalClose}
                        onStoryModalFilesChange={onStoryModalFilesChange}
                        storyFiles={storyFiles}
                        onStoryFileRemove={onStoryFileRemove}
                    />
                    <DeleteStoryConfirmationModal
                        title="Are you sure you want to delete your story?"
                        message="The story and all its contents (images, audio, comments) will be immediately deleted and you will not be able to recover them."
                        cancelText="Cancel"
                        theme="negative"
                        okText="Yes"
                        onOk={onDeleteStoryConfirmationModalOk}
                        visible={deleteStoryConfirmationModal.visible}
                        confirmLoading={
                            deleteStoryConfirmationModal.confirmLoading
                        }
                        onCancel={onDeleteStoryConfirmationModalCancel}
                        afterClose={deleteStoryConfirmationModalAfterClose}
                    />
                    <StoryMoveToDraftsConfirmationModal
                        title="Are you sure you want to move the story to drafts?"
                        message="Exercitation voluptate tempor duis nulla deserunt ex nulla."
                        cancelText="Cancel"
                        theme="negative"
                        okText="Yes"
                        onOk={onStoryMoveToDraftsConfirmationModalOk}
                        visible={storyMoveToDraftsConfirmationModal.visible}
                        confirmLoading={
                            storyMoveToDraftsConfirmationModal.confirmLoading
                        }
                        onCancel={onStoryMoveToDraftsConfirmationModalCancel}
                        afterClose={onStoryMoveToDraftsConfirmationModalClose}
                    />
                </div>
            </div>
            {/* Story files Image Lightbox */}
            {storyFilesImageLightbox.visible && (
                <StoriesFeedImageLightbox
                    getter={storyFilesImageLightbox}
                    setter={setStoryFilesImageLightbox}
                />
            )}
            {/* Story files Player */}
            {storyFilesPlayer.visible && (
                <StoryFilesFilePlayer
                    visible={storyFilesPlayer.visible}
                    file={storyFilesPlayer.file}
                    onPlayerClose={() =>
                        setStoryFilesPlayer(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }))
                    }
                    clickOutsideToClose={false}
                />
            )}
            {/* Story files PDF Viewer */}
            {storyFilesPDFViewer.visible && (
                <StoryFilesPDFViewer
                    visible={storyFilesPDFViewer.visible}
                    onOverlayClose={() =>
                        setStoryFilesPDFViewer(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }))
                    }
                    clickOutsideToClose={false}
                    file={storyFilesPDFViewer.file}
                />
            )}
            {/* Show particular story */}
            {showStoryParticular && (
                <StoryParticular
                    visible={showStoryParticular}
                    editStoryModalOpen={editStoryModalOpen}
                    deleteStoryConfirmationOpen={deleteStoryConfirmationOpen}
                    handleStorySave={handleStorySave}
                    handleStoryMoveToDrafts={handleStoryMoveToDrafts}
                    onStoryEachFileClick={onStoryEachFileClick}
                    onClose={() => setStoryParticularVisibility(false)}
                />
            )}
        </>
    );
}

export default Stories;
