import { Button, Col, Input, Row } from 'antd';
import React from 'react';
// Import from React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function RichTextEditor(props) {
    // Local states
    const [value, setValue] = React.useState('');
    const [videoLink, setVideoLink] = React.useState('');

    // Refsa
    const quillRef = React.useRef();

    // Destructuring props
    const { value: editorValue } = props;

    // Set text length
    const setTextLength = () => {
        setTimeout(() => {
            const valLen = quillRef.current.unprivilegedEditor.getLength() - 1;
            props.setTextLength && props.setTextLength(valLen);
        }, 1);
    };

    // Handle text editor change
    const handleChange = value => {
        setTextLength();
        setValue(value);
        props.onChange(value);
    };

    function getVideoUrl(url) {
        let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
            url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
            url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
        console.log(match[2]);
        if (match && match[2].length === 11) {
            return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
        }
        if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
            return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
        }
        return null;
    }

    const handleAddEmbed = () => {
        const valLen = quillRef.current.unprivilegedEditor.getLength() - 1;
        var url = getVideoUrl(videoLink);
        quillRef.current.getEditor().insertEmbed(valLen, 'video', url);
        setVideoLink('');
    }

    // On component mount (Set value as props value if available)
    React.useEffect(() => {
        editorValue && setValue(editorValue);
        setTextLength();
    }, [editorValue]);

    // Toolbar modules
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['link', 'video'],
            ['clean'],
        ],
    };

    return (
        <>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={value}
                onChange={handleChange}
                placeholder={props.placeholder}
                modules={modules}
            />
            {props.maxTextLength && (
                <div className="react-quill-text-counter">
                    {props.textLength > props.maxTextLength && (
                        <span className="color-error react-quill-text-counter__helper">
                            Characters limit exceeded
                        </span>
                    )}
                    {props.textLength}/{props.maxTextLength}
                </div>
            )}

            <Row gutter={20}>
                <Col span={12}>
                    <Input 
                        value={videoLink} 
                        placeholder='Enter video link' 
                        onChange={(e)=>setVideoLink(e.target.value)}
                    >
                    </Input>
                </Col>
                <Col span={12}>
                    <Button type='button' onClick={()=>handleAddEmbed()}>Add video link</Button>
                </Col>
            </Row>
        </>
    );
}
