import React from "react";

function IconIntention() {
  const uid = Date.now();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <g clipPath="url(#clip0_294_57)">
        <path
          fill={`url(#${uid})`}
          d="M21.389 11.378a4.197 4.197 0 00-4.198-4.197c-.598 0-1.682.353-1.682.353l-8.021.846s-1.66-.967-2.679-.967A4.198 4.198 0 00.632 12.04C.86 17.577 5.408 22 11 22c5.738 0 10.389-4.651 10.389-10.389 0-.032-.006-.062-.008-.093.002-.047.008-.093.008-.14z"
        ></path>
        <path
          fill="#000"
          fillOpacity="0.2"
          d="M21.389 11.378a4.197 4.197 0 00-4.198-4.197c-.598 0-1.682.353-1.682.353l-8.021.846s-1.66-.967-2.679-.967A4.198 4.198 0 00.632 12.04C.86 17.577 5.408 22 11 22c5.738 0 10.389-4.651 10.389-10.389 0-.032-.006-.062-.008-.093.002-.047.008-.093.008-.14z"
        ></path>
        <path
          fill="#2A2E33"
          d="M18.41 8.403c-.575-.149-.616.236-1.345.15-.714-.083.262-.45-.12-.667-.128-.072-.312-.088-.538-.06-.643.08-10.361.333-10.723.508-.323.155-.513.335-.806.061-.368-.343-.896-.145-1.049-.03-.196.147-.237.44.143.78.38.256 1.023.525 2.118.708.12.02.223.028.319.032.75.025.854-1.02 1.913-1.049.694-.018 6.815.654 7.32.585.066-.01.133-.014.2-.014.956 0 1.178.886 2.194.193.43-.35 1.107-1.007.374-1.197z"
        ></path>
        <path
          fill="#fff"
          d="M3.629 5.57a12.31 12.31 0 012.054 2.764c.277.5.52 1.018.726 1.551.75.025.854-.41 1.913-.437.694-.019 1.09.47 1.603.75.917.503 1.57-.445 2.272-.445.52 0 1.356.737 1.941.467.848-.393 1-.728 1.504-.797a14.5 14.5 0 01.765-1.595c.452-.809 1.025-1.628 1.736-2.256H3.629V5.57z"
        ></path>
        <path
          fill="#fff"
          d="M17.91 5.766c.782-.772-14.08.01-13.653.481C4.86 6.916 5.6 8.174 5.6 8.174s2.907 1.47 5.103-.382c1.528.916 3.667.592 4.622-.984 0 0 .743.277 1.497.325 0 0 .583-.87 1.086-1.367z"
        ></path>
        <path
          fill="#2A2E33"
          d="M11 20.526c1.462 0 2.647-1.354 2.647-3.025 0-1.67-1.185-3.025-2.647-3.025-1.462 0-2.647 1.354-2.647 3.025 0 1.67 1.185 3.025 2.647 3.025zM8.556 12.593c0 .9-.548 1.63-1.222 1.63-.676 0-1.223-.73-1.223-1.63 0-.9.547-1.63 1.223-1.63.674 0 1.222.73 1.222 1.63zm7.333-.001c0 .9-.547 1.63-1.222 1.63-.675 0-1.222-.73-1.222-1.63 0-.9.547-1.63 1.222-1.63.675 0 1.222.73 1.222 1.63z"
        ></path>
        <path
          fill="#CCD6DD"
          d="M19.02 2.276c-.146 0-.285.028-.418.067a2.278 2.278 0 00-1.771-.853c-.054 0-.104.012-.156.016a2.433 2.433 0 00-1.826-.83c-.92 0-1.71.512-2.13 1.262a1.366 1.366 0 00-2.338-.3A2.285 2.285 0 008.224.09C7.416.09 6.71.51 6.3 1.143c-.112-.018-.224-.036-.342-.036-.875 0-1.626.497-2.012 1.218a2.063 2.063 0 00-.382-.039 2.062 2.062 0 000 4.125c.346 0 .686-.09.987-.26a1.364 1.364 0 001.08.253A2.949 2.949 0 009.559 7.45a2.965 2.965 0 001.072-.994 2.385 2.385 0 001.85.89 2.392 2.392 0 002.308-1.778c.021 0 .04.006.06.006.174 0 .342-.019.506-.053.41.354.934.55 1.477.552.717 0 1.35-.336 1.77-.853.134.04.273.067.42.067a1.505 1.505 0 00-.001-3.01z"
        ></path>
        <path
          fill="#E1E8ED"
          d="M16.986 4.82a1.296 1.296 0 100-2.592 1.296 1.296 0 000 2.592zM12.146 3.607a1.32 1.32 0 00-1.32-1.32c-.225 0-.434.062-.619.16a1.016 1.016 0 00-1.775-.186 2.28 2.28 0 00-1.557-.62A2.286 2.286 0 004.66 3.376a1.23 1.23 0 10.152 1.541 2.286 2.286 0 001.68 1.27A1.369 1.369 0 009.017 5.8c.198.11.422.179.664.179.654 0 1.2-.458 1.338-1.07a1.317 1.317 0 001.127-1.3z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id={uid}
          x1="10.999"
          x2="10.999"
          y1="7.181"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F92ED"></stop>
          <stop offset="1" stopColor="#00CFAA"></stop>
        </linearGradient>
        <clipPath id="clip0_294_57">
          <path fill="#fff" d="M0 0H22V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconIntention;