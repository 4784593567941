import React from 'react';

export default function SmileyIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<circle cx="256" cy="256" r="256" fill="#ffd93b" />
			<path
				d="M256 424.56c-68.768 0-129.2-44.064-150.368-109.648-2.032-6.304 1.424-13.072 7.728-15.104a11.974 11.974 0 0115.104 7.728C146.432 363.168 197.68 400.56 256 400.56s109.568-37.376 127.536-93.024a11.974 11.974 0 0115.104-7.728c6.304 2.032 9.76 8.8 7.728 15.104C385.2 380.496 324.768 424.56 256 424.56z"
				fill="#3e4347"
			/>
			<path
				d="M512 256c0 141.44-114.64 256-256 256-80.48 0-152.32-37.12-199.28-95.28 43.92 35.52 99.84 56.72 160.72 56.72 141.36 0 256-114.56 256-256 0-60.88-21.2-116.8-56.72-160.72C474.8 103.68 512 175.52 512 256z"
				fill="#f4c534"
			/>
			<g fill="#3e4347">
				<path d="M213.44 214.176c-12.032-16.416-33.728-27.328-58.464-27.328-24.848.112-46.544 11.024-58.576 27.44.56-31.824 26.544-57.456 58.576-57.456 31.808 0 57.904 25.52 58.464 57.344zM415.616 214.176c-12.032-16.416-33.728-27.328-58.464-27.328-24.848.112-46.544 11.024-58.576 27.44.56-31.824 26.544-57.456 58.576-57.456 31.824 0 57.904 25.52 58.464 57.344z" />
			</g>
		</svg>
	);
}
